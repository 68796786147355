import { useCallback, useMemo } from "react"

import { useParams } from "react-router-dom"

import { SidebarContent } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { DETAIL_TAB_PARAM_KEY } from "../../../../common/constants/tabKeys"
import { useEquipment } from "../../hooks/queries/equipment/useEquipments"
import { useEquipmentType } from "../../hooks/queries/equipment/useEquipmentsType"

import { EquipmentDetailsSidebarContent } from "../EquipmentDetailsSidebarContent"

export function EquipmentDetailSidebar() {

    const navigate = useNavigateWithSearchParams()
    const { signId: equipmentId, road } = useParams()
    const { data: equipment } = useEquipment(equipmentId)
    const { data: equipmentType } = useEquipmentType(equipment?.equipment_type, {}, { enabled: !!equipment })

    const title = useMemo(() => {
        return equipmentType?.name ? equipmentType.name : ""
    }, [equipmentType])

    const navigateBack = useCallback(() => {
        navigate(`../${road}`, [DETAIL_TAB_PARAM_KEY])
    }, [navigate, road])

    return <SidebarContent id="equipmentDetailSidebar-sidebar"
        onBack={navigateBack}
        title={title}
    >
        <EquipmentDetailsSidebarContent />
    </SidebarContent>
}