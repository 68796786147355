import { useCallback, useEffect, useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ListRoundedIcon, LocalSeeIcon, MapRoundedIcon } from "@l2r-front/l2r-icons"
import { MobileImageViewerContainer, useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { MobileModulePage } from "@l2r-front/l2r-ui"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { PARAM_KEY_EQUIPMENT } from "../../../../common/constants/urlParams"
import { EquipmentDetailSidebar } from "../../containers/EquipmentDetailSidebar"
import { EquipmentMapLayersRouterMobile } from "../../routers/EquipmentMapLayersRouter"

import * as Styled from "./MobileEquipmentDetailPage.styled"

export function MobileEquipmentDetailPage() {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { resetImageCollectionType } = useImageCollectionDispatchContext()
    const { setParam } = useUrlParams()

    const tabChangeCallback = (oldValue) => {
        if (oldValue === "tab-photo") {
            resetImageCollectionType()
        }
    }
    const tabsConfig = useMemo(() => ([
        {
            element: <EquipmentDetailSidebar />,
            icon: <ListRoundedIcon />,
            id: "tab-equipment",
            label: t(I18N_NAMESPACE, "pages.equipment.tabs.equipment"),
        },
        {
            element: <Styled.Map>
                <EquipmentMapLayersRouterMobile />
            </Styled.Map>,
            icon: <MapRoundedIcon />,
            id: "tab-map",
            label: t(I18N_NAMESPACE, "pages.equipment.tabs.map"),
            fullScreen: true,
        },
        {
            element: <MobileImageViewerContainer />,
            icon: <LocalSeeIcon />,
            id: "tab-photo",
            label: t(I18N_NAMESPACE, "pages.equipment.tabs.photo"),
            fullScreen: true,
        },
    ]), [t])

    const onImageClicked = useCallback(() => {
        setParam(PARAM_KEY_EQUIPMENT, "tab-photo")
    }, [setParam])

    useEffect(() => {
        document.addEventListener("imageClick", onImageClicked)

        return () => {
            document.removeEventListener("imageClick", onImageClicked)
        }
    }, [onImageClicked])

    return (
        <MobileModulePage
            paramKey={PARAM_KEY_EQUIPMENT}
            tabChangeCallback={tabChangeCallback}
            tabsConfig={tabsConfig}
        />
    )
}