import { useCallback, useMemo } from "react"

import { useParams } from "react-router-dom"

import { useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import {
    Layer,
    REFERENTIAL_LAYER,
    CATALOG_ITEMS_LAYER,
    CATALOG_ITEMS_SOURCE,
    CATALOG_ITEMS_LAYER_CLUSTER,
    CATALOG_ITEMS_LAYER_CLUSTER_COUNT,
    getClusterLayerConfig,
    getClusterCountLayerConfig,
    useOrderingLayers,
} from "@l2r-front/l2r-map"
import { useNetworksDispatchContext } from "@l2r-front/l2r-networks"
import { useTheme } from "@l2r-front/l2r-ui"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { getCatalogItemLayerStyle } from "../../utils/getCatalogItemLayerStyle"

const CLUSTER_SIZE = 30
const MAX_ZOOM_LEVEL_CLUSTERED = 16

export const ICON_SIZE = 60
export const COLOR_SIZE = 20

export function CatalogItemLayers(props) {

    const { Source } = props

    const theme = useTheme()
    const params = useParams()
    const { setSelectedLinearLocations } = useNetworksDispatchContext()
    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()
    const road = params?.road
    const { signId } = useParams()

    useOrderingLayers(["road-number", "road-number-shield", CATALOG_ITEMS_LAYER, CATALOG_ITEMS_LAYER_CLUSTER, CATALOG_ITEMS_LAYER_CLUSTER_COUNT])

    const navigateWithSearchParams = useNavigateWithSearchParams()
    const catalogItemsLayerConfig = useMemo(() => {
        if (!theme) {
            return null
        }

        return getCatalogItemLayerStyle(signId, road)
    }, [signId, road, theme])

    const catalogItemsClusterLayerConfig = useMemo(() => {
        if (!theme) {
            return null
        }
        return getClusterLayerConfig({
            paint: {
                "circle-color": theme.palette["colors/gris/500"].main,
                "circle-radius": CLUSTER_SIZE,
            },
        })
    }, [theme])

    const catalogItemsClusterCountLayerConfig = useMemo(() => {
        if (!theme) {
            return null
        }
        return getClusterCountLayerConfig({
            layout: {
                "text-size": theme.typography["H2"].fontSize,
            },
            paint: {
                "text-color": theme.palette.map.text,
            },
        })
    }, [theme])

    const handleClusterLayerClick = useCallback((event) => {
        const map = event.target

        const feature = event.feature
        const geometry = feature.geometry
        const clusterId = feature?.properties?.["cluster_id"]

        const source = map.getSource(CATALOG_ITEMS_SOURCE)
        source.getClusterExpansionZoom(clusterId, (error, zoom) => {
            if (error) {
                return
            }
            map.easeTo({
                center: geometry.coordinates,
                zoom: zoom + 1,
            })
        })
    }, [])

    const handleSignLayerClick = useCallback((event) => {
        const map = event.target
        const roadFeatures = map.queryRenderedFeatures({
            layers: [REFERENTIAL_LAYER],
        })
        const feature = event.feature
        const roadFeatureClicked = roadFeatures.find(roadFeature => {
            const roadFeatureRoad = JSON.parse(roadFeature.properties.linearLocation)[0].road
            const roadFeatureEvent = JSON.parse(feature.properties.linearLocation).road
            return roadFeatureRoad === roadFeatureEvent
        })

        setSelectedLinearLocations(({
            ...feature,
            properties: {
                ...feature.properties,
                linearLocation: JSON.stringify([JSON.parse(feature.properties.linearLocation)]),
            },
        }))
        const linearLocation = JSON.parse(feature.properties.linearLocation)
        setSegmentFeatureIdClicked(roadFeatureClicked?.properties?.uuid)
        const newLocation = (signId || road) ? `../${linearLocation.road}/${feature?.properties?.uuid}`
            : `${linearLocation.road}/${feature?.properties?.uuid}`

        if (signId) {
            return navigateWithSearchParams(newLocation)
        }
        return navigateWithSearchParams(newLocation)
    }, [navigateWithSearchParams, signId, road, setSegmentFeatureIdClicked, setSelectedLinearLocations])

    return <Source
        id={CATALOG_ITEMS_SOURCE}
        cluster={true}
        clusterMaxZoom={MAX_ZOOM_LEVEL_CLUSTERED}>
        <Layer
            {...catalogItemsLayerConfig}
            id={CATALOG_ITEMS_LAYER}
            onClick={handleSignLayerClick}
        />
        <Layer
            {...catalogItemsClusterLayerConfig}
            id={CATALOG_ITEMS_LAYER_CLUSTER}
            onClick={handleClusterLayerClick}
        />
        <Layer
            {...catalogItemsClusterCountLayerConfig}
            id={CATALOG_ITEMS_LAYER_CLUSTER_COUNT}
        />
    </Source>
}