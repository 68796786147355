import { Navigate, Route, Routes } from "react-router-dom"

import { useGetEnabledModulesInApp } from "@l2r-front/l2r-networks"

import { EquipmentMobileRoutes } from "../../../modules/equipment/routers/EquipmentRoutes"
import { VerticalSigningMobileRoutes } from "../../../modules/verticalSigning/routers/VerticalSigningRoutes"
import { appCity } from "../../constants/appCity"
import { MODULE_EQUIPMENT, MODULE_VERTICAL_SIGNING } from "../../constants/modules"

export function CityMobileRouter() {

    const enabledCityModule = useGetEnabledModulesInApp(appCity)

    return (
        <Routes>
            <Route
                path={`/${MODULE_VERTICAL_SIGNING}/*`}
                element={<VerticalSigningMobileRoutes />}
            />
            <Route
                path={`/${MODULE_EQUIPMENT}/*`}
                element={<EquipmentMobileRoutes />}
            />
            <Route
                path="/*"
                element={<Navigate replace to={enabledCityModule?.[0]?.path ? enabledCityModule?.[0]?.path : ""} />}
            />
        </Routes>
    )
}
