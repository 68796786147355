import {
    styled,
    List as UiList,
    Skeleton as UiSkeleton,
    Typography,
    IconButton,
} from "@mui/material"

export const Header = styled("div")(({ theme }) => ({
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(5),
}))

export const ButtonsContainer = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(3),
}))

export const CreateButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette["primary"].main,
    color: theme.palette["primary"].contrastText,
    height: 32,
    width: 32,

    "&:hover": {
        backgroundColor: theme.palette["primary"].dark,
    },
}))

export const ErrorTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette["texts/text-error"].main,
}))

export const TitleSkeleton = styled(UiSkeleton)(({ theme }) => ({
    height: 27,
    marginBottom: theme.spacing(5),
    transform: "scale(1)",
}))

export const List = styled(UiList)(() => ({
}))
