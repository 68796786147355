import { useRoadworksQuery } from "../../../../common/hooks/queries/useRoadworksQuery"

import { roadworksQueryKeys } from "./queryKeys"

export function useRoadworksStatLengthUnique(
    queryParameters,
    config = {},
) {

    const url = "roadworks/stats/length_unique/"
    const queryKey = roadworksQueryKeys["stat_length_unique"](queryParameters)

    return useRoadworksQuery(
        url,
        queryKey,
        queryParameters,
        { ...config },
    )
}