import { EMPTY_QUERY_PARAM } from "@l2r-front/l2r-query"

export function findInCatalog(code, catalog) {
    const item = catalog.find(catalogItem => catalogItem.code === code)

    if (item) {
        return item
    } else {
        let childItem = null
        for (let i = 0; childItem === null && i < catalog.length; i++) {
            childItem = findInCatalog(code, catalog[i].children)
        }
        return childItem
    }
}

export function getAscendantCodes(code, typesData) {

    const codes = []
    let codeToAdd = code
    if (typesData) {
        while (codeToAdd && codeToAdd !== EMPTY_QUERY_PARAM) {
            codes.push(codeToAdd)
            const type = findInCatalog(codeToAdd, typesData)
            codeToAdd = type?.parent
        }
    }

    return codes
}