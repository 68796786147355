import { Navigate, Route, Routes } from "react-router-dom"

import { useGetEnabledModulesInApp } from "@l2r-front/l2r-networks"

import { appCity } from "../../constants/appCity"
import { CityDesktopLayout } from "../../layouts/CityDesktopLayout"

export function CityDesktopRouter() {

    const enabledCityModule = useGetEnabledModulesInApp(appCity)

    return (
        <Routes>
            <Route
                path="/*"
                element={
                    <CityDesktopLayout />
                }
            />
            <Route
                path="/"
                element={
                    <Navigate replace to={enabledCityModule?.[0]?.path ? enabledCityModule?.[0]?.path : ""} />
                }
            />
        </Routes>
    )
}