import { Typography, styled } from "@l2r-front/l2r-ui"

const anchorStyle = (theme) => ({
    position: "absolute",
    display: "flex",
    gap: theme.spacing(3),
    zIndex: 3,
    pointerEvents: "none",

    "& > *": {
        width: "fit-content",
        pointerEvents: "auto",
    },
})

export const TopLeftAnchor = styled("div")(({ theme }) => ({
    ...anchorStyle(theme),
    top: theme.spacing(3),
    left: theme.spacing(3),
    flexDirection: "column",
    width: `calc(100% - ${theme.spacing(3 * 2)})`,
}))

export const BottomRightAnchor = styled("div")(({ theme }) => ({
    ...anchorStyle(theme),
    bottom: theme.spacing(9),
    right: theme.spacing(2),
    flexDirection: "column",
}))


export const RoadContainer = styled("div")(({ theme }) => ({
    backgroundColor: "white",
    borderRadius: theme.spacing(3),
    padding: theme.spacing(2, 3),
    width: "fit-content",
    maxWidth: "100%",
}))

export const RoadText = styled(Typography)(() => ({
    display: "block",
    width: "100%",
}))


