import { useMemo } from "react"

import { useNetworksStateContext } from "../contexts/NetworksContext"

export function useGetEnabledModulesInApp(app) {

    const { selectedNetwork } = useNetworksStateContext()

    const enabledRoadModule = useMemo(() => {
        if (selectedNetwork) {
            return Object.values(app.modules)
                .filter(app => app.label)
                .sort((a, b) => a.index - b.index)
                .filter((moduleSettings) => {
                    const enabled = selectedNetwork && selectedNetwork.modules.includes(moduleSettings.id)
                        || moduleSettings?.enablersModules?.some(enablerModule => {
                            return selectedNetwork.modules?.includes(enablerModule)
                        })
                        || moduleSettings?.mandatoryModules?.every(mandatoryModule => {
                            return selectedNetwork.modules?.includes(mandatoryModule)
                        })
                    return enabled
                })
        }
        return []
    }, [app, selectedNetwork])

    return enabledRoadModule
}