import { useMemo } from "react"

import { useRoutes } from "react-router-dom"

import { SegmentSelectListener } from "@l2r-front/l2r-common"
import { MapAdapterRoad, MapAdapterBoundingBox } from "@l2r-front/l2r-networks"
import { useUrlParams, NavigateWithParams } from "@l2r-front/l2r-utils"

import { RoadwayDetailSegmentSelectListener } from "../../containers/RoadwayDetailSegmentSelectListener"
import { MobileRoadwayDetailPage } from "../../pages/MobileRoadwayDetailPage"
import { MobileRoadwayPage } from "../../pages/MobileRoadwayPage"

export const RoadwayMobileRoutes = () => {
    const { getParams } = useUrlParams()

    const roadwayRoutes = useMemo(() => {
        const searchParams = getParams()

        return [
            {
                path: "/*",
                element:
                    <>
                        <MapAdapterBoundingBox />
                        <SegmentSelectListener />
                        <MobileRoadwayPage />
                    </>,
            },
            {
                path: "/:road",
                element:
                    <>
                        <MapAdapterRoad />
                        <RoadwayDetailSegmentSelectListener />
                        <MobileRoadwayDetailPage />
                    </>,
            },
            {
                path: "/:road/*",
                element: <NavigateWithParams replace to={`../:road?${searchParams}`} />,
            },
        ]
    }, [getParams])

    return useRoutes(roadwayRoutes)
}