import * as Styled from "./ZoomButtons.styled"

export const ZoomButtons = (props) => {
    const {
        onZoomIn,
        onZoomOut,
    } = props

    return (
        <Styled.Wrapper>
            <Styled.ZoomButton onClick={onZoomIn}>
                <Styled.PlusIcon />
            </Styled.ZoomButton>
            <Styled.Divider />
            <Styled.ZoomButton onClick={onZoomOut}>
                <Styled.MinusIcon />
            </Styled.ZoomButton>
        </Styled.Wrapper>
    )
}