import {
    Typography,
    styled,
} from "@l2r-front/l2r-ui"

const BUTTON_SIZE = 72
const SELECTION_PADDING = 2

export const Wrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),

    "&:hover": {
        "& > div": {
            border: `solid 2px ${theme.palette["primary"].main}`,
        },

        "& .MuiTypography-root": {
            color: theme.palette["primary"].main,
        },
    },
}))

export const Image = styled("div", { shouldForwardProp: prop => !["background", "isSelected"].includes(prop) })(({ background, isSelected, theme }) => ({
    backgroundImage: `url(${background})`,
    border: isSelected ? `solid 2px ${theme.palette["primary"].main}` : "none",
    borderRadius: theme.spacing(1),
    backgroundSize: isSelected ? `calc(100% - ${2 * SELECTION_PADDING}px)` : "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundBlendMode: "multiply",
    height: BUTTON_SIZE,
    width: BUTTON_SIZE,
}))

export const Title = styled(Typography, { shouldForwardProp: prop => !["isSelected"].includes(prop) })(({ isSelected, theme }) => ({
    color: isSelected ? theme.palette["primary"].main : theme.palette["border/border-gris"].main,
}))