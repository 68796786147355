import { useCallback, useState } from "react"

import { useNavigate } from "react-router-dom"

import { useUrlParams } from "@l2r-front/l2r-utils"

import { HorizontalSignsSidebarComponents } from "../../components/HorizontalSignsSidebarComponents"
import { HORIZONTAL_SIGNING_CONDITION_URL_PARAM, HORIZONTAL_SIGNING_CODE_URL_PARAM } from "../../constants/urlParams"
import { useGetHorizontalSignTypeCatalogUtils } from "../../hooks/utils/useGetHorizontalSignTypeCatalogUtils"

import * as Styled from "./HorizontalSigningMobileContent.styled"

export const HorizontalSigningMobileContent = () => {
    const { getHorizontalSignTypeDepth, getHorizontalSignTypeChildren } = useGetHorizontalSignTypeCatalogUtils()
    const navigate = useNavigate()
    const { getParam } = useUrlParams()
    const [displayFilters, setDisplayFilters] = useState(false)
    const closeFilters = () => setDisplayFilters(false)

    const handleChangeTypeFilter = useCallback((filters) => {
        const filterCodeDepth = getHorizontalSignTypeDepth(filters.type)
        const children = getHorizontalSignTypeChildren(filters.type)
        if (filterCodeDepth % 2 === 1 && children.length > 1) {
            const condition = getParam(HORIZONTAL_SIGNING_CONDITION_URL_PARAM)
            navigate(`./code/${filters.type}?${HORIZONTAL_SIGNING_CODE_URL_PARAM}=${filters.type}${condition ? `&${HORIZONTAL_SIGNING_CONDITION_URL_PARAM}=${condition}` : ""}`)
        }
    }, [getParam, getHorizontalSignTypeDepth, getHorizontalSignTypeChildren, navigate])

    const toggleFilter = () => setDisplayFilters(displayFilters => !displayFilters)

    return (
        <>
            <Styled.HorizontalSignFilters
                close={closeFilters}
                displayFilters={displayFilters}
            />
            <Styled.BackdropFilter
                id="backdrop-filters"
                onClick={toggleFilter}
                open={displayFilters}
            />
            <Styled.Container>
                <HorizontalSignsSidebarComponents handleChangeTypeFilter={handleChangeTypeFilter} toggleFilter={toggleFilter} />
            </Styled.Container>
        </>
    )
}