import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { mirandaQueryKeys } from "./queryKeys"
import { useMirandaQuery } from "./useMirandaQuery"

export function useMirandaProject(config = {}) {
    const { selectedNetwork } = useNetworksStateContext()
    const url = `projects/${selectedNetwork?.slug}/`

    const queryKey = mirandaQueryKeys.project(selectedNetwork?.slug)

    return useMirandaQuery(
        url,
        queryKey,
        {},
        {
            enabled: !!selectedNetwork,
            ...config,
        },
    )
}