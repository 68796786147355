import { FocusIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import * as Styled from "./RecenterButton.styled"

export const RecenterButton = (props) => {

    const {
        onRecenter,
    } = props

    const isMobile = useIsMobileDevice()

    return <Styled.Button isMobile={isMobile} onClick={onRecenter}>
        <FocusIcon color="primary" />
    </Styled.Button>
}

RecenterButton.propTypes = {
    onRecenter: PropTypes.func.isRequired,
}
