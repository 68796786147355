import { useMemo } from "react"

import { FilterAltIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { FILTERS } from "../../constants/filters"
import { useEquipmentStateContext } from "../../contexts/EquipmentContext"

import * as Styled from "./MobileFilterButton.styled"

export function MobileFilterButton(props) {

    const {
        className,
        enabledFilters,
        onClick,
    } = props

    const { filters } = useEquipmentStateContext()

    const filterCount = useMemo(() => {
        return Object.entries(filters || {}).map(([filterKey, filterValue]) => {
            if (enabledFilters.includes(filterKey)) {
                return filterValue
            } else {
                return []
            }
        }).flat().length
    }, [filters, enabledFilters])

    return <Styled.Wrapper className={className}>
        <Styled.Fab color="cta-bg/cta-bg-primary" id="mobile-filters-button" size="small" onClick={onClick}>
            <FilterAltIcon color="objects/object-lightgrey" />
            {filterCount ? <Styled.FilterCount id="filter-count">
                <Styled.Typography variant="Small">{filterCount}</Styled.Typography>
            </Styled.FilterCount> : null}
        </Styled.Fab>
    </Styled.Wrapper>
}

MobileFilterButton.defaultProps = {
    enabledFilters: FILTERS,
}

MobileFilterButton.propTypes = {
    className: PropTypes.string,
    enabledFilters: PropTypes.arrayOf(PropTypes.oneOf(FILTERS)),
    onClick: PropTypes.func.isRequired,
}