import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { equipmentQueryKeys } from "./queryKeys"
import { useEquipmentQuery } from "./useEquipmentQuery"


export function useEquipments(parameters = {}, config = {}) {
    const url = "equipments/"
    const { selectedNetwork } = useNetworksStateContext()

    const queryParameters = {
        ...parameters,
        project: selectedNetwork.slug,
    }

    const queryKey = equipmentQueryKeys["layer"](queryParameters)

    return useEquipmentQuery(
        url,
        queryKey,
        queryParameters,
        {
            enabled: !!selectedNetwork,
            ...config,
        },
    )
}

export function useEquipment(equipmentId, parameters = {}, config = {}) {
    const url = `equipments/${equipmentId}/`
    const { selectedNetwork } = useNetworksStateContext()

    const queryParameters = {
        ...parameters,
        project: selectedNetwork.slug,
    }

    const queryKey = equipmentQueryKeys["layer"]({ ...parameters, equipmentId })

    return useEquipmentQuery(
        url,
        queryKey,
        queryParameters,
        {
            enabled: !!selectedNetwork,
            ...config,
        },
    )
}