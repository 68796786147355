import {
    IconButton,
    Skeleton as UiSkeleton,
    Typography as UiTypography,
    styled,
} from "@l2r-front/l2r-ui"

export const Skeleton = styled(UiSkeleton)({
    height: 24,
    transform: "scale(1)",
    width: 220,
})

export const RoadDetailWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 8, 8, 8),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
}))

export const Wrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette["colors/gris/150"].main,
    flex: 1,
    height: "fit-content",
    padding: theme.spacing(8),
}))

export const Container = styled("div")({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "auto",
})
export const ErrorText = styled(UiTypography)(({ theme }) => ({
    color: theme.palette["texts/text-error"].main,
    marginTop: theme.spacing(5),
}))

export const LocationHeader = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
})

export const FilledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette["cta-bg/cta-bg-primary"].main,
    color: "white",
    borderRadius: theme.spacing(3),
    marginLeft: theme.spacing(1),

    "&:hover": {
        backgroundColor: theme.palette["cta-bg/cta-bg-primary"].dark,
    },
}))