import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { MenuItem } from "@mui/material"
import { Formik } from "formik"
import { isEqual } from "lodash"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ArrowRightIcon } from "@l2r-front/l2r-icons"
import { MapStyles } from "@l2r-front/l2r-map"
import { BaseNetworkReferentialLayer } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CTAButton, useIsMobileDevice, useTheme, I18N_NAMESPACE } from "@l2r-front/l2r-ui"
import { findCatalogTypeByCode, getFinalCatalogTypes } from "@l2r-front/l2r-utils"

import { useCatalogItemEditContext } from "../../components/CatalogItemEditModal"

import { FormikMapPointEditor } from "../FormikMapPointEditor"

import * as Styled from "./CatalogItemDetailsForm.styled"

const CONTENT_MAX_CHARACTERS = 100

export const CatalogItemDetailsForm = (props) => {
    const {
        forceTextContent,
        disableMaxDistanceCheck,
        initialValues,
        addItemDesc,
        catalogTypes,
        conditions,
        existingTags,
        typeField,
        typeFieldLabel,
        contentFieldLabel,
        referential,
        startingPosition,
        startingRoad,
        className,
        onCancel,
        onChange,
        onSubmit,
    } = props

    const [parentType, setParentType] = useState(null)
    const scrollableRef = useRef(null)
    const [showShadow, setShowShadow] = useState(false)
    const [contextState, contextDispatch] = useCatalogItemEditContext()
    const { typeSelectionDisplayed, mapZoom } = contextState
    const { setTypeSelectionDisplayed, setOnBack, setMapZoom } = contextDispatch
    const { t } = useTranslation(I18N_NAMESPACE)
    const isMobile = useIsMobileDevice()
    const theme = useTheme()

    const FULL_MAP_HEIGHT = 400
    const DETAILS_MAP_HEIGHT = 400

    const showLocation = useMemo(() => !!referential, [referential])
    const showDetails = useMemo(() => !!typeField, [typeField])

    const formInitialValues = useMemo(() => ({
        ...initialValues,
        position: startingPosition,
        road: {
            typeid: 1,
            road: startingRoad,
            start: 0,
            end: 1,
        },

    }), [initialValues, startingPosition, startingRoad])

    const conditionOptions = useMemo(() => {
        if (!conditions) {
            return []
        }
        return conditions?.map(condition => <MenuItem
            key={condition.value}
            value={condition.value}>
            {condition.label}
        </MenuItem>)
    }, [conditions])

    const typeOptions = useMemo(() => {
        if (!catalogTypes) {
            return []
        }

        const finalTypes = getFinalCatalogTypes(catalogTypes || [])
        return finalTypes.map(type => <MenuItem
            key={type.code}
            value={type.code}>
            {type.name}
        </MenuItem>)
    }, [catalogTypes])

    const displayedCatalogTypes = useMemo(() => {
        const types = !parentType ? catalogTypes : parentType.children

        const mapItem = (item => ({
            icon: item.icon,
            label: item.name,
            value: item.code,
            children: item.children.map(child => mapItem(child)),
        }))

        return types?.map(type => mapItem(type))
    }, [parentType, catalogTypes])

    const catalogTypesListTitle = useMemo(() => (
        parentType ? parentType.name : t(I18N_NAMESPACE, "containers.catalogItemEditForm.category")
    ), [parentType, t])

    const compareValues = useCallback((values, initialValues) => {
        const { tags: initialTags, ...initialFields } = initialValues
        const { tags, ...fields } = values

        const areInitialTags = (tags?.length === initialTags?.length) && tags?.every(tag => initialTags?.includes(tag))
        const areInitialFields = isEqual(fields, initialFields)

        return areInitialFields && (!showDetails || areInitialTags)
    }, [showDetails])

    const handleFieldChange = useCallback((fieldName, value, values) => {
        const newValues = {
            ...values,
            [fieldName]: value,
        }

        const areInitialValues = compareValues(newValues, formInitialValues)
        onChange(!areInitialValues)
    }, [formInitialValues, compareValues, onChange])

    const handleOpenTypeSelection = useCallback(() => {
        setTypeSelectionDisplayed(true)
    }, [setTypeSelectionDisplayed])

    const handleTypeHierarchyClick = useCallback((code, setFieldValue, values) => {
        const type = findCatalogTypeByCode(code, catalogTypes)

        if (type?.children?.length) {
            setParentType(type)
        } else {
            setFieldValue(typeField, code)
            handleFieldChange(typeField, code, values)
            setParentType(null)
            setTypeSelectionDisplayed(false)
            if (!type.is_textual && !forceTextContent) {
                setFieldValue("content", "")
            }
        }
    }, [catalogTypes,
        forceTextContent,
        typeField,
        setParentType,
        setTypeSelectionDisplayed,
        handleFieldChange,
    ])

    const handleTypeHierarchyCancel = useCallback(() => {
        setParentType(null)
        setTypeSelectionDisplayed(false)
    }, [setParentType, setTypeSelectionDisplayed])

    const handleMapZoom = useCallback((zoom) => {
        setMapZoom(zoom)
    }, [setMapZoom])

    const handleBack = useCallback(() => {
        if (parentType) {
            const parentItemType = findCatalogTypeByCode(parentType.parent, catalogTypes)
            setParentType(parentItemType)
        } else {
            setTypeSelectionDisplayed(false)
        }
    }, [parentType,
        catalogTypes,
        setParentType,
        setTypeSelectionDisplayed,
    ])

    useEffect(() => {
        setOnBack(handleBack)

        return () => setOnBack(null)
    }, [handleBack, setOnBack])

    useEffect(() => {
        const checkScroll = () => {
            if (scrollableEl.scrollHeight > scrollableEl.clientHeight) {
                setShowShadow(scrollableEl.scrollTop + scrollableEl.clientHeight < scrollableEl.scrollHeight)
            } else {
                setShowShadow(false)
            }
        }

        if (typeSelectionDisplayed) {
            window.removeEventListener("resize", checkScroll)
            return
        }

        const scrollableEl = scrollableRef.current
        if (!scrollableEl) {
            return
        }

        checkScroll()
        scrollableEl.addEventListener("scroll", checkScroll)
        window.addEventListener("resize", checkScroll)

        return () => {
            scrollableEl.removeEventListener("scroll", checkScroll)
            window.removeEventListener("resize", checkScroll)
        }
    }, [scrollableRef, typeSelectionDisplayed])

    const mapHeight = useMemo(() => {
        return isMobile ? "100%" : showDetails ? DETAILS_MAP_HEIGHT : FULL_MAP_HEIGHT
    }, [isMobile, showDetails])

    return <Formik
        initialValues={formInitialValues}
        onSubmit={onSubmit}
    >
        {({ errors, values, setFieldValue }) => {
            const areInitialValues = compareValues(values, formInitialValues)
            const hasErrors = Object.keys(errors).length > 0

            const catalogType = findCatalogTypeByCode(values[typeField], catalogTypes || [])
            const isTextual = forceTextContent || catalogType?.is_textual

            return !typeSelectionDisplayed ? <Styled.Form className={className}>
                <Styled.FormContent ref={scrollableRef}>
                    {showLocation &&
                        <>
                            <Styled.Desc variant="H3">{t(I18N_NAMESPACE, "containers.catalogItemDetailsForm.locationDesc")}</Styled.Desc>
                            <Styled.MapContainer height={mapHeight}>
                                <FormikMapPointEditor
                                    disableMaxDistanceCheck={disableMaxDistanceCheck}
                                    positionFieldName="position"
                                    roadFieldName="road"
                                    referential={referential}
                                    initialZoom={mapZoom}
                                    onZoom={handleMapZoom}
                                >
                                    <BaseNetworkReferentialLayer
                                        mapStyle={MapStyles.satellite}
                                        selectedRoad={values.road.road}
                                        selectedRoadColor={theme.palette["border/border-primary"].main}
                                        interactive={false}
                                    />
                                </FormikMapPointEditor>
                            </Styled.MapContainer>
                        </>
                    }
                    {showDetails &&
                        <>
                            {addItemDesc && <Styled.Desc variant="H3">{addItemDesc}</Styled.Desc>}
                            <Styled.Select
                                id="condition-select"
                                name="condition"
                                value={values.condition ?? ""}
                                label={t(I18N_NAMESPACE, "containers.catalogItemEditForm.condition")}
                                onChange={v => handleFieldChange("condition", v, values)}>
                                {conditionOptions}
                            </Styled.Select>
                            <Styled.Select
                                id="type-select"
                                IconComponent={ArrowRightIcon}
                                name={typeField}
                                value={values[typeField] ?? ""}
                                label={typeFieldLabel}
                                onOpen={handleOpenTypeSelection}>
                                {typeOptions}
                            </Styled.Select>
                            {isTextual && <Styled.TextField
                                id="content-text"
                                name="content"
                                type="text"
                                value={values.content ?? ""}
                                inputProps={{ maxLength: CONTENT_MAX_CHARACTERS }}
                                label={contentFieldLabel}
                                labelOutside={false}
                                onChange={e => handleFieldChange("content", e.target.value, values)} />}
                            <Styled.TagsAutocomplete
                                name="tags"
                                variant="outlined"
                                tags={values.tags}
                                existingTags={existingTags}
                                onChange={v => handleFieldChange("tags", v, values)}
                            />
                        </>}
                </Styled.FormContent>
                <Styled.ButtonsWrapper showShadow={showShadow}>
                    <CTAButton
                        id="cancel-button"
                        onClick={() => {
                            onCancel()
                        }}
                        variant="outlined"
                    >
                        {t(I18N_NAMESPACE, "containers.catalogItemEditForm.cancel")}
                    </CTAButton>
                    <Styled.SubmitButton
                        id="submit-button"
                        color="cta-bg/cta-bg-primary"
                        type="submit"
                        variant="contained"
                        disabled={hasErrors || areInitialValues || (showDetails && !values[typeField])}
                    >
                        {t(I18N_NAMESPACE, "containers.catalogItemEditForm.save")}
                    </Styled.SubmitButton>
                </Styled.ButtonsWrapper>
            </Styled.Form>
                : <Styled.HierarchicalList
                    data={displayedCatalogTypes}
                    title={catalogTypesListTitle}
                    onCancel={handleTypeHierarchyCancel}
                    onClick={(code) => handleTypeHierarchyClick(code, setFieldValue, values)} />
        }}

    </Formik>
}

CatalogItemDetailsForm.propTypes = {
    className: PropTypes.string,
    forceTextContent: PropTypes.bool,
    disableMaxDistanceCheck: PropTypes.bool,
    initialValues: PropTypes.shape({
        condition: PropTypes.number,
        content: PropTypes.string,
        tags: PropTypes.arrayOf(PropTypes.string),
    }),
    catalogTypes: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
        parent: PropTypes.string,
        children: PropTypes.array,
    })),
    conditions: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
    })),
    existingTags: PropTypes.arrayOf(PropTypes.string),
    addItemDesc: PropTypes.string,
    typeField: PropTypes.string,
    typeFieldLabel: PropTypes.string,
    contentFieldLabel: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
}

CatalogItemDetailsForm.defaultProps = {
    disableMaxDistanceCheck: false,
    forceTextContent: false,
}