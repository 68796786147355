import { useMemo } from "react"

import { union } from "lodash"

import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { API_MODULE_MIRANDA } from "../../../../../common/constants/appRoad"
import { MIRANDA_GRADE_TYPE, NOTATION_GRADE_TYPE } from "../../../../../common/constants/urlParams"

import { useRelevantMirandaGradings } from "../miranda/useRelevantMirandaGradings"

import { useRelevantNetworkGradings } from "./useRelevantNetworkGradings"

export function useRelevantGradings(queryParameters, config = {}) {

    const { selectedNetwork } = useNetworksStateContext()

    const { isLoading: isLoadingNetworkGrading, isError: isErrorNetworkGradings, sortedRelevantNetworkGradings } = useRelevantNetworkGradings(selectedNetwork?.slug, queryParameters, config)
    const { isLoading: isLoadingMirandaGradings, isError: isErrorMirandaGradings, sortedRelevantMirandaGradings } = useRelevantMirandaGradings(selectedNetwork?.slug, { enabled: selectedNetwork?.modules?.includes(API_MODULE_MIRANDA) })

    const isError = isErrorNetworkGradings || isErrorMirandaGradings
    const isLoading = isLoadingNetworkGrading || isLoadingMirandaGradings

    const relevantGradings = union(sortedRelevantNetworkGradings?.map(v => ({ ...v, type: NOTATION_GRADE_TYPE })), sortedRelevantMirandaGradings?.map(v => ({ ...v, type: MIRANDA_GRADE_TYPE })))

    const sortedRelevantGradings = useMemo(() => {
        if (isLoading) {
            return undefined
        }
        return relevantGradings.reduce((acc, v) => {
            const existingYearIndex = acc.findIndex(item => parseInt(item.year) === parseInt(v.year))

            if (existingYearIndex === -1) {
                acc.push({
                    ...v,
                    type: [v.type],
                })
            } else {
                acc[existingYearIndex].type = Array.isArray(acc[existingYearIndex].type)
                    ? [...acc[existingYearIndex].type, v.type]
                    : [acc[existingYearIndex].type, v.type]
            }
            return acc
        }, [])
            .sort((a, b) => parseInt(a.year) - parseInt(b.year))
    }, [isLoading, relevantGradings])

    return { isLoading, isError, sortedRelevantGradings }
}