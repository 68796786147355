import { useRoutes } from "react-router-dom"

import { DetailSegmentSelectListener, SegmentSelectListener } from "@l2r-front/l2r-common"
import { MapAdapterBoundingBox, MapAdapterRoad } from "@l2r-front/l2r-networks"

import { HorizontalSignSelectListener } from "../../containers/HorizontalSignSelectListener"
import { MobileHorizontalSignDetailsPage } from "../../pages/MobileHorizontalSignDetailsPage"
import { MobileHorizontalSigningPage } from "../../pages/MobileHorizontalSigningPage"
import { MobileRoadHorizontalSigningPage } from "../../pages/MobileRoadHorizontalSigningPage"

import { HorizontalSignValidator } from "../HorizontalSignValidator"

export const HorizontalSigningMobileRoutes = () => {

    const horizontalSigningRoutes = useRoutes([
        {
            path: "/*",
            element: <>
                <MapAdapterBoundingBox />
                <MobileHorizontalSigningPage />
                <SegmentSelectListener />
            </>,
        },
        {
            path: "/code/:code",
            element: <>
                <MapAdapterBoundingBox />
                <MobileHorizontalSigningPage />
                <SegmentSelectListener />
            </>,
        },
        {
            path: "/:road",
            element: <>
                <DetailSegmentSelectListener />
                <MapAdapterRoad />
                <MobileRoadHorizontalSigningPage />
            </>,
        },
        {
            path: "/:road/:signId",
            element: <HorizontalSignValidator>
                <HorizontalSignSelectListener />
                <MobileHorizontalSignDetailsPage />
            </HorizontalSignValidator>,
        },
        {
            path: "/:road/code/:code",
            element: <>
                <DetailSegmentSelectListener />
                <MapAdapterRoad />
                <MobileRoadHorizontalSigningPage />,
            </>,
        },
        {
            path: "/:road/code/:code/:signId",
            element: <HorizontalSignValidator>
                <HorizontalSignSelectListener />
                <MobileHorizontalSignDetailsPage />
            </HorizontalSignValidator>,
        },
    ])

    return horizontalSigningRoutes
}