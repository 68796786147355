import { useCallback, useState } from "react"

import { useNavigate, useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { Typography } from "@l2r-front/l2r-ui"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { EQUIPMENT_CONDITION_URL_PARAM, EQUIPMENT_CODE_URL_PARAM } from "../../../../common/constants/urlParams"
import { EquipmentSidebarComponents } from "../../component/EquipmentSidebarComponents"
import { useGetEquipmentTypeCatalogUtils } from "../../hooks/utils/useGetEquipmentTypeCatalogUtils"

import * as Styled from "./EquipmentMobileContent.styled"

export const EquipmentMobileContent = () => {
    const { getEquipmentTypeDepth, getEquipmentTypeChildren } = useGetEquipmentTypeCatalogUtils()
    const navigate = useNavigate()
    const { getParam } = useUrlParams()
    const { t } = useTranslation(I18N_NAMESPACE)
    const { road } = useParams()
    const [displayFilters, setDisplayFilters] = useState(false)
    const closeFilters = () => setDisplayFilters(false)
    const toggleFilter = () => setDisplayFilters(displayFilters => !displayFilters)

    const handleChangeFilter = useCallback((filters) => {
        const filterCodeDepth = getEquipmentTypeDepth(filters.type)
        const children = getEquipmentTypeChildren(filters.type)
        if (filterCodeDepth % 2 === 1 && children.length > 1) {
            const condition = getParam(EQUIPMENT_CONDITION_URL_PARAM)
            navigate(`./code/${filters.type}?${EQUIPMENT_CODE_URL_PARAM}=${filters.type}${condition ? `&${EQUIPMENT_CONDITION_URL_PARAM}=${condition}` : ""}`)
        }
    }, [getParam, getEquipmentTypeDepth, getEquipmentTypeChildren, navigate])


    return (
        <>
            <Styled.HorizontalSignFilters
                close={closeFilters}
                displayFilters={displayFilters}
            />
            <Styled.BackdropFilter
                id="backdrop-filters"
                onClick={toggleFilter}
                open={displayFilters}
            />
            <Styled.Container>
                <Typography variant="H1">
                    {t(I18N_NAMESPACE, `containers.equipmentSidebar.${road ? "roadTitle" : "title"}`)}
                </Typography>
                <EquipmentSidebarComponents handleChangeTypeFilter={handleChangeFilter} toggleFilter={toggleFilter} />
            </Styled.Container>
        </>
    )
}