import { useCallback } from "react"

import { findCatalogTypeByCode } from "@l2r-front/l2r-utils"

import { useHorizontalSignTypes } from "../queries/horizontalSigning/useHorizontalSignTypes"

function findCodeIndex(code, catalog, depth) {
    const codeIndex = catalog.findIndex(item => item.code === code)

    if (codeIndex >= 0) {
        return depth
    } else {
        for (let item of catalog) {
            const codeDepth = findCodeIndex(code, item.children, depth + 1)
            if (codeDepth >= 0) {
                return codeDepth
            }
        }

        return -1
    }
}

export function useGetHorizontalSignTypeCatalogUtils() {
    const { data: horizontalSignTypes } = useHorizontalSignTypes()

    const getHorizontalSignTypeDepth = useCallback((code) => {
        if (!horizontalSignTypes) {
            return -1
        }

        return findCodeIndex(code, horizontalSignTypes, 0)
    }, [horizontalSignTypes])

    const getHorizontalSignTypeChildren = useCallback((code) => {
        if (!horizontalSignTypes) {
            return []
        }

        const horizontalSignType = findCatalogTypeByCode(code, horizontalSignTypes)
        return horizontalSignType ? horizontalSignType.children : []
    }, [horizontalSignTypes])

    return {
        getHorizontalSignTypeDepth,
        getHorizontalSignTypeChildren,
    }
}