import { useRoutes } from "react-router-dom"

import { DetailSegmentSelectListener, SegmentSelectListener } from "@l2r-front/l2r-common"
import { MapAdapterBoundingBox, MapAdapterRoad } from "@l2r-front/l2r-networks"

import { EquipmentSelectListener } from "../../containers/EquipmentSelectListener"
import { MobileEquipmentDetailPage } from "../../pages/MobileEquipmentDetailPage"
import { MobileEquipmentPage } from "../../pages/MobileEquipmentPage"
import { MobileEquipmentRoadPage } from "../../pages/MobileEquipmentRoadPage"

import { EquipmentValidator } from "../EquipmentValidator"

export const EquipmentMobileRoutes = () => {

    const equipmentRoutes = useRoutes([
        {
            path: "/*",
            element: <>
                <MapAdapterBoundingBox />
                <MobileEquipmentPage />
                <SegmentSelectListener />
            </>,
        },
        {
            path: "/code/:code",
            element: <>
                <MapAdapterBoundingBox />
                <MobileEquipmentPage />
                <SegmentSelectListener />
            </>,
        },
        {
            path: "/:road",
            element: <>
                <DetailSegmentSelectListener />
                <MapAdapterRoad />
                <MobileEquipmentRoadPage />
            </>,
        },
        {
            path: "/:road/:signId",
            element: <EquipmentValidator>
                <EquipmentSelectListener />
                <MobileEquipmentDetailPage />
            </EquipmentValidator>,
        },
        {
            path: "/:road/code/:code",
            element: <>
                <DetailSegmentSelectListener />
                <MapAdapterRoad />
                <MobileEquipmentRoadPage />
            </>,
        },
        {
            path: "/:road/code/:code/:signId",
            element: <EquipmentValidator>
                <EquipmentSelectListener />
                <MobileEquipmentDetailPage />
            </EquipmentValidator>,
        },
    ])

    return equipmentRoutes
}