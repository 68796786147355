import { Navigate, Route, Routes } from "react-router-dom"

import { useGetEnabledModulesInApp } from "@l2r-front/l2r-networks"

import { Dashboard } from "../../../modules/dashboard/components/Dashboard"
import { HorizontalSigningMobileRoutes } from "../../../modules/horizontalSigning/routers/HorizontalSigningRoutes"
import { RoadwayMobileRoutes } from "../../../modules/roadway/routers/RoadwayRoutes"
import { RoadworksMobileRoutes } from "../../../modules/roadworks/routers/RoadworksRoutes"
import { appRoad } from "../../constants/appRoad"
import { MODULE_DASHBOARD, MODULE_HORIZONTAL_SIGNING, MODULE_ROADWAY, MODULE_ROADWORKS } from "../../constants/modules"

export function RoadMobileRouter() {

    const enabledRoadModule = useGetEnabledModulesInApp(appRoad)

    return (
        <Routes>
            <Route
                path={`/${MODULE_DASHBOARD}/*`}
                element={<Dashboard />}
            />
            <Route
                path={`/${MODULE_ROADWAY}/*`}
                element={<RoadwayMobileRoutes />}
            />
            <Route
                path={`/${MODULE_ROADWORKS}/*`}
                element={<RoadworksMobileRoutes />}
            />
            <Route
                path={`/${MODULE_HORIZONTAL_SIGNING}/*`}
                element={<HorizontalSigningMobileRoutes />}
            />
            <Route
                path="/*"
                element={<Navigate replace to={enabledRoadModule?.[0]?.path ? enabledRoadModule?.[0]?.path : ""} />}
            />
        </Routes>
    )
}
