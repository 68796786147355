import { array, oneOf, shape, string } from "prop-types"

export const equipment = shape({
    children: array,
    code: string,
    condition: oneOf([10, 20, 30]),
    content: string,
    description: string,
    icon: string,
    name: string,
    name_long: string,
    parent: string,
    equipment_type: string,
})