import { useCallback, useMemo } from "react"

import { CatalogItemDetailsForm } from "@l2r-front/l2r-common"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { VERTICAL_SIGNING_CONDITION_GOOD, VERTICAL_SIGNING_CONDITIONS, VERTICAL_SIGNING_CONDITIONS_VALUES } from "../../constants/verticalSigningConditions"
import { useVerticalSignCreate } from "../../hooks/mutations/useVerticalSignCreate"
import { useVerticalSigningProject } from "../../hooks/queries/verticalSigning/useVerticalSigningProject"
import { useVerticalSignTypes } from "../../hooks/queries/verticalSigning/useVerticalSignTypes"

import * as Styled from "./VerticalSignAddForm.styled"

export const VerticalSignAddFormPost = (props) => {

    const {
        className,
        close,
        poleId,
        onSuccess,
        ...otherProps
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    const { data: project } = useVerticalSigningProject()
    const { data: verticalSignTypes, isLoading: isLoadingTypes, isError: isErrorTypes } = useVerticalSignTypes()

    const existingTags = useMemo(() => {
        if (project?.tags) {
            return project.tags.sort()
        }

        return []
    }, [project])

    const conditions = useMemo(() => {
        return VERTICAL_SIGNING_CONDITIONS.filter(condition => condition > 0)
            .map(condition => ({
                value: condition,
                label: t(I18N_NAMESPACE, VERTICAL_SIGNING_CONDITIONS_VALUES[condition].label),
            }))
    }, [t])

    const initialValues = useMemo(() => ({
        vertical_sign_type: undefined,
        condition: VERTICAL_SIGNING_CONDITION_GOOD,
        content: "",
        tags: [],
    }), [])

    const mutation = useVerticalSignCreate({}, {
        onSuccess: onSuccess,
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.verticalSignPoleAddForm.snackbar.error"),
        successSnackbarMessage: t(I18N_NAMESPACE, "containers.verticalSignPoleAddForm.snackbar.success"),
    })

    const onSubmit = useCallback((formValues) => {
        const values = {
            ...formValues,
            vertical_sign_pole: poleId,
        }
        mutation.mutate(values)
        close(false)
    }, [close, mutation, poleId])

    if (isLoadingTypes) {
        return <Styled.FormSkeleton className={className} />
    }

    if (isErrorTypes) {
        return <Styled.ErrorMessage>{t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.error")}</Styled.ErrorMessage>
    }

    return <CatalogItemDetailsForm
        className={className}
        {...otherProps}
        initialValues={initialValues}
        catalogTypes={verticalSignTypes}
        typeField="vertical_sign_type"
        typeFieldLabel={t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.type") + "*"}
        conditions={conditions}
        existingTags={existingTags}
        onSubmit={onSubmit}
        onCancel={close}
    />
}

VerticalSignAddFormPost.propTypes = {
    className: PropTypes.string,
    close: PropTypes.func.isRequired,
    poleId: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
}