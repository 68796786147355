import { useParams } from "react-router-dom"

import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { useEquipment } from "../../hooks/queries/equipment/useEquipments"

export const EquipmentValidator = (props) => {
    const { children } = props

    const { road, code, signId: equipmentId } = useParams()
    const navigate = useNavigateWithSearchParams()

    const { isLoading, isError } = useEquipment(equipmentId, {}, {
        onError: () => navigate(code ? `../${road}/code/${code}` : `../${road}`),
    })

    if (isLoading || isError) {
        return null
    } else {
        return children
    }
}