import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { EMPTY_QUERY_PARAM } from "@l2r-front/l2r-query"

import { verticalSigningQueryKeys } from "./queryKeys"
import { useVerticalSigningQueries, useVerticalSigningQuery } from "./useVerticalSigningQuery"

const REFETCH_INTERVAL = 7 * 60 * 1000

function sortChildTypes(parent) {
    parent.children.sort((a, b) => a.code.localeCompare(b.code))

    parent.children.forEach(child => sortChildTypes(child))
}

const refetchConfig = {
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: true,
    staleTime: REFETCH_INTERVAL,
}

export function useVerticalSignTypes(parent = EMPTY_QUERY_PARAM, config = {}) {
    const url = "vertical_sign_types/"
    const { selectedNetwork } = useNetworksStateContext()
    const { country } = selectedNetwork

    const parameters = {
        country,
        parent: parent || EMPTY_QUERY_PARAM,
    }

    const queryKey = verticalSigningQueryKeys.list(parameters)

    const { data, isLoading, isError, isFetching } = useVerticalSigningQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
            ...refetchConfig,
        },
    )

    if (data) {
        data.sort((a, b) => a.code.localeCompare(b.code))
        data.forEach(d => sortChildTypes(d))
    }

    return { data, isLoading, isError, isFetching }
}

export function useVerticalSignType(code, queryParameters, config = {}) {
    const url = `vertical_sign_types/${code}/`
    const queryKey = verticalSigningQueryKeys["verticalSignType"]({ ...queryParameters, code })

    const { data, isLoading, isError } = useVerticalSigningQuery(
        url,
        queryKey,
        { ...queryParameters },
        {
            enabled: !!code,
            ...config,
            ...refetchConfig,
        },
    )

    if (data) {
        sortChildTypes(data)
    }

    return { data, isLoading, isError }
}

export function useVerticalSignTypeQueries(verticalSigns = [], config = {}) {
    const uniqueTypes = [...new Set(verticalSigns.map(verticalSign => verticalSign.vertical_sign_type))]
    const queries = uniqueTypes.map(type => {
        const url = `vertical_sign_types/${type}/`
        const queryKey = verticalSigningQueryKeys["verticalSignType"](type)
        return ({
            url,
            queryKey,
            queryParameters: {},
            config: {
                ...config,
                ...refetchConfig,
            },
        })
    })

    return useVerticalSigningQueries(queries)
}