import { Typography, styled } from "@l2r-front/l2r-ui"

export const Container = styled("div")(({ theme }) => ({
    width: "100%",
    height: "100%",
    borderRadius: theme.spacing(3),
}))

export const ErrorMessage = styled(Typography)(({ theme }) => ({
    color: theme.palette["error/main"].main,
    marginTop: theme.spacing(5),
}))
