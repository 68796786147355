import { chartData } from "./chartData"
import { chartDomain } from "./chartDomain"
import { cssSize } from "./cssSize"
import { date } from "./date"
import { email } from "./email"
import { geoJsonPoint } from "./geoJson"
import { equipment } from "./l2r-app-city/equipment"
import { verticalSign } from "./l2r-app-city/verticalSign"
import { damageType } from "./l2r-app-road/damageType"
import { horizontalSign } from "./l2r-app-road/horizontalSign"
import { networkGrading } from "./l2r-app-road/networkGrading"
import { quartersCost } from "./l2r-app-road/quartersCost"
import { roadwork, roadworkForm, roadworkWithTasks } from "./l2r-app-road/roadwork"
import { task, taskContent, taskStat } from "./l2r-app-road/task"
import { taskType } from "./l2r-app-road/taskType"
import { technique } from "./l2r-app-road/technique"
import { linearLocation } from "./linearLocation"
import { timestamp } from "./timestamp"
import { url } from "./url"
import { uuid } from "./uuid"

export const PropTypes = {
    chartData,
    chartDomain,
    cssSize,
    damageType,
    date,
    email,
    equipment,
    geoJsonPoint,
    linearLocation,
    networkGrading,
    quartersCost,
    roadwork,
    roadworkForm,
    roadworkWithTasks,
    task,
    taskContent,
    taskStat,
    taskType,
    technique,
    timestamp,
    url,
    uuid,
    verticalSign,
    horizontalSign,
}