import { useCallback, useEffect, useMemo, useState } from "react"

import { useQueryClient } from "@tanstack/react-query"
import { FieldArray, Form, Formik } from "formik"
import { cloneDeep, isEqual } from "lodash"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { CheckCircleIcon } from "@l2r-front/l2r-icons"
import { useRoleIsReadOnly } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CTAButton, Typography } from "@l2r-front/l2r-ui"
import { formatNumber, formatDate } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { getEmptyTask } from "../../constants/emptyTask"
import { MINIMAL_IMPACT } from "../../constants/impacts"
import { STATUS_DONE, STATUS_TODO } from "../../constants/status"
import { AddRoadworkTaskButton } from "../../containers/AddRoadworkTaskButton"
import { RoadworkTask } from "../../containers/RoadworkTask"
import { RoadworkYearSelector } from "../../containers/RoadworkYearSelector"
import { roadworksQueryKeys } from "../../hooks/queries/queryKeys"
import { constructTechniqueAcronym } from "../../utils/roadworkUtils"

import { computeTechniqueCode, computeTechniqueCost, computeTechniqueImpact } from "./RoadworkDetailsForm.helpers"
import * as Styled from "./RoadworkDetailsForm.styled"

export function RoadworkDetailsForm(props) {

    const {
        className,
        currency,
        hasPendingChange,
        onStatusChange,
        onSubmit,
        onChange,
        initialState,
        roadwork,
        taskTypes,
    } = props

    const [taskInEditionIndex, setTaskInEditionIndex] = useState(null)
    const [taskExtendedIndex, setTaskExtendedIndex] = useState(null)

    const readOnly = useRoleIsReadOnly()
    const { t } = useTranslation(I18N_NAMESPACE)
    const queryClient = useQueryClient()

    const initialValues = useMemo(() => {
        const initial = {
            ...initialState,
            tasks: initialState?.tasks?.length ? initialState.tasks : [getEmptyTask()],
        }
        return cloneDeep(initial)
    }, [initialState])

    const isLocalizedTechnique = useMemo(() => {
        const techniqueImpact = computeTechniqueImpact(initialState, taskTypes)
        return techniqueImpact === MINIMAL_IMPACT
    }, [initialState, taskTypes])

    const updateRoadworkSectionCache = useCallback((uuid, techniqueProperties) => {
        if (Object.entries(techniqueProperties).length > 0) {
            const emptyTechniqueProperties = Object.values(techniqueProperties)
                .every(techniquePropertie => !techniquePropertie)
            const featureQueryKey = roadworksQueryKeys.section(uuid)
            if (queryClient.getQueryData(featureQueryKey)) {
                queryClient.setQueryData(featureQueryKey, oldFeature => {
                    return ({
                        ...oldFeature,
                        properties: {
                            ...oldFeature.properties,
                            technique: emptyTechniqueProperties ? null : {
                                ...oldFeature.properties.technique,
                                ...techniqueProperties,
                            },
                        },
                    })
                })
            }
        }
    }, [queryClient])

    const handleYearChanged = useCallback((value) => {
        const newValues = cloneDeep(initialState)
        newValues.year = value
        onChange(newValues)
    }, [initialState, onChange])

    const handleTaskChanged = useCallback((inputName, values, setFieldValue) => {
        const techniqueProperties = {}
        const newCost = computeTechniqueCost(values)
        setFieldValue("technique.cost", newCost)
        techniqueProperties["cost"] = newCost
        const newCode = computeTechniqueCode(values, taskTypes)
        setFieldValue("technique.code", newCode)
        techniqueProperties["code"] = newCode

        const newAcronym = constructTechniqueAcronym({ code: newCode }, taskTypes)
        setFieldValue("technique.acronym", newAcronym)
        techniqueProperties["acronym"] = newAcronym

        const newImpact = computeTechniqueImpact(values, taskTypes)
        setFieldValue("technique.impact", newImpact)
        techniqueProperties["impact"] = newImpact

        updateRoadworkSectionCache(values.uuid, techniqueProperties)

        return techniqueProperties
    }, [taskTypes, updateRoadworkSectionCache])

    const handleTaskCancel = useCallback((resetForm) => {
        resetForm({ values: cloneDeep(initialState) })

        const techniqueProperties = {
            cost: initialState.technique.cost,
            code: initialState.technique.code,
            impact: initialState.technique.impact,
        }
        updateRoadworkSectionCache(initialState.uuid, techniqueProperties)

        setTaskInEditionIndex(null)
    }, [initialState, updateRoadworkSectionCache, setTaskInEditionIndex])

    const handleTaskValidated = useCallback((values) => {
        onChange(cloneDeep(values))
        setTaskInEditionIndex(null)
    }, [onChange, setTaskInEditionIndex])

    const handleSetInEditionTask = useCallback((taskIndex, forceReset, resetForm) => {
        if (taskIndex !== taskInEditionIndex) {
            setTaskInEditionIndex(taskIndex)
            if (forceReset) {
                resetForm({ values: cloneDeep(initialState) })
            }
        }
        if (taskIndex !== null) {
            setTaskExtendedIndex(null)
        }
    }, [taskInEditionIndex, setTaskInEditionIndex, setTaskExtendedIndex, initialState])

    const handleSetExtendedTask = useCallback((taskIndex, resetForm) => {
        setTaskExtendedIndex(taskIndex)
        if (taskInEditionIndex !== null) {
            handleSetInEditionTask(null, true, resetForm)
        }
    }, [taskInEditionIndex, setTaskExtendedIndex, handleSetInEditionTask])

    useEffect(() => {
        if (!initialState?.tasks?.length && initialValues?.tasks?.length) {
            setTaskInEditionIndex(0)
        }
        if (!isEqual(initialValues, initialState)) {
            onChange(initialValues)
        }
    }, [initialState, initialValues, onChange])

    return <Formik initialValues={initialValues}
        onSubmit={onSubmit}
    >
        {
            ({ values, setFieldValue, resetForm }) => {
                const isRoadworkDone = roadwork.status === STATUS_DONE
                const currentYear = new Date().getFullYear()
                const canBeDone = currentYear === values.year && !readOnly
                const canBeEdited = values.year >= (currentYear - 1) && roadwork.status === STATUS_TODO && !readOnly
                const selectedTaskTypes = values.tasks.map(v => v.taskType)
                const taskTypesLeft = taskTypes
                    .filter(v => v.impact !== MINIMAL_IMPACT)
                    .filter(v => !selectedTaskTypes.includes(v.code))
                const roadworkCost = formatNumber(values.technique.cost) ?? 0
                const localizedTaskAllowed = values.tasks.length <= 1
                return (
                    <Styled.RoadworkDetailWrapper className={className} id="roadwork-detail">
                        <Form>
                            {canBeEdited && <Styled.YearWrapper>
                                <Typography id="task-form-title" variant="H3">{t(I18N_NAMESPACE, "forms.roadworkTaskForm.yearTitle")}</Typography>
                                <RoadworkYearSelector name="year" value={values.year} onChange={handleYearChanged} />
                            </Styled.YearWrapper>}
                            <Styled.Wrapper>
                                <Styled.TechniqueWrapper>
                                    {!canBeEdited && <Typography variant="Regular">{values.year}</Typography>}
                                    <Typography id="technique-acronym" variant="H3">{values.technique.acronym ?? t(I18N_NAMESPACE, "components.roadworkDetailsForm.emptyTechnique")}</Typography>
                                </Styled.TechniqueWrapper>
                                <Styled.TaskImpactBadge id="technique-impact" impact={values.technique.impact} />
                            </Styled.Wrapper>
                            <Styled.TechniqueWrapper>
                                <div>
                                    <Typography variant="H3">{`${t(I18N_NAMESPACE, "components.roadworkDetail.cost")}`}&nbsp;</Typography>
                                    <Typography id="technique-cost" variant="Regular">{roadworkCost} {currency}</Typography>
                                </div>
                            </Styled.TechniqueWrapper>
                            <div>
                                {isRoadworkDone &&
                                    <Styled.DoneInfoContainer id="roadwork-done-info">
                                        <CheckCircleIcon color="objects/object-primary" />
                                        <Styled.DoneInfo >
                                            <Typography variant="H3">
                                                {t(I18N_NAMESPACE, "containers.roadworkSectionSidebar.doneAt",
                                                    {
                                                        date: formatDate(roadwork.doneAt),
                                                    })}
                                            </Typography>
                                            <Typography variant="H3">
                                                {t(I18N_NAMESPACE, "containers.roadworkSectionSidebar.doneBy",
                                                    {
                                                        name: roadwork.doneBy,
                                                    })}
                                            </Typography>
                                        </Styled.DoneInfo>
                                    </Styled.DoneInfoContainer>
                                }
                                <FieldArray
                                    name="tasks"
                                    render={arrayHelpers => {
                                        return <>
                                            {values.tasks.map((task, index) => {
                                                const otherTaskTypes = values.tasks.filter(t => t.taskType !== task.taskType).map(t => t.taskType)
                                                return <RoadworkTask
                                                    closed={index !== taskExtendedIndex}
                                                    deleteTask={() => {
                                                        arrayHelpers.remove(index)
                                                        const newValues = cloneDeep(values)
                                                        newValues.tasks.splice(index, 1)
                                                        const techniqueProperties = handleTaskChanged("taskType", newValues, setFieldValue)
                                                        onChange({
                                                            ...newValues,
                                                            technique: {
                                                                ...newValues.technique,
                                                                ...techniqueProperties,
                                                            },
                                                        })
                                                    }}
                                                    disableTaskTypes={otherTaskTypes}
                                                    isDeletable={values.tasks.length > 1}
                                                    isEditable={canBeEdited}
                                                    isEditing={index === taskInEditionIndex}
                                                    initialValues={initialValues}
                                                    isRoadworkCreation={roadwork && !roadwork.tasks.length}
                                                    localizedTaskAllowed={localizedTaskAllowed}
                                                    key={`task-${index}`}
                                                    index={index}
                                                    setExtendedTask={(taskIndex) => handleSetExtendedTask(taskIndex, resetForm)}
                                                    setInEditionTask={(taskIndex, forceReset) => handleSetInEditionTask(taskIndex, forceReset, resetForm)}
                                                    onChange={(inputName, newValues) => handleTaskChanged(inputName, newValues, setFieldValue)}
                                                    onCancel={() => handleTaskCancel(resetForm)}
                                                    onValidate={() => handleTaskValidated(values)}
                                                    task={task}
                                                />
                                            })}
                                            <AddRoadworkTaskButton
                                                canAdd={canBeEdited && taskTypesLeft.length > 0 && !Number.isInteger(taskInEditionIndex)}
                                                initialState={initialState}
                                                isLocalizedTechnique={isLocalizedTechnique}
                                                onClick={() => {
                                                    arrayHelpers.push(getEmptyTask())
                                                    handleSetInEditionTask(values.tasks.length, false, false)
                                                }}
                                                taskTypes={taskTypes}
                                                taskInEditionIndex={taskInEditionIndex}
                                            />
                                        </>
                                    }}
                                >
                                </FieldArray>
                            </div>
                            <Styled.ButtonWrapper>
                                {canBeDone && <CTAButton
                                    id={isRoadworkDone ? "roadwork-reopen-button" : "roadwork-close-button"}
                                    disabled={taskInEditionIndex !== null}
                                    variant="outlined"
                                    onClick={onStatusChange}
                                >
                                    {isRoadworkDone ? t(I18N_NAMESPACE, "components.techniqueCardItem.reopenButton.label") :
                                        t(I18N_NAMESPACE, "components.techniqueCardItem.closeButton.label")
                                    }
                                </CTAButton>}
                                {canBeEdited && <Styled.SubmitButton id="roadwork-submit-button" disabled={!hasPendingChange || taskInEditionIndex !== null}>
                                    {t(I18N_NAMESPACE, "components.roadworkDetailsForm.submit")}
                                </Styled.SubmitButton>}
                            </Styled.ButtonWrapper>
                        </Form>
                    </Styled.RoadworkDetailWrapper>
                )
            }
        }
    </Formik >
}

RoadworkDetailsForm.propTypes = {
    className: PropTypes.string,
    currency: PropTypes.string.isRequired,
    hasPendingChange: PropTypes.bool,
    onStatusChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    initialState: PropTypes.roadworkForm.isRequired,
    roadwork: PropTypes.roadworkWithTasks.isRequired,
    taskTypes: PropTypes.arrayOf(PropTypes.taskType).isRequired,
}