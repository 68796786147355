import { useMemo, useCallback } from "react"

import * as turf from "@turf/turf"
import { useParams } from "react-router-dom"

import { CatalogItemsSource, DEFAULT_SIGN_CODE, DEFAULT_SIGN_ICON } from "@l2r-front/l2r-common"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { findTypeByCode, getAscendantCodes } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useEquipmentStateContext } from "../../contexts/EquipmentContext"
import { useEquipments } from "../../hooks/queries/equipment/useEquipments"
import { useEquipmentTypes } from "../../hooks/queries/equipment/useEquipmentsType"

export const ENABLED_STATE = "1"
export const DISABLED_STATE = "0"

export function EquipmentSource(props) {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { setError: setMapError } = useMapDispatchContext()
    const { descendantCodes, filters } = useEquipmentStateContext()
    const { signId: equipmentId } = useParams()

    const { data: equipmentTypes } = useEquipmentTypes()

    const { data } = useEquipments({
        as_geojson: true,
    }, {
        onError: () => setMapError(true),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.equipmentSource.error"),
    })

    const getIcon = useCallback((typeCode) => {
        const ascendantCodes = getAscendantCodes(typeCode, equipmentTypes)
        const equipmentType = findTypeByCode(typeCode, equipmentTypes)
        const parentEquipmentType = findTypeByCode(ascendantCodes[1], equipmentTypes)

        return typeCode === DEFAULT_SIGN_CODE ? DEFAULT_SIGN_ICON :
            equipmentType?.icon || parentEquipmentType?.icon || DEFAULT_SIGN_ICON
    }, [equipmentTypes])

    // Need to copy id into properties because of that mapbox issue: 
    // link:https://github.com/mapbox/mapbox-gl-js/issues/2716
    const sanitizedData = useMemo(() => {
        if (!data || !equipmentTypes) {
            return null
        }
        const dataFeatures = []
        turf.geomEach(data, (currentGeometry, featureIndex, featureProperties, featureBBox, featureId) => {
            const feature = data.features[featureIndex]
            const equipment = featureProperties
            const isSelectedEquipment = featureId === equipmentId
            const filterConditions = filters?.conditions || null
            const filterTags = filters?.tags || null
            const isSelectedType = descendantCodes.includes(equipment.equipment_type)
            const isSelectedCondition = filterConditions ? (filterConditions).includes(equipment.condition)
                : true
            const isFilteredByTags = !filterTags || filterTags.every(filterTag => equipment.tags.includes(filterTag))

            if (isSelectedEquipment || (isSelectedType && isSelectedCondition && isFilteredByTags)) {
                dataFeatures.push({
                    id: featureId,
                    bbox: featureBBox,
                    geometry: currentGeometry,
                    properties: {
                        ...featureProperties,
                        globalCondition: equipment.condition || -1,
                        globalIcon: equipment.equipment_type,
                        equipment_type: equipment.equipment_type,
                        linearLocation: feature.linear_location,
                        uuid: featureId,
                    },
                })
            }
        })
        return turf.featureCollection(dataFeatures)
    }, [data, descendantCodes, filters, equipmentId, equipmentTypes])

    return (
        <CatalogItemsSource
            {...props}
            getIcon={getIcon}
            sanitizedData={sanitizedData}
            types={equipmentTypes}
        />
    )
}

CatalogItemsSource.proptypes = {
    getIcon: PropTypes.func,
    sanitizedData: PropTypes.object,
    types: PropTypes.array,
}