import { SwipeableDrawer } from "@mui/material"

import { undefinedFunctionWarning } from "@l2r-front/l2r-utils"

import * as Styled from "./BottomSheet.styled"

export const BottomSheet = (props) => {
    const {
        children,
        ...bottomSheetProps
    } = props

    return <Styled.SwipeableDrawer
        anchor="bottom"
        ModalProps={{
            keepMounted: true,
        }}
        {...bottomSheetProps}
    >
        <Styled.Box>
            {children}
        </Styled.Box>
    </Styled.SwipeableDrawer>
}

BottomSheet.propTypes = SwipeableDrawer.propTypes

BottomSheet.defaultProps = {
    onOpen: undefinedFunctionWarning("onOpen"),
}