import { LinearProgress, styled, styledForMobile } from "@l2r-front/l2r-ui"

export const Loader = styled(LinearProgress)(() => ({
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
    width: "100%",
    "&[hidden]": {
        visibility: "hidden",
    },
}))

export const Wrapper = styled("div")(({ borderRounded, scaleColor, theme }) => ({
    position: "relative",
    height: "100%",

    ".mapboxgl-map": {
        borderRadius: borderRounded ? theme.spacing(3) : 0,
    },
    ".mapboxgl-ctrl-scale": {
        backgroundColor: "transparent",
        borderColor: theme.palette[scaleColor].main,
        color: theme.palette[scaleColor].main,
    },
    ".mapboxgl-ctrl-group": {
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none",
        display: "flex",
        flexDirection: "row-reverse",

        ".mapboxgl-ctrl-zoom-in": {
            margin: theme.spacing(0, 2),
            ".mapboxgl-ctrl-icon": {
                backgroundImage: "url('assets/resources/Plus.svg')",
            },
        },

        ".mapboxgl-ctrl-zoom-out": {
            ".mapboxgl-ctrl-icon": {
                backgroundImage: "url('assets/resources/Moins.svg')",
            },
        },

        "& > button": {
            backgroundColor: theme.palette["surfaces/surface-primary"].main,
            borderRadius: theme.spacing(1),
            height: theme.spacing(8),
            width: theme.spacing(8),

            "&:hover": {
                backgroundColor: "#E5E5E6",
            },
        },
    },
}))

const anchorStyle = (theme) => ({
    position: "absolute",
    display: "flex",
    gap: theme.spacing(3),
    zIndex: 3,
    pointerEvents: "none",

    "& > *": {
        pointerEvents: "auto",
    },
})

export const TopLeftAnchor = styled("div")(({ theme }) => ({
    ...anchorStyle(theme),
    top: theme.spacing(3),
    left: theme.spacing(3),
    flexDirection: "column",
    width: `calc(100% - ${theme.spacing(3 * 2)})`,
}))

export const BottomLeftAnchor = styled("div")(({ theme }) => ({
    ...anchorStyle(theme),
    bottom: theme.spacing(12),
    left: theme.spacing(3),
    flexDirection: "column-reverse",
    gap: theme.spacing(3),
    width: `calc(100% - ${theme.spacing(3 * 2)})`,
}))

export const TopRightAnchor = styled("div")(({ theme }) => ({
    ...anchorStyle(theme),
    top: theme.spacing(3),
    right: theme.spacing(3),
    flexDirection: "column",
    alignItems: "flex-end",
    width: `calc(100% - ${theme.spacing(3 * 2)})`,
}))

export const BottomRightAnchor = styledForMobile("div")(({ theme }) => {

    return {
        ...anchorStyle(theme),
        bottom: theme.spacing(12),
        right: theme.spacing(3),
        flexDirection: "column-reverse",
        alignItems: "flex-end",
    }
})