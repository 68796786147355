import { useMemo, useState } from "react"

import { useParams } from "react-router-dom"

import { CatalogItemEditModal } from "@l2r-front/l2r-common"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { ModeEditIcon } from "@l2r-front/l2r-icons"
import { RoadDetailInfo, useRoleIsReadOnly } from "@l2r-front/l2r-networks"
import { Typography, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { EquipmentLocationEditFormPost } from "../../forms/EquipmentLocationEditForm"
import { useEquipment } from "../../hooks/queries/equipment/useEquipments"

import { EquipmentDetails } from "../EquipmentDetails/EquipmentDetails"

import * as Styled from "./EquipmentDetailsSidebarContent.styled"

export function EquipmentDetailsSidebarContent() {

    const [modalOpen, setModalOpen] = useState(false)
    const { signId: equipmentId, road } = useParams()
    const { data, isError } = useEquipment(equipmentId)
    const isMobile = useIsMobileDevice()

    const { t } = useTranslation(I18N_NAMESPACE)
    const readOnly = useRoleIsReadOnly()
    const latLong = data?.geometry.coordinates

    const LatLongComponent = useMemo(() => {
        if (latLong) {
            return <Typography id="road-detail-latlong" variant="Regular">
                {latLong[0].toFixed(6)}, {latLong[1].toFixed(6)}
            </Typography>
        }
        return <Styled.Skeleton />
    }, [latLong])

    return <>
        <Styled.Container isMobile={isMobile}>
            <Styled.RoadDetailWrapper>
                <Styled.LocationHeader>
                    <Typography variant="H3">
                        {t(I18N_NAMESPACE, "containers.equipmentDetailSidebar.location.title")}
                    </Typography>
                    {!readOnly && <Styled.FilledIconButton id="linear-edit-button" onClick={() => setModalOpen(true)}>
                        <ModeEditIcon />
                    </Styled.FilledIconButton>}
                </Styled.LocationHeader>
                <RoadDetailInfo
                    LengthComponent={LatLongComponent}
                    lengthLabel={`${t(I18N_NAMESPACE, "containers.equipmentDetailSidebar.location.latlong")} `}
                    road={road}
                />
            </Styled.RoadDetailWrapper>
            <Styled.Wrapper isMobile={isMobile}>
                {
                    isError ? <Styled.ErrorText>
                        {t(I18N_NAMESPACE, "containers.horizontalSignDetailSidebar.content.error")}
                    </Styled.ErrorText>
                        : <EquipmentDetails equipment={data} />
                }
            </Styled.Wrapper>
        </Styled.Container>
        <CatalogItemEditModal
            FormComponent={EquipmentLocationEditFormPost}
            formTitle={t(I18N_NAMESPACE, "containers.equipmentDetailsForm.title")}
            signId={equipmentId}
            startingPosition={{ longitude: latLong[0], latitude: latLong[1] }}
            startingRoad={data?.linear_location.road}
            open={modalOpen}
            onClose={() => setModalOpen(false)}
        />
    </>
}