import { useCallback } from "react"

import { CatalogItemsFiltersFormError, CatalogItemsFiltersFormSkeleton } from "@l2r-front/l2r-common"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { HorizontalSignFiltersFormPost } from "../../forms/HorizontalSignFiltersForm"
import { useHorizontalSigningProject } from "../../hooks/queries/horizontalSigning/useHorizontalSigningProject"

import * as Styled from "./HorizontalSignFilters.styled"

export function HorizontalSignFilters(props) {

    const {
        className,
        close,
        displayFilters,
    } = props

    const { data, isError, isLoading } = useHorizontalSigningProject()

    const HorizontalSignFiltersContent = useCallback(() => {
        if (isError) {
            return <CatalogItemsFiltersFormError />
        } else if (isLoading) {
            return <CatalogItemsFiltersFormSkeleton />
        }
        return <HorizontalSignFiltersFormPost close={close} projectTags={data?.tags} />
    }, [close, data, isError, isLoading])


    return <div className={className}>
        <Styled.Collapse in={displayFilters}>
            <HorizontalSignFiltersContent close={close} />
        </Styled.Collapse>
    </div>
}

HorizontalSignFilters.propTypes = {
    className: PropTypes.string,
    close: PropTypes.func.isRequired,
    displayFilters: PropTypes.bool.isRequired,
}