import { createContext } from "react"

export const initialState = {
    onBack: null,
    typeSelectionDisplayed: false,
}

const initialDispatch = {}

export const CatalogItemEditStateContext = createContext(initialState)
export const CatalogItemEditDispatchContext = createContext(initialDispatch)