import { useCallback } from "react"

import { CatalogItemsFiltersForm } from "@l2r-front/l2r-common"

import { useHorizontalSigningDispatchContext, useHorizontalSigningStateContext } from "../../contexts/HorizontalSigningContext/HorizontalSigningContext.hooks"
import { useHorizontalSigningProject } from "../../hooks/queries/horizontalSigning/useHorizontalSigningProject"


export function HorizontalSignFiltersFormPost(props) {
    const {
        close,
        ...verticalSignFiltersFormProps
    } = props

    const { setFilters } = useHorizontalSigningDispatchContext()
    const { filters } = useHorizontalSigningStateContext()
    const { data: project } = useHorizontalSigningProject()

    const onSubmit = useCallback((values) => {
        setFilters(values)
        close()
    }, [close, setFilters])

    return <CatalogItemsFiltersForm
        {...verticalSignFiltersFormProps}
        activeFilters={filters}
        close={close}
        onSubmit={onSubmit}
        project={project}
        resetFilters={() => setFilters({ tags: [] })}
    />
}