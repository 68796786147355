import { horizontalSigningQueryKeys } from "./queryKeys"
import { useHorizontalSigningQuery } from "./useHorizontalSigningQueries"


export function useHorizontalSigningCategoriesStats(parameters = {}, config = {}) {
    const url = "horizontal_signs/stats/horizontal_sign_types/"

    const queryKey = horizontalSigningQueryKeys["stats_categories"]({ parameters })

    return useHorizontalSigningQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
        },
    )
}