import { useQueryClient } from "@tanstack/react-query"

import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

import { verticalSigningQueryKeys } from "../queries/verticalSigning/queryKeys"

import { useVerticalSigningMutation } from "./useVerticalSigningMutation"

export function useVerticalSignCreate(
    queryParameters,
    config = {},
) {
    const url = "vertical_signs/"
    const queryClient = useQueryClient()

    return useVerticalSigningMutation(
        url,
        axiosInstance.post,
        {
            ...queryParameters,
        },
        {
            ...config,
            onSuccess: (data) => {
                const poleId = data.vertical_sign_pole
                const poleQueries = queryClient.getQueriesData({ queryKey: verticalSigningQueryKeys.pole(poleId, {}) })
                if (poleQueries?.length) {
                    queryClient.setQueriesData({ queryKey: verticalSigningQueryKeys.pole(poleId, {}) }, (oldData) => {
                        if (!oldData.vertical_signs) {
                            return oldData
                        }

                        const newData = {
                            ...oldData,
                            vertical_signs: [...oldData.vertical_signs, data],
                        }
                        return newData
                    })
                }
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.project() })
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.layer() })
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.stats_categories() })
                queryClient.invalidateQueries({ queryKey: verticalSigningQueryKeys.stats_conditions() })
                config.onSuccess?.(data)
            },
        },
    )
}