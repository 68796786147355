import { useCallback, useEffect, useMemo } from "react"

import { useMatch, useRoutes } from "react-router-dom"

import { CatalogItemLayers } from "@l2r-front/l2r-common"
import { ImageCollectionLayer, ImageSelectedLayer, useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { NetworkReferentialLayer, useNavigateToLinearLocation, useNetworksDispatchContext } from "@l2r-front/l2r-networks"

import { APP_CITY } from "../../../../common/constants/appCity"
import { MODULE_VERTICAL_SIGNING } from "../../../../common/constants/modules"
import { VerticalSignPolesSource } from "../../containers/VerticalSignPolesSource"

import { VerticalSignPoleValidator } from "../VerticalSignPoleValidator"

export function VerticalSigningMapLayersRouter() {

    const match = useMatch(`:slugId/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/:road/*`)
    const road = decodeURIComponent(match?.params?.road)
    const isSegmentView = !!match

    const navigateToLinearLocation = useNavigateToLinearLocation()
    const { setSegmentFeatureIdClicked } = useImageCollectionDispatchContext()
    const { setSearchRoadCallback } = useMapDispatchContext()
    const { setRoadSearched, setSelectedLinearLocations } = useNetworksDispatchContext()

    const processNavigation = useCallback((feature, location) => {
        if (location && location.road && location.road !== road) {
            setSegmentFeatureIdClicked(feature?.properties?.uuid)
            setSelectedLinearLocations(feature)
            navigateToLinearLocation(location, ".", { saveMapBoundingBox: !isSegmentView })
        }
    }, [setSegmentFeatureIdClicked, setSelectedLinearLocations, navigateToLinearLocation, isSegmentView, road])

    const onFeatureClick = useCallback(feature => {
        const linearLocation = JSON.parse(feature.properties.linearLocation)
        if (linearLocation?.[0]?.road !== road) {
            setRoadSearched(null)
            processNavigation(feature, linearLocation?.[0])
        }
    }, [processNavigation, setRoadSearched, road])

    useEffect(() => {
        setSearchRoadCallback((searchedRoad) => {
            processNavigation(null, { road: searchedRoad })
        })

        return () => setSearchRoadCallback(null)
    }, [
        setSearchRoadCallback,
        processNavigation])

    const layers = useMemo(() => {
        return (
            <>
                <NetworkReferentialLayer onClickOnFeature={onFeatureClick} />
                <CatalogItemLayers Source={VerticalSignPolesSource} />
                {isSegmentView && <ImageCollectionLayer road={road} />}
                {isSegmentView && <ImageSelectedLayer />}
            </>
        )
    }, [isSegmentView, onFeatureClick, road])

    const layersRoutes = useMemo(() => ([
        { path: "/", element: layers },
        { path: "/:road", element: layers },
        { path: "/:road/:signId", element: <VerticalSignPoleValidator>{layers}</VerticalSignPoleValidator> },
    ]), [layers])

    return useRoutes(layersRoutes)
}