import * as turf from "@turf/turf"

import { LINESTRING_FEATURE_COLLECTION, LINESTRING_GEOMETRY, MULTILINESTRING_GEOMETRY } from "../constants/geojson/lineTypes"
import { MULTIPOINT_GEOMETRY, POINT_GEOMETRY } from "../constants/geojson/pointsTypes"

export function getFeatureCenterPoint(feature) {
    if (!feature) {
        return null
    }

    const type = feature?.geometry?.type || feature?.type

    if (type === POINT_GEOMETRY) {
        return feature
    }
    if (type === MULTIPOINT_GEOMETRY) {
        const points = turf.points(feature.geometry.coordinates)
        return turf.center(points)
    }
    if (type === LINESTRING_GEOMETRY) {
        const featureLength = turf.length(feature.geometry)
        return turf.along(feature.geometry, featureLength / 2)
    }
    if (type === MULTILINESTRING_GEOMETRY || type === LINESTRING_FEATURE_COLLECTION) {
        const multiLineStringFeature = type === LINESTRING_FEATURE_COLLECTION ? feature
            : turf.multiLineString(feature.geometry.coordinates)
        const halfFeatureLength = turf.length(multiLineStringFeature) / 2
        const flattenFeature = turf.flatten(multiLineStringFeature)
        const options = flattenFeature.features.reduce((alongOptions, feature) => {
            const featureLength = turf.length(feature)
            if (alongOptions.feature) {
                return alongOptions
            } else if ((alongOptions.distance + featureLength) >= halfFeatureLength) {
                return {
                    feature: feature,
                    distance: halfFeatureLength - alongOptions.distance,
                }
            } else {
                return {
                    feature: null,
                    distance: featureLength + alongOptions.distance,
                }
            }
        }, { feature: null, distance: 0 })
        return turf.along(options.feature, options.distance)
    }
}