import { useCallback, useState } from "react"


import { useToggle } from "react-use"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { DeleteIcon, MoreVertRoundedIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice, IconButton, CTAButton, Menu, MenuItem, TruncableText, Typography } from "@l2r-front/l2r-ui"
import { formatNumber } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"

import * as Styled from "./RoadworkTask.styled"

export function RoadworkTaskComponent(props) {

    const {
        closed,
        currency,
        editionEnabled,
        id,
        isDeletable,
        onCommentToggled,
        onDelete,
        onEdit,
        task,
        taskType,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const isMobile = useIsMobileDevice()

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const [commentDisplayed, toggleCommentDisplayed] = useToggle(false)

    const handleMenuClick = useCallback((event) => {
        setAnchorEl(event.currentTarget)
    }, [])

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleCommentDisplayedToggle = useCallback(() => {
        const commentShouldDisplay = closed || !commentDisplayed
        toggleCommentDisplayed(commentShouldDisplay)
        onCommentToggled(commentShouldDisplay)
    }, [onCommentToggled, toggleCommentDisplayed, closed, commentDisplayed])

    const taskCost = `${formatNumber(task.cost)} ${currency.symbol}`

    return <Styled.RoadworkTaskWrapper id={id}>
        {!isMobile && <Styled.RoadworkTaskInfo withButton={false}>
            <Styled.LeftWrapper>
                <Typography variant="H3">{taskType?.name}</Typography>
                {task.comment &&
                    <CTAButton id="task-comment-button-placeholder" variant="link" type="button" onClick={handleCommentDisplayedToggle}>
                        <Typography variant="Small">{t(I18N_NAMESPACE, "components.roadworkTask.comment")}</Typography>
                    </CTAButton>}
            </Styled.LeftWrapper>
            <Styled.RightWrapper>
                <Styled.TextWrapper>
                    <Styled.Text variant="Regular" isMobile={isMobile}>{taskCost}</Styled.Text>
                    <Styled.Quantity variant="Regular">{task.quantity} {task.unit}</Styled.Quantity>
                </Styled.TextWrapper>
                <Styled.TaskImpactBadge impact={taskType?.impact} variant="outlined" />
            </Styled.RightWrapper>
            {editionEnabled && <Styled.Overlay id="task-overlay">
                <Styled.RoadworkTaskInfo withButton={false}>
                    <Styled.LeftWrapper>
                        <Typography variant="H3">{taskType?.name}</Typography>
                        {task.comment && <CTAButton id="task-comment-button" variant="link" type="button" onClick={handleCommentDisplayedToggle}>
                            <Typography variant="Small">{t(I18N_NAMESPACE, "components.roadworkTask.comment")}</Typography>
                        </CTAButton>}
                    </Styled.LeftWrapper>
                    <Styled.RightWrapper>
                        <Styled.TextWrapper hidden={true}>
                            <Styled.Text variant="Regular" isMobile={isMobile}>{taskCost}</Styled.Text>
                            <Styled.Quantity variant="Regular">{task.quantity} {task.unit}</Styled.Quantity>
                        </Styled.TextWrapper>
                        <Styled.TaskImpactBadge impact={taskType?.impact} variant="outlined" hidden={true} />
                        <Styled.OverlayButtonsContainer>
                            {isDeletable && <IconButton id="task-delete-button" onClick={onDelete}>
                                <DeleteIcon color="cta-bg/cta-bg-primary" />
                            </IconButton>}
                            <CTAButton id="task-edit-button" onClick={() => onEdit?.()}>{t(I18N_NAMESPACE, "components.roadworkTask.edit")}</CTAButton>
                        </Styled.OverlayButtonsContainer>
                    </Styled.RightWrapper>
                </Styled.RoadworkTaskInfo>
            </Styled.Overlay>}
        </Styled.RoadworkTaskInfo>
        }
        {isMobile && <>
            <Typography variant="H3">{taskType?.name}</Typography>
            <Styled.RoadworkTaskInfo withButton={editionEnabled}>
                <Styled.LeftWrapper>
                    <Styled.TextWrapper>
                        <Styled.Text variant="Regular" isMobile={isMobile}>{taskCost}</Styled.Text>
                        <Styled.Quantity variant="Regular">{task.quantity} {task.unit}</Styled.Quantity>
                    </Styled.TextWrapper>
                </Styled.LeftWrapper>
                <Styled.RightWrapper>
                    <Styled.TextWrapper>
                        <Styled.TaskImpactBadge impact={taskType?.impact} variant="outlined" />
                    </Styled.TextWrapper>
                </Styled.RightWrapper>
                {editionEnabled &&
                    <div>
                        <IconButton id="task-menu" onClick={handleMenuClick}>
                            <MoreVertRoundedIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: "center",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "center",
                                horizontal: "right",
                            }}
                            open={open}
                            onClose={handleMenuClose}
                        >
                            <MenuItem key={"task-menu-edit"} id={"task-menu-edit"} onClick={() => onEdit?.()}>
                                {t(I18N_NAMESPACE, "components.roadworkTask.edit")}
                            </MenuItem>
                            {isDeletable && <MenuItem key={"task-menu-delete"} id={"task-menu-delete"} onClick={onDelete}>
                                {t(I18N_NAMESPACE, "components.roadworkTask.delete")}
                            </MenuItem>}
                        </Menu>
                    </div>
                }
            </Styled.RoadworkTaskInfo>
            {task.comment && <CTAButton id="task-comment-button" variant="link" type="button" onClick={handleCommentDisplayedToggle}>
                <Typography variant="Small">{t(I18N_NAMESPACE, "components.roadworkTask.comment")}</Typography>
            </CTAButton>}
        </>
        }
        {!closed && commentDisplayed && <Styled.Comments id="task-comment-text">
            <TruncableText>
                {task.comment}
            </TruncableText>
        </Styled.Comments>}
    </Styled.RoadworkTaskWrapper>
}

RoadworkTaskComponent.propTypes = {
    closed: PropTypes.bool,
    currency: PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        symbol: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    isDeletable: PropTypes.bool,
    editionEnabled: PropTypes.bool,
    onDelete: PropTypes.func.isRequired,
    onCommentToggled: PropTypes.func,
    onEdit: PropTypes.func,
    task: PropTypes.task.isRequired,
    taskType: PropTypes.oneOfType([
        PropTypes.taskType.isRequired,
        PropTypes.arrayOf(PropTypes.taskType.isRequired).isRequired,
    ]),
}

RoadworkTaskComponent.defaultProps = {
    closed: true,
    editionEnabled: true,
}