import {
    Box as UiBox,
    SwipeableDrawer as UiSwipeableDrawer,
    styled,
} from "@mui/material"

export const SwipeableDrawer = styled(UiSwipeableDrawer)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: theme.spacing(7, 7, 0, 0),
    },
}))

export const Box = styled(UiBox)(({ theme }) => ({
    padding: theme.spacing(6, 6, 8, 6),
    borderTopLeftRadius: theme.spacing(7),
    borderTopRightRadius: theme.spacing(7),
}))