import { Button as UiButton, styled } from "@mui/material"

import { StreetViewRoundedIcon } from "@l2r-front/l2r-icons"

export const Button = styled(UiButton)(() => ({
    width: "fit-content",
}))

export const Icon = styled(StreetViewRoundedIcon)(({ theme }) => ({
    marginLeft: theme.spacing(3),
}))