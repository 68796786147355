import { useCallback, useMemo } from "react"

import { CatalogItemDetailsForm } from "@l2r-front/l2r-common"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { VERTICAL_SIGNING_CONDITIONS, VERTICAL_SIGNING_CONDITIONS_VALUES } from "../../constants/verticalSigningConditions"
import { useVerticalSignUpdate } from "../../hooks/mutations/useVerticalSignUpdate"
import { useVerticalSign } from "../../hooks/queries/verticalSigning/useVerticalSign"
import { useVerticalSigningProject } from "../../hooks/queries/verticalSigning/useVerticalSigningProject"
import { useVerticalSignTypes } from "../../hooks/queries/verticalSigning/useVerticalSignTypes"

import * as Styled from "./VerticalSignDetailsForm.styled"

export const VerticalSignDetailsFormPost = (props) => {

    const {
        className,
        close,
        signId,
        poleId,
        ...otherProps
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    const { data: verticalSign, isLoading, isRefetching, isError } = useVerticalSign(signId)
    const { data: project } = useVerticalSigningProject()
    const { data: verticalSignTypes, isLoading: isLoadingTypes, isError: isErrorTypes } = useVerticalSignTypes()

    const existingTags = useMemo(() => {
        if (project?.tags) {
            return project.tags.sort()
        }

        return []
    }, [project])

    const conditions = useMemo(() => {
        return VERTICAL_SIGNING_CONDITIONS.filter(condition => condition > 0)
            .map(condition => ({
                value: condition,
                label: t(I18N_NAMESPACE, VERTICAL_SIGNING_CONDITIONS_VALUES[condition].label),
            }))
    }, [t])

    const mutation = useVerticalSignUpdate(signId, poleId, {}, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.snackbar.error"),
        successSnackbarMessage: t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.snackbar.success"),
    })

    const onSubmit = useCallback((values) => {
        mutation.mutate(values)
        close(false)
    }, [close, mutation])

    if (isLoading || isRefetching || isLoadingTypes) {
        return <Styled.FormSkeleton className={className} />
    }

    if (isError || isErrorTypes) {
        return <Styled.ErrorMessage>{t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.error")}</Styled.ErrorMessage>
    }

    return <CatalogItemDetailsForm
        className={className}
        {...otherProps}
        initialValues={verticalSign}
        catalogTypes={verticalSignTypes}
        typeField="vertical_sign_type"
        typeFieldLabel={t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.type")}
        conditions={conditions}
        existingTags={existingTags}
        onSubmit={onSubmit}
        onCancel={close}
    />
}

VerticalSignDetailsFormPost.propTypes = {
    className: PropTypes.string,
    close: PropTypes.func.isRequired,
    signId: PropTypes.string.isRequired,
    poleId: PropTypes.string.isRequired,
}