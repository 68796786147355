import { useMemo } from "react"

import { useMatch, useRoutes } from "react-router-dom"

import { DetailSegmentSelectListener, SegmentSelectListener } from "@l2r-front/l2r-common"
import { MapAdapterRoad, MapAdapterBoundingBox, MapAdapterFeature } from "@l2r-front/l2r-networks"

import { APP_ROAD } from "../../../../common/constants/appRoad"
import { MODULE_ROADWORKS } from "../../../../common/constants/modules"

import { RoadworkValidator } from "../RoadworkValidator/RoadworkValidator"

export function RoadworksDesktopRoutes() {

    const match = useMatch(`:slugId/${APP_ROAD}/${MODULE_ROADWORKS}/:road/:roadworkUuid`)

    const roadworksRoutes = useMemo(() => ([
        {
            path: "/",
            element: <>
                <MapAdapterBoundingBox />
                <SegmentSelectListener />
            </>,
        },
        {
            path: "/:road/*",
            element: <>
                <MapAdapterRoad />
                <DetailSegmentSelectListener />
            </>,
        },
        {
            path: "/:road/:roadworkUuid/*",
            element: <RoadworkValidator>
                <MapAdapterFeature featureId={match?.params?.roadworkUuid} />
                <DetailSegmentSelectListener />
            </RoadworkValidator>,
        },
    ]), [match])

    return useRoutes(roadworksRoutes)
}