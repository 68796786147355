import { ReactMapGl } from "@l2r-front/l2r-map"
import { RoadSearch } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { Map } from "../../../../common/containers/Map"
import { RoadMapLegend } from "../../../../common/containers/RoadMapLegend"

import * as Styled from "./MobileHorizontalSigningMap.styled"

export function MobileHorizontalSigningMap(props) {
    const {
        children,
        ...mapProps
    } = props

    return (
        <Styled.Container>
            <Map
                {...mapProps}
                topLeftElements={null}
                topRightElements={[<RoadSearch key="road-search" disableExpend={false} />, <RoadMapLegend key="road-map-legend" />]}
            >
                {children}
            </Map>
        </Styled.Container >
    )
}

MobileHorizontalSigningMap.propTypes = {
    ...ReactMapGl.propTypes,
    className: PropTypes.string,
}