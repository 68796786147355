import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { mirandaQueryKeys } from "./queryKeys"
import { useMirandaQuery } from "./useMirandaQuery"

export const useMirandaStats = (queryParams, config = {}) => {
    const url = "network_grades/stats/"
    const { selectedNetwork } = useNetworksStateContext()

    const queryKey = mirandaQueryKeys.stats(selectedNetwork?.slug, queryParams)

    return useMirandaQuery(
        url,
        queryKey,
        { project: selectedNetwork?.slug, ...queryParams },
        {
            enabled: !!selectedNetwork,
            ...config,
        },
    )
}