import { useMemo } from "react"

import { useParams } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CatalogItemsCount, CatalogItemsCountSkeleton } from "@l2r-front/l2r-ui"

import { useEquipmentStateContext } from "../../contexts/EquipmentContext"
import { useEquipmentsCategoriesStats } from "../../hooks/queries/equipment/useEquipmentsCategoriesStats"
import { useEquipmentTypes } from "../../hooks/queries/equipment/useEquipmentsType"

export function EquipmentsCount(props) {

    const {
        className,
        displayTotalCount,
        isMainCount,
        signType,
    } = props

    const { road } = useParams()
    const { filters } = useEquipmentStateContext()

    const apiFilters = {
        ...(filters?.conditions?.length && { condition: filters?.conditions }),
        ...(filters?.tags?.length && { tags: filters.tags.join(",") }),
        ...(road && { linear_location_road: road }),
    }

    const { data: dataWithFilters,
        isError: dataWithFiltersError,
        isLoading: dataWithFiltersLoading,
    } = useEquipmentsCategoriesStats({ ...apiFilters })

    const {
        data: dataWithoutConditionFilters,
        isError: dataWithoutConditionFiltersError,
        isLoading: dataWithoutConditionFiltersLoading,
    } = useEquipmentsCategoriesStats({ ...(road && { linear_location_road: road }) })

    const {
        data: equipmentTypes,
        isError: isErrorEquipmentTypes,
        isLoading: isLoadingEquipmentTypes,
    } = useEquipmentTypes()

    const calculatedCount = useMemo(() => {
        if (signType) {
            return dataWithFilters?.count[signType] || 0
        } else if (dataWithFilters && equipmentTypes) {
            return equipmentTypes.reduce((acc, equipmentType) => {
                return acc + (dataWithFilters.count[equipmentType.code] || 0)
            }, 0)
        } else {
            return 0
        }
    }, [dataWithFilters, equipmentTypes, signType])

    if (dataWithFiltersError || dataWithoutConditionFiltersError || isErrorEquipmentTypes) {
        return null
    }

    if (dataWithFiltersLoading || dataWithoutConditionFiltersLoading || isLoadingEquipmentTypes) {
        return <CatalogItemsCountSkeleton className={className} isMainCount={isMainCount} />
    }

    return <CatalogItemsCount
        className={className}
        filteredCount={calculatedCount}
        isMainCount={isMainCount}
        totalCount={displayTotalCount && dataWithoutConditionFilters?.count?.[signType]}
    />
}

EquipmentsCount.propTypes = {
    className: PropTypes.string,
    displayTotalCount: PropTypes.bool,
    isMainCount: PropTypes.bool,
    signType: PropTypes.string,
}