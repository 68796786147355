import { styled } from "@l2r-front/l2r-ui"

const ICON_SIZE = 36

export const TitleWrapper = styled("div")({
    alignItems: "center",
    display: "flex",
})

export const Icon = styled("img")(({ theme }) => ({
    height: ICON_SIZE,
    width: ICON_SIZE,
    marginRight: theme.spacing(9),
}))