import { useCallback, useMemo } from "react"

import * as turf from "@turf/turf"

import { CatalogItemDetailsForm } from "@l2r-front/l2r-common"
import { sanitizeData } from "@l2r-front/l2r-geodata"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { useNetworkReferential } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useEquipmentUpdate } from "../../hooks/mutations/useEquipmentUpdate"


export const EquipmentLocationEditFormPost = (props) => {

    const {
        close,
        signId,
        startingPosition,
        startingRoad,
        ...otherProps
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const navigate = useNavigateWithSearchParams()

    const { data: referential } = useNetworkReferential()
    const sanitizedReferential = useMemo(() => {
        return sanitizeData(referential)
    }, [referential])

    const mutation = useEquipmentUpdate(signId, {}, {
        onSuccess: (data) => navigate(`../${data.linear_location.road}/${data.uuid}`),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.equipmentLocationEditForm.snackbar.error"),
        successSnackbarMessage: t(I18N_NAMESPACE, "containers.equipmentLocationEditForm.snackbar.success"),
    })

    const onSubmit = useCallback(values => {

        const linearLocation = {
            typeid: 0,
            road: values.road.road,
            start: values.road.start,
            end: values.road.start + 1,
        }
        const point = turf.point([values.position.longitude, values.position.latitude])

        mutation.mutate({ linear_location: linearLocation, geometry: point.geometry })

        close()
    }, [close, mutation])

    return <CatalogItemDetailsForm
        {...otherProps}
        disableMaxDistanceCheck
        forceTextContent
        referential={sanitizedReferential}
        startingPosition={startingPosition}
        startingRoad={startingRoad}
        onSubmit={onSubmit}
        onCancel={close}
    />
}

EquipmentLocationEditFormPost.propTypes = {
    close: PropTypes.func.isRequired,
    signId: PropTypes.string.isRequired,
    startingPosition: PropTypes.shape({
        longitude: PropTypes.number,
        latitude: PropTypes.number,
    }).isRequired,
    startingRoad: PropTypes.string,
}