import { useMemo } from "react"

import { useMatch } from "react-router-dom"

import { useEquipmentStateContext } from "../../../modules/equipment/contexts/EquipmentContext"
import { useVerticalSigningStateContext } from "../../../modules/verticalSigning/contexts/VerticalSigningContext"
import { APP_CITY } from "../../constants/appCity"
import { MODULE_EQUIPMENT, MODULE_VERTICAL_SIGNING } from "../../constants/modules"
import { CityContentRouter } from "../../routers/CityContentRouter"
import { CityDesktopMapRouter } from "../../routers/CityDesktopMapRouter"
import { SidebarRouter } from "../../routers/SidebarRouter"

import * as Styled from "./CityDesktopLayout.styled"

export function CityDesktopLayout() {
    const {
        ascendantCodes: verticalSigningAscendantCodes,
        descendantCodes: verticalSigningDescendantCodes,
    } = useVerticalSigningStateContext()
    const {
        ascendantCodes: equipmentAscendantCodes,
        descendantCodes: equipmentDescendantCodes,
    } = useEquipmentStateContext()

    const matchVerticalSigning = useMatch(`:slugId/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/*`)
    const matchEquipment = useMatch(`:slugId/${APP_CITY}/${MODULE_EQUIPMENT}/*`)
    const matchDetailVerticalSigning = useMatch(`:slugId/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/:road/:poleId`)
    const matchDetailEquipment = useMatch(`:slugId/${APP_CITY}/${MODULE_EQUIPMENT}/:road/:poleId`)

    const sidebarCount = useMemo(() => {
        if (matchVerticalSigning) {
            const hasAdditionalSidebar = verticalSigningAscendantCodes.length % 2 === 1 || verticalSigningDescendantCodes.length > 1
            return matchDetailVerticalSigning ? 1 : Math.floor(verticalSigningAscendantCodes.length / 2) + (hasAdditionalSidebar ? 1 : 0)

        }
        if (matchEquipment) {
            const hasAdditionalSidebar = equipmentAscendantCodes.length % 2 === 1 || equipmentDescendantCodes.length > 1
            return matchDetailEquipment ? 1 : Math.floor(equipmentAscendantCodes.length / 2) + (hasAdditionalSidebar ? 1 : 0)

        }
    }, [verticalSigningAscendantCodes, verticalSigningDescendantCodes, equipmentAscendantCodes, equipmentDescendantCodes, matchDetailVerticalSigning, matchDetailEquipment, matchEquipment, matchVerticalSigning])

    return (
        <Styled.Wrapper>
            <SidebarRouter />
            <CityContentRouter />
            <Styled.Map sidebarCount={sidebarCount}>
                <CityDesktopMapRouter />
            </Styled.Map>
        </Styled.Wrapper>
    )
}
