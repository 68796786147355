import { useMemo } from "react"

import { getYear, parseISO } from "date-fns"

import { MIRANDA_GRADE_TYPE } from "../../../../../common/constants/urlParams"

import { useMirandaProject } from "./useMirandaProject"
import { useMirandaStats } from "./useMirandaStats"

export function useRelevantMirandaGradings(queryParameters, config) {

    const {
        data: stats,
        isLoading: isLoadingStats,
        isError: isErrorStats,
    } = useMirandaStats(queryParameters, config)

    const {
        data: project,
        isLoading: isLoadingProject,
        isError: isErrorProject,
    } = useMirandaProject(config)

    const projectCreationYear = getYear(parseISO(project?.created_at || new Date()))
    const currentYear = getYear(new Date())
    const displayedYears = Array.from({ length: currentYear - projectCreationYear + 1 }, (_, i) => projectCreationYear + i)
    const isLoading = isLoadingStats || isLoadingProject
    const isError = isErrorStats || isErrorProject

    const sortedRelevantMirandaGradings = useMemo(() => {
        if (isLoading || isError || !displayedYears.length) {
            return null
        }
        return displayedYears.map(year => {
            const stat = stats?.grade?.[year]
            return ({
                ...(stat && { grade: stat }),
                type: MIRANDA_GRADE_TYPE,
                uuid: year,
                year: parseInt(year),
            })
        }).sort((a, b) => a.year - b.year)
    }, [displayedYears, isError, isLoading, stats])

    return { isLoading, isError, sortedRelevantMirandaGradings }
}