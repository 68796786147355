import { useMemo } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ListRoundedIcon, MapRoundedIcon } from "@l2r-front/l2r-icons"
import { MobileModulePage } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { PARAM_KEY_EQUIPMENT } from "../../../../common/constants/urlParams"
import { EquipmentMobileContent } from "../../containers/EquipmentMobileContent"
import { EquipmentMapLayersRouterMobile } from "../../routers/EquipmentMapLayersRouter"

import * as Styled from "./MobileEquipmentPage.styled"

export function MobileEquipmentPage() {

    const { t } = useTranslation(I18N_NAMESPACE)

    const equipmentListTabs = useMemo(() => ([
        {
            element: <EquipmentMobileContent />,
            icon: <ListRoundedIcon />,
            id: "tab-equipment",
            label: t(I18N_NAMESPACE, "pages.equipment.tabs.equipment"),
        },
        {
            element: <Styled.Map >
                <EquipmentMapLayersRouterMobile />
            </Styled.Map>,
            icon: <MapRoundedIcon />,
            id: "tab-map",
            label: t(I18N_NAMESPACE, "pages.equipment.tabs.map"),
            fullScreen: true,
        },
    ]), [t])

    return (
        <MobileModulePage paramKey={PARAM_KEY_EQUIPMENT} tabsConfig={equipmentListTabs} />
    )
}