import { useCallback, useEffect, useMemo } from "react"

import { getYear } from "date-fns"
import _ from "lodash"

import { useImageCollectionDispatchContext } from "@l2r-front/l2r-images"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { EQUIPMENT_CODE_URL_PARAM, EQUIPMENT_CONDITION_URL_PARAM, EQUIPMENT_TAGS_URL_PARAM } from "../../../../common/constants/urlParams"
import { useEquipmentDescendantCodes } from "../../hooks/utils/useEquimentDescendantCodes"
import { useEquipmentAscendantCodes } from "../../hooks/utils/useEquipmentAscendantCodes"

import { EquipmentDispatchContext, EquipmentStateContext } from "./EquipmentContext.context"


export const EquipmentContextProvider = (props) => {

    const { children } = props
    const { getParam, setParam, deleteParam } = useUrlParams()
    const { setImageCollectionCurrentYear } = useImageCollectionDispatchContext()
    const typeFilter = getParam(EQUIPMENT_CODE_URL_PARAM)
    const conditionFilter = getParam(EQUIPMENT_CONDITION_URL_PARAM)
    const tagsFilter = getParam(EQUIPMENT_TAGS_URL_PARAM)

    const ascendantCodes = useEquipmentAscendantCodes(typeFilter)
    const descendantCodes = useEquipmentDescendantCodes(typeFilter)

    const setFilters = useCallback((newFilters) => {
        if (newFilters?.type && typeFilter !== newFilters.type) {
            setParam(EQUIPMENT_CODE_URL_PARAM, newFilters.type)
        } else if (newFilters.type === null) {
            deleteParam(EQUIPMENT_CODE_URL_PARAM)
        }

        if (newFilters?.condition) {
            let conditionFilterArray = conditionFilter?.split(",") || []
            if (conditionFilterArray.includes(newFilters.condition)) {
                const index = conditionFilterArray.findIndex(condition => {
                    return condition === newFilters.condition
                })
                conditionFilterArray.splice(index, 1)
            } else {
                conditionFilterArray.push(newFilters.condition)
            }
            if (conditionFilterArray.length) {
                setParam(EQUIPMENT_CONDITION_URL_PARAM, conditionFilterArray.join(","))
            } else {
                deleteParam(EQUIPMENT_CONDITION_URL_PARAM)
            }
        }

        if (newFilters?.tags) {
            if (newFilters?.tags.length) {
                setParam(EQUIPMENT_TAGS_URL_PARAM, newFilters?.tags.join(","))
            } else {
                deleteParam(EQUIPMENT_TAGS_URL_PARAM)
            }
        }
    }, [conditionFilter, deleteParam, setParam, typeFilter])

    const stateValue = useMemo(() => {
        return {
            ascendantCodes,
            descendantCodes,
            filters: {
                ...(conditionFilter && { conditions: conditionFilter }),
                tags: tagsFilter?.split(",") || [],
                ...(typeFilter && { type: typeFilter }),
            },
        }
    }, [ascendantCodes,
        conditionFilter,
        descendantCodes,
        tagsFilter,
        typeFilter,
    ])

    const dispatchValue = useMemo(() => {
        return {
            setFilters,
        }
    }, [setFilters])

    useEffect(() => {
        const currentYear = getYear(new Date())
        setImageCollectionCurrentYear(parseInt(currentYear))
    }, [setImageCollectionCurrentYear])

    return (
        <EquipmentStateContext.Provider value={stateValue}>
            <EquipmentDispatchContext.Provider value={dispatchValue}>
                {children}
            </EquipmentDispatchContext.Provider>
        </EquipmentStateContext.Provider>
    )
}

EquipmentContextProvider.propTypes = {
    children: PropTypes.node,
}
