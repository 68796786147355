import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

import { useVerticalSigningMutation } from "./useVerticalSigningMutation"

export function useVerticalSignPoleCreate(
    queryParameters,
    config = {},
) {
    const url = "vertical_sign_poles/"

    return useVerticalSigningMutation(
        url,
        axiosInstance.post,
        {
            ...queryParameters,
        },
        {
            ...config,
        },
    )
}