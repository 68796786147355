import { useCallback, useState } from "react"

import { useParams } from "react-router-dom"

import { CatalogItemEditModal, useGetViewCenter } from "@l2r-front/l2r-common"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { TAGS_FILTER } from "../../constants/filters"
import { HorizontalSignAddFormPost } from "../../forms/HorizontalSignAddForm"

import * as Styled from "./HorizontalSignsSidebarComponents.styled"

export function HorizontalSignsSidebarComponents(props) {

    const { handleChangeTypeFilter, toggleFilter } = props

    const [mapLocation, setMapLocation] = useState(null)


    const { t } = useTranslation(I18N_NAMESPACE)
    const isMobile = useIsMobileDevice()
    const { road, code } = useParams()
    const getViewCenter = useGetViewCenter()

    const handleCreateSign = useCallback(() => {
        setMapLocation(getViewCenter)
    }, [getViewCenter])

    const handleCloseModal = useCallback(() => {
        setMapLocation(null)
    }, [])

    return <>
        {isMobile && <Styled.MobileFilterButton enabledFilters={[TAGS_FILTER]} onClick={toggleFilter} />}
        {road && <Styled.RoadDetailInfo isMobile={isMobile} road={road} />}
        <Styled.HorizontalSignsGrading isMobile={isMobile} />
        <Styled.HorizontalSignTypeList
            baseHorizontalSignTypeCode={code}
            isMobile={isMobile}
            onChangeFilter={handleChangeTypeFilter}
            onCreate={handleCreateSign}
        />
        {
            mapLocation &&
            <CatalogItemEditModal
                formTitle={t(I18N_NAMESPACE, "containers.horizontalSignAddForm.title")}
                typeTitle={t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.type")}
                contentFieldLabel={t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.content")}
                FormComponent={HorizontalSignAddFormPost}
                startingPosition={mapLocation}
                open={!!mapLocation}
                onClose={handleCloseModal}
            />
        }
    </>
}

HorizontalSignsSidebarComponents.propTypes = {
    handleChangeTypeFilter: PropTypes.func,
    toggleFilter: PropTypes.func,
}