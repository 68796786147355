import { useCallback } from "react"

import { useEquipmentTypes } from "../queries/equipment/useEquipmentsType"

function findCodeIndex(code, catalog, depth) {
    const codeIndex = catalog.findIndex(item => item.code === code)

    if (codeIndex >= 0) {
        return depth
    } else {
        for (let item of catalog) {
            const codeDepth = findCodeIndex(code, item.children, depth + 1)
            if (codeDepth >= 0) {
                return codeDepth
            }
        }

        return -1
    }
}

export function findEquipmentTypeByCode(code, catalog) {
    const verticalSignType = catalog.find(item => item.code === code)

    if (verticalSignType) {
        return verticalSignType
    } else {
        for (let item of catalog) {
            const type = findEquipmentTypeByCode(code, item.children)
            if (type) {
                return type
            }
        }

        return null
    }
}

export function getFinalEquipmentTypes(catalog) {

    const getLeafTypes = (types) => {
        let leafTypes = []

        types.forEach(type => {
            if (!type.children?.length) {
                leafTypes.push(type)
            } else {
                leafTypes = leafTypes.concat(getLeafTypes(type.children))
            }
        })

        return leafTypes
    }

    return getLeafTypes(catalog)
}

export function useGetEquipmentTypeCatalogUtils() {
    const { data: equipmentTypes } = useEquipmentTypes()

    const getEquipmentTypeDepth = useCallback((code) => {
        if (!equipmentTypes) {
            return -1
        }

        return findCodeIndex(code, equipmentTypes, 0)
    }, [equipmentTypes])

    const getEquipmentTypeChildren = useCallback((code) => {
        if (!equipmentTypes) {
            return []
        }

        const equipmentType = findEquipmentTypeByCode(code, equipmentTypes)
        return equipmentType ? equipmentType.children : []
    }, [equipmentTypes])

    return {
        getEquipmentTypeDepth,
        getEquipmentTypeChildren,
    }
}