import { I18N_NAMESPACE } from "../../../common/constants/i18n"

export const EQUIPMENT_CONDITION_GOOD = 10
export const EQUIPMENT_CONDITION_MEDIUM = 20
export const EQUIPMENT_CONDITION_BAD = 30
export const EQUIPMENT_CONDITION_UNKNOWN = -1
export const EQUIPMENT_CONDITIONS = [
    EQUIPMENT_CONDITION_GOOD,
    EQUIPMENT_CONDITION_MEDIUM,
    EQUIPMENT_CONDITION_BAD,
    EQUIPMENT_CONDITION_UNKNOWN,
]

export const EQUIPMENT_CONDITIONS_VALUES = {
    [EQUIPMENT_CONDITION_BAD]: {
        backgroundColor: "colors/rouge/800",
        color: "error/main",
        label: "components.equipmentConditionCount.bad",
    },
    [EQUIPMENT_CONDITION_MEDIUM]: {
        backgroundColor: "colors/jaune/800",
        color: "DPE/orange",
        label: "components.equipmentConditionCount.medium",
    },
    [EQUIPMENT_CONDITION_GOOD]: {
        backgroundColor: "colors/vert logiroad/200",
        color: "objects/object-primary",
        label: "components.equipmentConditionCount.good",
    },
    [EQUIPMENT_CONDITION_UNKNOWN]: {
        color: "colors/gris/500",
        label: "",
    },
}

export const getEquipmentConditionsValues = (t) => ({
    [EQUIPMENT_CONDITION_BAD]: {
        ...EQUIPMENT_CONDITIONS_VALUES[EQUIPMENT_CONDITION_BAD],
        label: t(I18N_NAMESPACE, EQUIPMENT_CONDITIONS_VALUES[EQUIPMENT_CONDITION_BAD].label),
    },
    [EQUIPMENT_CONDITION_MEDIUM]: {
        ...EQUIPMENT_CONDITIONS_VALUES[EQUIPMENT_CONDITION_MEDIUM],
        label: t(I18N_NAMESPACE, EQUIPMENT_CONDITIONS_VALUES[EQUIPMENT_CONDITION_MEDIUM].label),
    },
    [EQUIPMENT_CONDITION_GOOD]: {
        ...EQUIPMENT_CONDITIONS_VALUES[EQUIPMENT_CONDITION_GOOD],
        label: t(I18N_NAMESPACE, EQUIPMENT_CONDITIONS_VALUES[EQUIPMENT_CONDITION_GOOD].label),
    },
    [EQUIPMENT_CONDITION_UNKNOWN]: {
        color: "colors/gris/500",
        label: "",
    },
})


export const conditions = {
    [EQUIPMENT_CONDITION_GOOD]: {
        backgroundColor: "colors/vert logiroad/800",
        text: "containers.equipment.legend.conditions.good",
    },
    [EQUIPMENT_CONDITION_MEDIUM]: {
        backgroundColor: "DPE/orange",
        text: "containers.equipment.legend.conditions.medium",
    },
    [EQUIPMENT_CONDITION_BAD]: {
        backgroundColor: "red/700",
        text: "containers.equipment.legend.conditions.bad",
    },
}

export const getEquipmentLegendConfig = (t, theme) => {
    return Object.values(conditions).map(conditionValue => {
        return {
            color: theme.palette[conditionValue.backgroundColor]?.main,
            label: t(I18N_NAMESPACE, conditionValue.text),
        }
    })
}