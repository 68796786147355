import { useCallback, useMemo } from "react"

import * as turf from "@turf/turf"

import { CatalogItemDetailsForm } from "@l2r-front/l2r-common"
import { sanitizeData } from "@l2r-front/l2r-geodata"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { useNetworkReferential } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { VERTICAL_SIGNING_CONDITION_GOOD, VERTICAL_SIGNING_CONDITIONS, VERTICAL_SIGNING_CONDITIONS_VALUES } from "../../constants/verticalSigningConditions"
import { useVerticalSignCreate } from "../../hooks/mutations/useVerticalSignCreate"
import { useVerticalSignPoleCreate } from "../../hooks/mutations/useVerticalSignPoleCreate"
import { useVerticalSigningProject } from "../../hooks/queries/verticalSigning/useVerticalSigningProject"
import { useVerticalSignTypes } from "../../hooks/queries/verticalSigning/useVerticalSignTypes"

import * as Styled from "./VerticalSignPoleAddForm.styled"

export const VerticalSignPoleAddFormPost = (props) => {

    const {
        className,
        close,
        startingPosition,
        ...otherProps
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const navigate = useNavigateWithSearchParams()

    const { data: project } = useVerticalSigningProject()
    const { data: verticalSignTypes, isLoading: isLoadingTypes, isError: isErrorTypes } = useVerticalSignTypes()

    const existingTags = useMemo(() => {
        if (project?.tags) {
            return project.tags.sort()
        }

        return []
    }, [project])

    const conditions = useMemo(() => {
        return VERTICAL_SIGNING_CONDITIONS.filter(condition => condition > 0)
            .map(condition => ({
                value: condition,
                label: t(I18N_NAMESPACE, VERTICAL_SIGNING_CONDITIONS_VALUES[condition].label),
            }))
    }, [t])

    const initialValues = useMemo(() => ({
        vertical_sign_type: undefined,
        condition: VERTICAL_SIGNING_CONDITION_GOOD,
        content: "",
        tags: [],
    }), [])

    const { data: referential, isLoading: isLoadingReferential, isError: isErrorReferential } = useNetworkReferential()
    const sanitizedReferential = useMemo(() => {
        return sanitizeData(referential)
    }, [referential])

    const poleCreationMutation = useVerticalSignPoleCreate({}, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.verticalSignPoleAddForm.snackbar.error"),
    })

    const signCreationMutation = useVerticalSignCreate({}, {
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.verticalSignPoleAddForm.snackbar.error"),
        successSnackbarMessage: t(I18N_NAMESPACE, "containers.verticalSignPoleAddForm.snackbar.success"),
    })

    const onSubmit = useCallback(async values => {
        const {
            position,
            road,
            ...signParams
        } = values

        const linearLocation = {
            typeid: 0,
            road: road.road,
            start: road.start,
            end: road.start + 1,
        }
        const point = turf.point([position.longitude, position.latitude])

        const signPole = await poleCreationMutation.mutateAsync({ project: project.uuid, linear_location: linearLocation, geometry: point.geometry })
        if (signPole) {
            const sign = await signCreationMutation.mutateAsync({ vertical_sign_pole: signPole.uuid, ...signParams })

            if (sign) {
                navigate(`../${signPole.linear_location.road}/${signPole.uuid}`)
            }
        }

        close(false)
    }, [close, poleCreationMutation, signCreationMutation, project, navigate])

    if (isLoadingTypes || isLoadingReferential) {
        return <Styled.FormSkeleton className={className} />
    }

    if (isErrorTypes || isErrorReferential) {
        return <Styled.ErrorMessage>{t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.error")}</Styled.ErrorMessage>
    }

    return <CatalogItemDetailsForm
        {...otherProps}
        initialValues={initialValues}
        catalogTypes={verticalSignTypes}
        typeField="vertical_sign_type"
        typeFieldLabel={t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.type") + "*"}
        conditions={conditions}
        existingTags={existingTags}
        referential={sanitizedReferential}
        startingPosition={startingPosition}
        onSubmit={onSubmit}
        onCancel={close}
    />
}

VerticalSignPoleAddFormPost.propTypes = {
    close: PropTypes.func.isRequired,
    startingPosition: PropTypes.shape({
        longitude: PropTypes.number,
        latitude: PropTypes.number,
    }).isRequired,
    startingRoad: PropTypes.string,
}