import { getPointSymbolLayerConfig } from "@l2r-front/l2r-map"
import { getColorWithOpacity } from "@l2r-front/l2r-utils"

import {
    CATALOG_ITEMS_CONDITION_BAD,
    CATALOG_ITEMS_CONDITION_GOOD,
    CATALOG_ITEMS_CONDITION_MEDIUM,
    CATALOG_ITEMS_CONDITIONS,
    CATALOG_ITEMS_CONDITIONS_VALUES,
} from "../constants/catalogItemConditions"
import { DEFAULT_SIGN_CODE } from "../constants/defaultIcon"

export const ENABLED_STATE = "1"
export const DISABLED_STATE = "0"

const NOT_SELECTED_SCALE = .8

function getStyleFromSignCondition(theme, signId, condition) {
    return [
        ["all", ["==", ["get", "uuid", ["properties"]], signId], ["==", ["get", "globalCondition", ["properties"]], condition]],
        theme.palette[CATALOG_ITEMS_CONDITIONS_VALUES[condition].color].main,
        ["all", ["!=", ["get", "uuid", ["properties"]], signId], ["==", ["get", "globalCondition", ["properties"]], condition]],
        getColorWithOpacity(theme.palette[CATALOG_ITEMS_CONDITIONS_VALUES[condition].color].main, .3),
    ]
}

function getStyleFromRoadCondition(theme, road, condition) {
    return [
        "case",
        ["all", ["==", ["get", "road", ["get", "linearLocation"]], road], ["==", ["get", "globalCondition", ["properties"]], condition]],
        theme.palette[CATALOG_ITEMS_CONDITIONS_VALUES[condition].color].main,
        ["all", ["!=", ["get", "road", ["get", "linearLocation"]], road], ["==", ["get", "globalCondition", ["properties"]], condition]],
        getColorWithOpacity(theme.palette[CATALOG_ITEMS_CONDITIONS_VALUES[condition].color].main, .3),

    ]
}

export function getCircleColor(theme, road, signId) {
    if (signId) {
        return [
            "case",
            ...CATALOG_ITEMS_CONDITIONS.map(condition => {
                return getStyleFromSignCondition(theme, signId, condition)
            }),
            ["==", ["get", "uuid", ["properties"]], signId],
            theme.palette["colors/gris/500"].main,
            ["!=", ["get", "uuid", ["properties"]], signId],
            getColorWithOpacity(theme.palette["colors/gris/500"].main, .3),
            theme.palette["colors/gris/500"].main,
        ]
    } else if (road) {
        return [
            "case",
            ...CATALOG_ITEMS_CONDITIONS.map(condition => {
                return getStyleFromRoadCondition(theme, road, condition)
            }),
            ["==", ["get", "road", ["get", "linearLocation"]], road],
            theme.palette["colors/gris/500"].main,
            ["!=", ["get", "road", ["get", "linearLocation"]], road],
            getColorWithOpacity(theme.palette["colors/gris/500"].main, .3),
            theme.palette["colors/gris/500"].main,
        ]
    }

    return [
        "case",
        ["==", ["get", "globalCondition", ["properties"]], CATALOG_ITEMS_CONDITION_BAD],
        theme.palette[CATALOG_ITEMS_CONDITIONS_VALUES[CATALOG_ITEMS_CONDITION_BAD].color].main,
        ["==", ["get", "globalCondition", ["properties"]], CATALOG_ITEMS_CONDITION_MEDIUM],
        theme.palette[CATALOG_ITEMS_CONDITIONS_VALUES[CATALOG_ITEMS_CONDITION_MEDIUM].color].main,
        ["==", ["get", "globalCondition", ["properties"]], CATALOG_ITEMS_CONDITION_GOOD],
        theme.palette[CATALOG_ITEMS_CONDITIONS_VALUES[CATALOG_ITEMS_CONDITION_GOOD].color].main,
        theme.palette["colors/gris/500"].main,
    ]
}

export function getCatalogItemLayerStyle(signId, road) {
    return getPointSymbolLayerConfig({
        layout: {
            "symbol-sort-key": signId ? ["case",
                ["!=", ["typeof", signId], "string"],
                1,
                ["==", ["get", "uuid", ["properties"]], signId],
                10,
                1,
            ] : road ? ["case",
                ["!=", ["typeof", road], "string"],
                1,
                ["==", ["get", "road", ["get", "linearLocation"]], road],
                10,
                1,
            ] : 1,
            "icon-size": signId ? ["case",
                ["!=", ["typeof", signId], "string"],
                1,
                ["==", ["get", "uuid", ["properties"]], signId],
                1,
                NOT_SELECTED_SCALE,
            ] : road ? ["case",
                ["!=", ["typeof", road], "string"],
                1,
                ["==", ["get", "road", ["get", "linearLocation"]], road],
                1,
                NOT_SELECTED_SCALE,
            ] : 1,
            "icon-image":
                ["coalesce",
                    ["concat",
                        ["get", "globalIcon", ["properties"]],
                        "#",
                        ["get", "globalCondition", ["properties"]],
                        "#",
                        signId ? ["case",
                            ["!=", ["typeof", signId], "string"],
                            1,
                            ["==", ["get", "uuid", ["properties"]], signId],
                            1,
                            NOT_SELECTED_SCALE,
                        ] : !road ? ENABLED_STATE : ["case",
                            ["!=", ["typeof", road], "string"],
                            ENABLED_STATE,
                            ["==", ["get", "road", ["get", "linearLocation"]], road],
                            ENABLED_STATE,
                            DISABLED_STATE,
                        ],
                    ],
                    ["concat",
                        DEFAULT_SIGN_CODE,
                        "#",
                        ["get", "globalCondition", ["properties"]],
                        "#",
                        signId ? ["case",
                            ["!=", ["typeof", signId], "string"],
                            ENABLED_STATE,
                            ["==", ["get", "uuid", ["properties"]], signId],
                            ENABLED_STATE,
                            DISABLED_STATE]
                            : !road ? ENABLED_STATE : ["case",
                                ["!=", ["typeof", road], "string"],
                                ENABLED_STATE,
                                ["==", ["get", "road", ["get", "linearLocation"]], road],
                                ENABLED_STATE,
                                DISABLED_STATE,
                            ],
                    ],
                ],
        },
    })
}