import { equipmentQueryKeys } from "./queryKeys"
import { useEquipmentQuery } from "./useEquipmentQuery"


export function useEquipmentsCategoriesStats(parameters = {}, config = {}) {
    const url = "equipments/stats/equipment_types/"

    const queryKey = equipmentQueryKeys["stats_categories"]({ parameters })

    return useEquipmentQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
        },
    )
}