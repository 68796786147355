import { keepPreviousData } from "@tanstack/react-query"

import { equipmentQueryKeys } from "./queryKeys"
import { useEquipmentQuery } from "./useEquipmentQuery"


export function useEquipmentGrades(config = {}, parameters = {}) {
    const url = "equipments/stats/conditions/"

    const queryKey = equipmentQueryKeys["stats_conditions"]({ parameters })

    return useEquipmentQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
            placeholderData: keepPreviousData,
        },
    )
}
