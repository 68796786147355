import { Typography } from "@mui/material"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { AddIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../constants/i18n"

import { CatalogListItem } from "../CatalogListItem"

import * as Styled from "./CatalogList.styled"

export function CatalogList(props) {

    const {
        alwaysDisplayIconsWhenAvailable,
        ascendantCodes,
        baseItemCode,
        defaultIcon,
        className,
        data,
        emptyListText,
        filter,
        ItemsCountComponent,
        onCreate,
        onClick,
        title,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)

    return <div className={className}>
        <Styled.Header>
            <Typography variant="H2">
                {title}
            </Typography>
            <Styled.ButtonsContainer>
                <ItemsCountComponent signType={baseItemCode} isMainCount />
                {onCreate && <Styled.CreateButton onClick={onCreate}><AddIcon /></Styled.CreateButton>}
            </Styled.ButtonsContainer>
        </Styled.Header>
        {data?.length ? <Styled.List>
            {
                data.map((dataItem) => {
                    const children = dataItem.children.length ? dataItem.children.map(child => {
                        return <CatalogListItem
                            disabled={child.disabled}
                            icon={getIcon(child, defaultIcon, alwaysDisplayIconsWhenAvailable)}
                            key={child.code}
                            defaultIcon={defaultIcon}
                            expandable={false}
                            highlighted={filter !== child.code && (ascendantCodes || []).includes(child.code)}
                            label={child.name}
                            onClick={() => onClick(child.code)}
                            selected={filter === child.code}
                            ItemsCountComponent={<ItemsCountComponent signType={child.code} />}
                            tooltipText={child.tooltipText}
                        />
                    })
                        : null

                    return <CatalogListItem
                        disabled={dataItem.disabled}
                        icon={getIcon(dataItem, defaultIcon, alwaysDisplayIconsWhenAvailable)}
                        defaultIcon={defaultIcon}
                        key={dataItem.code}
                        expandable={!!children}
                        children={children}
                        highlighted={filter !== dataItem.code && (ascendantCodes || []).includes(dataItem.code)}
                        label={dataItem.name}
                        onClick={() => onClick(dataItem.code)}
                        selected={filter === dataItem.code}
                        startOpen={children && (ascendantCodes || []).includes(dataItem.code)}
                        ItemsCountComponent={<ItemsCountComponent
                            displayTotalCount={!!dataItem.children.length}
                            signType={dataItem.code}
                        />}
                        tooltipText={dataItem.tooltipText}
                    />
                })
            }
        </Styled.List>
            : <Typography>
                {emptyListText ? emptyListText : t(I18N_NAMESPACE, "components.catalogList.emptyList")}
            </Typography>}
    </div>
}

function getIcon(item, defaultIcon, alwaysDisplayIconsWhenAvailable) {
    const shouldHideIcon = alwaysDisplayIconsWhenAvailable ? !item.icon : item.children.length
    if (shouldHideIcon) {
        return null
    }
    return item?.icon ?? defaultIcon
}

CatalogList.propTypes = {
    alwaysDisplayIconsWhenAvailable: PropTypes.bool,
    ascendantCodes: PropTypes.arrayOf(PropTypes.string),
    baseItemCode: PropTypes.string,
    className: PropTypes.string,
    data: PropTypes.any.isRequired,
    defaultIcon: PropTypes.string,
    emptyListText: PropTypes.string,
    filter: PropTypes.oneOfType([PropTypes.string, () => null]),
    ItemsCountComponent: PropTypes.func,
    onClick: PropTypes.func.isRequired,
    onCreate: PropTypes.func,
    title: PropTypes.string.isRequired,
}

CatalogList.defaultProps = {
    alwaysDisplayIconsWhenAvailable: false,
}