export const CATALOG_ITEMS_CONDITION_GOOD = 10
export const CATALOG_ITEMS_CONDITION_MEDIUM = 20
export const CATALOG_ITEMS_CONDITION_BAD = 30
export const CATALOG_ITEMS_CONDITION_UNKNOWN = -1
export const CATALOG_ITEMS_CONDITIONS = [
    CATALOG_ITEMS_CONDITION_GOOD,
    CATALOG_ITEMS_CONDITION_MEDIUM,
    CATALOG_ITEMS_CONDITION_BAD,
    CATALOG_ITEMS_CONDITION_UNKNOWN,
]

export const CATALOG_ITEMS_CONDITIONS_VALUES = {
    [CATALOG_ITEMS_CONDITION_BAD]: {
        color: "error/main",
    },
    [CATALOG_ITEMS_CONDITION_MEDIUM]: {
        color: "DPE/orange",
    },
    [CATALOG_ITEMS_CONDITION_GOOD]: {
        color: "objects/object-primary",
    },
    [CATALOG_ITEMS_CONDITION_UNKNOWN]: {
        color: "colors/gris/500",
    },
}
