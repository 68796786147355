import { useCallback, useMemo, useState } from "react"

import { Collapse, IconButton, Typography } from "@mui/material"

import { HelpOutlineRoundedIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./CatalogListItem.styled"

export function CatalogListItem(props) {

    const {
        children,
        defaultIcon,
        disabled,
        expandable,
        highlighted,
        icon,
        label,
        onClick,
        selected,
        tooltipText,
        ItemsCountComponent,
    } = props

    const [openInternal, setOpenInternal] = useState(false)

    const isOpen = useMemo(() => {
        if (disabled) {
            return openInternal
        } else {
            return highlighted || selected
        }
    }, [disabled, highlighted, openInternal, selected])

    const displayChildren = !!(expandable && isOpen && children)
    const displayArrowIcon = expandable

    const onButtonClick = useCallback(() => {
        if (disabled) {
            setOpenInternal(prevState => !prevState)
        } else if (onClick) {
            onClick()
        }
    }, [disabled, onClick])

    const arrowIcon = useMemo(() => {
        return isOpen ? <Styled.ArrowUpIcon color="objects/object-placeholder" />
            : <Styled.ArrowDownIcon color="objects/object-placeholder" />
    }, [isOpen])

    return <>
        <Styled.ListItemButton key={Math.random()}
            disableRipple={disabled}
            isDisabled={disabled}
            highlighted={highlighted}
            onClick={onButtonClick}
            selected={selected}
        >
            <Styled.SideContainer>
                {icon && <Styled.Image
                    disabled={disabled}
                    src={icon}
                    onError={e => e.target.src = defaultIcon}
                />}
                <Typography variant={(expandable && !disabled || selected || highlighted) ? "H3" : "Regular"}>{label}</Typography>
            </Styled.SideContainer>
            <Styled.SideContainer>
                {tooltipText && <Styled.Tooltip displayArrowIcon={displayArrowIcon} disableFocusListener title={tooltipText}>
                    <IconButton>
                        <HelpOutlineRoundedIcon color="action/disabled" />
                    </IconButton>
                </Styled.Tooltip>}
                {disabled && !expandable && <Styled.ArrowText>-</Styled.ArrowText>}
                {!disabled && ItemsCountComponent}
                {displayArrowIcon && arrowIcon}
            </Styled.SideContainer>
        </Styled.ListItemButton>
        <Collapse in={displayChildren}>
            <Styled.List disablePadding>
                {children}
            </Styled.List>
        </Collapse>
    </>
}

CatalogListItem.propTypes = {
    children: PropTypes.node,
    code: PropTypes.string,
    defaultIcon: PropTypes.string,
    disabled: PropTypes.bool,
    expandable: PropTypes.bool,
    highlighted: PropTypes.bool,
    icon: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    startOpen: PropTypes.bool,
    tooltipText: PropTypes.string,
    ItemsCountComponent: PropTypes.node,
}