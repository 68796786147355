import { useCallback, useMemo } from "react"

import * as turf from "@turf/turf"

import { CatalogItemDetailsForm } from "@l2r-front/l2r-common"
import { sanitizeData } from "@l2r-front/l2r-geodata"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { useNetworkReferential } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useNavigateWithSearchParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { HORIZONTAL_SIGNING_CONDITION_GOOD, HORIZONTAL_SIGNING_CONDITIONS, HORIZONTAL_SIGNING_CONDITIONS_VALUES } from "../../constants/horizontalSigningConditions"
import { useHorizontalSignCreate } from "../../hooks/mutations/useHorizontalSignCreate"
import { useHorizontalSigningProject } from "../../hooks/queries/horizontalSigning/useHorizontalSigningProject"
import { useHorizontalSignTypes } from "../../hooks/queries/horizontalSigning/useHorizontalSignTypes"

import * as Styled from "./HorizontalSignAddForm.styled"

export const HorizontalSignAddFormPost = (props) => {

    const {
        className,
        close,
        startingPosition,
        ...otherProps
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const navigate = useNavigateWithSearchParams()

    const { data: project } = useHorizontalSigningProject()
    const { data: horizontalSignTypes, isLoading: isLoadingTypes, isError: isErrorTypes } = useHorizontalSignTypes()

    const existingTags = useMemo(() => {
        if (project?.tags) {
            return project.tags.sort()
        }

        return []
    }, [project])

    const conditions = useMemo(() => {
        return HORIZONTAL_SIGNING_CONDITIONS.filter(condition => condition > 0)
            .map(condition => ({
                value: condition,
                label: t(I18N_NAMESPACE, HORIZONTAL_SIGNING_CONDITIONS_VALUES[condition].label),
            }))
    }, [t])

    const initialValues = useMemo(() => ({
        horizontal_sign_type: undefined,
        condition: HORIZONTAL_SIGNING_CONDITION_GOOD,
        content: "",
        tags: [],
    }), [])

    const { data: referential, isLoading: isLoadingReferential, isError: isErrorReferential } = useNetworkReferential()
    const sanitizedReferential = useMemo(() => {
        return sanitizeData(referential)
    }, [referential])

    const mutation = useHorizontalSignCreate({}, {
        onSuccess: (data) => navigate(`../${data.linear_location.road}/${data.uuid}`),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.horizontalSignAddForm.snackbar.error"),
        successSnackbarMessage: t(I18N_NAMESPACE, "containers.horizontalSignAddForm.snackbar.success"),
    })

    const onSubmit = useCallback(async values => {
        const {
            position,
            road,
            ...signParams
        } = values

        const linearLocation = {
            typeid: 0,
            road: road.road,
            start: road.start,
            end: road.start + 1,
        }
        const point = turf.point([position.longitude, position.latitude])

        const params = {
            ...signParams,
            geometry: point.geometry,
            linear_location: linearLocation,
            project: project.uuid,
        }

        mutation.mutate(params)

        close(false)
    }, [close, mutation, project])

    if (isLoadingTypes || isLoadingReferential) {
        return <Styled.FormSkeleton className={className} />
    }

    if (isErrorTypes || isErrorReferential) {
        return <Styled.ErrorMessage>{t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.error")}</Styled.ErrorMessage>
    }

    return <CatalogItemDetailsForm
        {...otherProps}
        initialValues={initialValues}
        catalogTypes={horizontalSignTypes}
        typeField="horizontal_sign_type"
        typeFieldLabel={t(I18N_NAMESPACE, "containers.horizontalSignDetailsForm.type") + "*"}
        conditions={conditions}
        existingTags={existingTags}
        referential={sanitizedReferential}
        startingPosition={startingPosition}
        onSubmit={onSubmit}
        onCancel={close}
    />
}

HorizontalSignAddFormPost.propTypes = {
    close: PropTypes.func.isRequired,
    startingPosition: PropTypes.shape({
        longitude: PropTypes.number,
        latitude: PropTypes.number,
    }).isRequired,
    startingRoad: PropTypes.string,
}