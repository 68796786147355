import { useTranslation } from "@l2r-front/l2r-i18n"
import { useTheme } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../common/constants/i18n"

import { getEquipmentLegendConfig } from "../constants/equipmentConditions"

export function useEquipmentLegend() {

    const { t } = useTranslation(I18N_NAMESPACE)
    const theme = useTheme()

    return [
        {
            layerName: "equipment",
            legendData: getEquipmentLegendConfig(t, theme),
            title: t(I18N_NAMESPACE, "containers.equipment.legend.title"),
        },
    ]
}