import { useCallback } from "react"

import { DEFAULT_SIGN_ICON } from "@l2r-front/l2r-common"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { useRoleIsReadOnly } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import {
    CatalogList,
    CatalogListError,
    CatalogListSkeleton,
} from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useHorizontalSigningContext } from "../../contexts/HorizontalSigningContext"
import { useHorizontalSignType, useHorizontalSignTypes } from "../../hooks/queries/horizontalSigning/useHorizontalSignTypes"

import { HorizontalSignsCount } from "../HorizontalSignsCount"

export function HorizontalSignTypeList(props) {

    const {
        baseHorizontalSignTypeCode,
        className,
        onChangeFilter,
        onCreate,
    } = props

    const { t } = useTranslation()
    const { data } = useHorizontalSignType(baseHorizontalSignTypeCode)
    const [state, dispatch] = useHorizontalSigningContext()
    const { ascendantCodes, filters } = state
    const readOnly = useRoleIsReadOnly()

    const {
        data: horizontalSignTypes,
        isError: isErrorHorizontalSignTypes,
        isLoading: isLoadingHorizontalSignTypes,
        isFetching: isFetchingHorizontalSignTypes,
    } = useHorizontalSignTypes(baseHorizontalSignTypeCode)

    const { setFilters } = dispatch

    const title = data?.name ?? t(I18N_NAMESPACE, "containers.horizontalSignTypeList.defaultTitle")

    const applyFilter = useCallback((newFilter) => {
        if (filters.type === newFilter || ascendantCodes.indexOf(newFilter) >= 0) {
            const filterIndex = ascendantCodes.indexOf(newFilter)
            if (filterIndex !== ascendantCodes.length - 1) {
                setFilters({ type: ascendantCodes[filterIndex + 1] })
            } else {
                setFilters({ type: null })
            }
        } else {
            setFilters({ type: newFilter })
            onChangeFilter?.({ type: newFilter })
        }
    }, [filters, ascendantCodes, setFilters, onChangeFilter])

    if (isErrorHorizontalSignTypes && !isFetchingHorizontalSignTypes) {
        return <CatalogListError className={className} errorText={t(I18N_NAMESPACE, "components.horizontalSignTypeList.error")} />
    }

    if (isLoadingHorizontalSignTypes) {
        return <CatalogListSkeleton className={className} />
    }

    return <CatalogList
        onClick={applyFilter}
        baseItemCode={baseHorizontalSignTypeCode}
        ascendantCodes={ascendantCodes}
        className={className}
        data={horizontalSignTypes}
        defaultIcon={DEFAULT_SIGN_ICON}
        emptyListText={t(I18N_NAMESPACE, "components.horizontalSignTypeList.emptyList")}
        filter={filters?.type}
        title={title}
        ItemsCountComponent={HorizontalSignsCount}
        onCreate={!readOnly ? onCreate : null}
    />
}

HorizontalSignTypeList.propTypes = {
    baseHorizontalSignTypeCode: PropTypes.string,
    className: PropTypes.string,
    onChangeFilter: PropTypes.func,
    onCreate: PropTypes.func,
}