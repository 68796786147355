import { useMemo } from "react"

import { useParams } from "react-router-dom"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { CatalogItemsCount, CatalogItemsCountSkeleton } from "@l2r-front/l2r-ui"

import { useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext"
import { useVerticalSigningCategoriesStats } from "../../hooks/queries/verticalSigning/useVerticalSigningCategoriesStats"
import { useVerticalSignTypes } from "../../hooks/queries/verticalSigning/useVerticalSignTypes"

export function VerticalSignsCount(props) {

    const {
        className,
        displayTotalCount,
        isMainCount,
        signType,
    } = props

    const { road } = useParams()
    const { filters } = useVerticalSigningStateContext()
    const apiFilters = {
        ...(filters?.conditions?.length && { condition: filters.conditions }),
        ...(filters?.tags?.length && { tags: filters.tags.join(",") }),
        ...(road && { vertical_sign_pole__linear_location_road: road }),
    }

    const {
        data: dataWithFilters,
        isError: dataWithFiltersError,
        isLoading: dataWithFiltersLoading,
    } = useVerticalSigningCategoriesStats({ ...apiFilters })

    const {
        data: dataWithoutConditionFilters,
        isError: dataWithoutConditionFiltersError,
        isLoading: dataWithoutConditionFiltersLoading,
    } = useVerticalSigningCategoriesStats({ ...(road && { vertical_sign_pole__linear_location_road: road }) })

    const {
        data: verticalSignTypes,
        isError: isErrorVerticalSignTypes,
        isLoading: isLoadingVerticalSignTypes,
    } = useVerticalSignTypes()

    const calculatedCount = useMemo(() => {
        if (signType) {
            return dataWithFilters?.count[signType] || 0
        } else if (dataWithFilters && verticalSignTypes) {
            return verticalSignTypes.reduce((acc, verticalSignType) => {
                return acc + dataWithFilters.count[verticalSignType.code]
            }, 0)
        } else {
            return 0
        }
    }, [signType, dataWithFilters, verticalSignTypes])

    if (dataWithFiltersError || dataWithoutConditionFiltersError || isErrorVerticalSignTypes) {
        return null
    }

    if (dataWithFiltersLoading || dataWithoutConditionFiltersLoading || isLoadingVerticalSignTypes) {
        return <CatalogItemsCountSkeleton className={className} isMainCount={isMainCount} />
    }

    return <CatalogItemsCount
        className={className}
        filteredCount={calculatedCount}
        isMainCount={isMainCount}
        totalCount={displayTotalCount ? dataWithoutConditionFilters?.count?.[signType] : undefined}
    />
}

VerticalSignsCount.propTypes = {
    className: PropTypes.string,
    displayTotalCount: PropTypes.bool,
    isMainCount: PropTypes.bool,
    signType: PropTypes.string,
}