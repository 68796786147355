export const MAX_ZOOM_LEVEL_CLUSTERED = 13

export const PADDING_BOUNDING_BOX = 80

export const FLY_TO_ZOOM = 17

export const FLY_TO_SPEED = 3

export const FLY_TO_CURVE = 1

export const FIT_TO_BOUNDING_BOX_DURATION = 2000
export const MAP_INDICATOR_PADDING = 5

export const BASE_MAP_ID = "baseMap"
export const STABILO_MAP_ID = "stabiloMap"

export const MAP_TYPE_ROAD = "road"
export const MAP_TYPE_NETWORK = "network"
export const MAP_TYPE_ALL = "all"

export const MAP_ACTION_CHANGE_STYLE = "CHANGE_STYLE"
