import { useCallback, useMemo, useState } from "react"

import { Modal } from "@mui/material"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { ArrowBackIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useAlertsDispatchContext } from "@l2r-front/l2r-query"
import { I18N_NAMESPACE, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { useCatalogItemEditStateContext } from "./CatalogItemEditContext.hooks"
import { CatalogItemEditContextProvider } from "./CatalogItemEditContext.provider"
import * as Styled from "./CatalogItemEditModal.styled"

export const CatalogItemEditModal = (props) => {

    const {
        className,
        formTitle,
        typeTitle,
        FormComponent,
        open,
        onClose,
        ...otherProps
    } = props

    const [hasOngoingModifications, setHasOngoingModifications] = useState(false)

    const { setAlert } = useAlertsDispatchContext()
    const { t } = useTranslation(I18N_NAMESPACE)

    const handleClose = useCallback((checkModifications = true) => {
        if (checkModifications && hasOngoingModifications) {
            setAlert({
                alertTitle: t(I18N_NAMESPACE, "containers.catalogItemEditModal.alert.title"),
                cancelText: t(I18N_NAMESPACE, "containers.catalogItemEditModal.alert.cancelButton"),
                confirmText: t(I18N_NAMESPACE, "containers.catalogItemEditModal.alert.confirmButton"),
                callback: () => {
                    setHasOngoingModifications(false)
                    onClose()
                },
                severity: "warning",
            })
        } else {
            setHasOngoingModifications(false)
            onClose()
        }
    }, [hasOngoingModifications, onClose, setAlert, t])

    return <Modal
        id="catalogItem-edit-modal"
        className={className}
        open={open}
        onClose={handleClose}
    >
        <div>
            <CatalogItemEditContextProvider>
                <CatalogItemEditModalContent
                    formTitle={formTitle}
                    typeTitle={typeTitle}
                    FormComponent={FormComponent}
                    onClose={handleClose}
                    onChange={setHasOngoingModifications}
                    {...otherProps} />
            </CatalogItemEditContextProvider>
        </div>
    </Modal>
}

CatalogItemEditModal.propTypes = {
    className: PropTypes.string,
    formTitle: PropTypes.string.isRequired,
    typeTitle: PropTypes.string,
    FormComponent: PropTypes.elementType.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

const CatalogItemEditModalContent = (props) => {

    const {
        formTitle,
        typeTitle,
        FormComponent,
        onClose,
        ...formProps
    } = props

    const { typeSelectionDisplayed, onBack } = useCatalogItemEditStateContext()

    const isMobile = useIsMobileDevice()

    const BASIC_POPUP_HEIGHT = 575
    const FULL_POPUP_HEIGHT = 872
    const LOCATION_POPUP_HEIGHT = 666

    const popupHeight = useMemo(() => {
        const showLocation = !!formProps.startingPosition
        const showDetails = !!typeTitle

        if (showLocation && showDetails) {
            return FULL_POPUP_HEIGHT
        } else if (showDetails) {
            return BASIC_POPUP_HEIGHT
        } else {
            return LOCATION_POPUP_HEIGHT
        }
    }, [formProps.startingPosition, typeTitle])

    return <Styled.Box isMobile={isMobile} height={popupHeight}>
        <Styled.TitleContainer>
            {typeSelectionDisplayed && <Styled.BackButton color="objects/object-black" onClick={() => onBack?.()}>
                <ArrowBackIcon color="objects/object-black" />
            </Styled.BackButton>}
            <Styled.Title variant="H1">{!typeSelectionDisplayed ? formTitle : typeTitle}</Styled.Title>
            <Styled.IconButton id="close-button" onClick={onClose}>
                <Styled.CloseIcon />
            </Styled.IconButton>
        </Styled.TitleContainer>
        <FormComponent sx={{ flex: "1 1 auto" }} close={onClose} {...formProps} />
    </Styled.Box>
}

CatalogItemEditModalContent.propTypes = {
    formTitle: PropTypes.string.isRequired,
    typeTitle: PropTypes.string,
    FormComponent: PropTypes.elementType.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
}
