import { useNetworksStateContext } from "@l2r-front/l2r-networks"

import { equipmentQueryKeys } from "./queryKeys"
import { useEquipmentQuery } from "./useEquipmentQuery"

export function useEquipmentProject() {
    const { selectedNetwork } = useNetworksStateContext()
    const url = `projects/${selectedNetwork.slug}/`

    const queryKey = equipmentQueryKeys["project"]()

    return useEquipmentQuery(
        url,
        queryKey,
    )
}