import { useMemo } from "react"

import { useLocation, useMatch, useNavigate } from "react-router-dom"
import urlJoin from "url-join"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { I18N_NAMESPACE } from "../../constants/i18n"
import { useGetActiveModules } from "../../hooks/useGetActiveModules"

import * as Styled from "./MobileModulesSelector.styled"

export const MobileModulesSelector = (props) => {

    const {
        close,
    } = props

    const { t } = useTranslation()
    const { selectedNetwork } = useNetworksStateContext()
    const { storeMapBoundingBox } = useMapDispatchContext()
    const match = useMatch("/:selectedNetwork/:app/:module/*")
    const selectedModule = match?.params?.module
    const navigate = useNavigate()
    const location = useLocation()
    const appsWithActiveModules = useGetActiveModules()

    const modules = useMemo(() => {
        const sortedApps = Object.values(appsWithActiveModules).sort((a, b) => a.index - b.index)
        return sortedApps.map(app => {
            const sortedModules = Object.values(app.modules).sort((a, b) => a.index - b.index)
            const appModules = sortedModules.map(module => {
                const modulePath = urlJoin("/", app.path ?? "", module.path)
                const Icon = module.icon
                const selected = selectedModule === module.path
                return <Styled.Button
                    color="objects/object-black"
                    disabled={module.disabled}
                    key={`module-${module.id}`}
                    selected={selected}
                    onClick={() => {
                        const previousPath = match?.params?.["*"].length ? match?.params?.["*"].replace(/code\/.*/, "") : ""
                        storeMapBoundingBox()
                        navigate(`/${selectedNetwork.slug}${modulePath}/${previousPath}${location?.search}`)
                        close()
                    }}
                    id={`module-${module.id}`}>
                    {module.icon ? <Styled.IconWrapper>
                        <Icon color={app.color} />
                    </Styled.IconWrapper> : <Styled.ColorIcon color={app.color} />}
                    <Styled.OptionText>
                        {t(app.i18nNamespace, module.label)}
                    </Styled.OptionText>
                </Styled.Button>
            })

            return [
                app.label && <Styled.ListSubheader
                    key={app.label}
                    variant="Regular"
                >
                    {t(app.i18nNamespace, app.label)}
                </Styled.ListSubheader>,
                appModules,
            ]
        })
    }, [close,
        location?.search,
        match,
        selectedModule,
        storeMapBoundingBox,
        selectedNetwork,
        navigate,
        t,
        appsWithActiveModules])

    return <Styled.Wrapper id="modules-list-container">
        {modules?.length ? modules : <Styled.Typography>
            {t(I18N_NAMESPACE, "containers.moduleSelector.emptyList")}
        </Styled.Typography>}
    </Styled.Wrapper>
}

MobileModulesSelector.propTypes = {
    close: PropTypes.func.isRequired,
}
