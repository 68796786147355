import { useAuthenticatedMutation } from "@l2r-front/l2r-auth"

export function useEquipmentMutation(
    url,
    axiosFn,
    queryParameters,
    config = {},
) {
    const apiUrl = `equipment/${url}`

    return useAuthenticatedMutation(
        apiUrl,
        axiosFn,
        queryParameters,
        config,
    )
}