import { useMemo } from "react"

import { useMatch } from "react-router-dom"

import { MapLegend } from "@l2r-front/l2r-map"

import { useEquipmentLegend } from "../../../modules/equipment/hooks/useEquipmentLegend"
import { useVerticalSigningLegend } from "../../../modules/verticalSigning/hooks/useVerticalSigningLegend"
import { APP_CITY } from "../../constants/appCity"
import { MODULE_EQUIPMENT, MODULE_VERTICAL_SIGNING } from "../../constants/modules"


export function CityMapLegend() {

    const matchVerticalSigning = useMatch(`:slug/${APP_CITY}/${MODULE_VERTICAL_SIGNING}/*`)
    const matchEquipment = useMatch(`:slug/${APP_CITY}/${MODULE_EQUIPMENT}/*`)

    const verticalSigningLegend = useVerticalSigningLegend()
    const equipmentLegend = useEquipmentLegend()

    const displayedLegend = useMemo(() => {

        if (matchVerticalSigning) {
            return verticalSigningLegend
        }

        if (matchEquipment) {
            return equipmentLegend
        }

        return null

    }, [equipmentLegend, matchEquipment, matchVerticalSigning, verticalSigningLegend])

    if (displayedLegend) {
        return <MapLegend displayedLegends={displayedLegend} />
    }

    return []
}