import { queryKeyFactory } from "@l2r-front/l2r-query"

export const EQUIPMENT_ROOT_QUERY_KEY = "equipment"

export const equipmentQueryKeys = queryKeyFactory(EQUIPMENT_ROOT_QUERY_KEY, {
    equipmentType: (code) => [EQUIPMENT_ROOT_QUERY_KEY, "verticalSignType", code],
    feature: (featureId, params = {}) => [EQUIPMENT_ROOT_QUERY_KEY, "feature", featureId, params],
    layer: (filters = {}) => [EQUIPMENT_ROOT_QUERY_KEY, "layer", filters],
    project: (filters = {}) => [EQUIPMENT_ROOT_QUERY_KEY, "project", filters],
    stats_categories: (parameters = {}) => [EQUIPMENT_ROOT_QUERY_KEY, "stats", "categories", { parameters }],
    stats_conditions: (parameters = {}) => [EQUIPMENT_ROOT_QUERY_KEY, "stats", "conditions", { parameters }],
})
