import { useLocation, useMatch } from "react-router-dom"

import * as Styled from "./Kpi.styled"

export const KpiCard = (props) => {
    const {
        className,
        path,
        children,
        ...cardProps
    } = props

    const location = useLocation()
    const match = useMatch("/:selectedNetwork/:app/:module/*")
    const selectedNetwork = match?.params?.selectedNetwork

    const previousPath = match?.params?.["*"].length ? match?.params?.["*"] : ""
    const fullPath = `/${selectedNetwork}/${path}/${previousPath}${location?.search ? location.search : ""}`

    return (
        <Styled.KpiCard to={fullPath} className={className} {...cardProps}>
            {children}
        </Styled.KpiCard>
    )
}
