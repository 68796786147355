import { Skeleton, styled, Typography } from "@mui/material"

export const Wrapper = styled("div", {
    shouldForwardProp: prop => prop !== "isMainCount",
})(({ isMainCount, theme }) => ({
    alignItems: "center",
    borderRadius: 100,
    border: isMainCount && `1px solid ${theme.palette["colors/gris/1000"].main}`,
    boxSizing: "border-box",
    height: 32,
    minWidth: 32,
    padding: isMainCount && theme.spacing(1, 2),
    lineHeight: isMainCount ? "24px" : "32px",
    fontSize: 0,
    textAlign: "center",
    marginLeft: theme.spacing(3),
}))

export const Text = styled(Typography)(() => ({
    fontSize: 16,
}))

export const TextTotal = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(1),
}))

export const CatalogItemsCountSkeleton = styled(Skeleton, {
    shouldForwardProp: prop => prop !== "isMainCount",
})(({ isMainCount, theme }) => ({
    height: 32,
    padding: isMainCount && theme.spacing(2),
    transform: "scale(1)",
    width: 42,
}))