import { Route, Routes } from "react-router-dom"

import { EquipmentMapLayersRouter } from "../../../modules/equipment/routers/EquipmentMapLayersRouter"
import { VerticalSigningMapLayersRouter } from "../../../modules/verticalSigning/routers/VerticalSigningMapLayersRouter"
import { MODULE_EQUIPMENT, MODULE_VERTICAL_SIGNING } from "../../constants/modules"

export const CityDesktopMapRouter = () => {

    return (
        <Routes>
            <Route
                element={
                    <VerticalSigningMapLayersRouter />
                }
                path={`/${MODULE_VERTICAL_SIGNING}/*`}
            />
            <Route
                element={
                    <EquipmentMapLayersRouter />
                }
                path={`/${MODULE_EQUIPMENT}/*`}
            />
        </Routes>
    )
}