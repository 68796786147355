import { ReactMapGl } from "@l2r-front/l2r-map"
import { RoadSearch } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { CityMapLegend } from "../../../../common/containers/CityMapLegend"
import { Map } from "../../../../common/containers/Map"

import * as Styled from "./MobileEquipmentMap.styled"

export function MobileEquipmentMap(props) {
    const {
        children,
        ...mapProps
    } = props

    return (
        <Styled.Container>
            <Map
                {...mapProps}
                topLeftElements={null}
                topRightElements={[<RoadSearch key="road-search" disableExpend={false} />, <CityMapLegend key="city-map-legend" />]}
            >
                {children}
            </Map>
        </Styled.Container >
    )
}

MobileEquipmentMap.propTypes = {
    ...ReactMapGl.propTypes,
    className: PropTypes.string,
}