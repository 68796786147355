import { useMemo } from "react"

import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { formatMetersToDistance } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { RoadworksStats } from "../../components/RoadworksStats"
import { useRoadworksStateContext } from "../../contexts/RoadworksContext"
import { useRoadworksStatLengthUnique } from "../../hooks/queries/useRoadworksStatLengthUnique"

import * as Styled from "./RoadworkStat.styled"

export function RoadworkLinearLengthUnique(props) {
    const {
        title,
    } = props

    const { t } = useTranslation()
    const { apiFilters } = useRoadworksStateContext()
    const { road } = useParams()
    const { data: stats, isLoading, isError } = useRoadworksStatLengthUnique({ ...apiFilters, linear_location_road: road })

    const content = useMemo(() => {
        if (isError) {
            return (
                <Styled.Error>
                    {t(I18N_NAMESPACE, "containers.roadworkStats.roadworkLinearLengthUnique.error")}
                </Styled.Error>
            )
        }

        if (isLoading) {
            return <Styled.Skeleton />
        }

        return (
            <Styled.Stat id="roadworks-unique-length">
                {formatMetersToDistance(stats.lengthUnique)}
            </Styled.Stat>
        )
    }, [isLoading, isError, stats, t])

    return (
        <RoadworksStats title={title}>
            {content}
        </RoadworksStats>
    )
}

RoadworkLinearLengthUnique.propTypes = {
    title: PropTypes.string,
}