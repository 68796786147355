import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import {
    CatalogItemsGrading,
    CatalogItemsGradingError,
    CatalogItemsGradingSkeleton,
} from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { getEquipmentConditionsValues } from "../../constants/equipmentConditions"
import { useEquipmentDispatchContext, useEquipmentStateContext } from "../../contexts/EquipmentContext/EquipmentContext.hooks"
import { useEquipmentGrades } from "../../hooks/queries/equipment/useEquipmentGrades"

export function EquipmentsGrading(props) {
    const { road } = useParams()
    const { filters } = useEquipmentStateContext()
    const { setFilters } = useEquipmentDispatchContext()
    const { t } = useTranslation(I18N_NAMESPACE)

    const { data, isError, isLoading } = useEquipmentGrades({}, {
        ...(filters?.tags?.length && { tags: filters.tags.join(",") }),
        ...(road && { linear_location_road: road }),
    })

    if (isError) {
        return <CatalogItemsGradingError
            errorText={t(I18N_NAMESPACE, "components.equipmentsGrading.error")}
            {...props}
        />
    }

    if (!data && isLoading) {
        return <CatalogItemsGradingSkeleton {...props} />
    }

    return <CatalogItemsGrading
        activeConditionFilters={filters?.conditions}
        catalogItemsGradingSettings={getEquipmentConditionsValues(t)}
        count={data.count}
        onConditionFiltersChanged={(value) => setFilters({ condition: value })}
        {...props}
    />
}