import { useContext } from "react"

import { EquipmentDispatchContext, EquipmentStateContext } from "./EquipmentContext.context"

export const useEquipmentStateContext = () => {
    const context = useContext(EquipmentStateContext)
    if (context === undefined) {
        throw new Error("useEquipmentStateContext must be used within an EquipmentStateContext")
    }
    return context
}

export const useEquipmentDispatchContext = () => {
    const context = useContext(EquipmentDispatchContext)
    if (context === undefined) {
        throw new Error("useEquipmentDispatchContext must be used within an EquipmentDispatchContext")
    }
    return context
}

export const useEquipmentContext = () => {
    return [useEquipmentStateContext(), useEquipmentDispatchContext()]
}
