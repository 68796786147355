import { CloseIcon as Close } from "@l2r-front/l2r-icons"
import {
    BottomSheet as UIBottomSheet,
    Divider as UiDivider,
    IconButton,
    List as UiList,
    Popover,
    styled,
    Typography,
} from "@l2r-front/l2r-ui"

const BUTTON_SIZE = 44
const DESKTOP_CONTAINER_WIDTH = 280
const CLOSE_BUTTON_SIZE = 24

export const Container = styled(Popover)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: theme.spacing(2),
        boxShadow: theme.shadows[30],
        padding: theme.spacing(4, 6),
        width: DESKTOP_CONTAINER_WIDTH,
    },
}))

export const ContentWrapper = styled("div")(() => ({
    position: "relative",
}))

export const CloseButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette["objects/object-black"].main,
    padding: 0,
    position: "absolute",
    height: CLOSE_BUTTON_SIZE,
    right: 0,
    top: 0,
    width: CLOSE_BUTTON_SIZE,
}))

export const CloseIcon = styled(Close)({
    height: 16,
    width: 16,
})

export const List = styled(UiList)(() => ({
    paddingTop: 0,
    paddingBottom: 0,
}))

export const Title = styled(Typography, { shouldForwardProp: prop => !["isFirst"].includes(prop) })(({ isFirst, theme }) => ({
    display: "block",
    width: isFirst ? `calc(100% - ${CLOSE_BUTTON_SIZE}px - ${theme.spacing(3)})` : "100%",
}))

export const LayerList = styled("div")(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(5),
    marginTop: theme.spacing(4),
}))

export const Button = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    boxShadow: theme.shadows[3],
    width: BUTTON_SIZE,
    height: BUTTON_SIZE,

    "&:hover": {
        backgroundColor: theme.palette["objects/object-lightgrey"].main,
    },
}))

export const Divider = styled(UiDivider)(({ theme }) => ({
    margin: theme.spacing(2, 0),
}))

export const BottomSheet = styled(UIBottomSheet)(({ theme }) => ({
    zIndex: theme.zIndex.drawer + 10,
}))