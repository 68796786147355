import { Form as FormikForm } from "formik"

import {
    CTAButton as UiButton,
    HierarchicalList as UIHierarchicalList,
    FormikTextField,
    FormikSelect,
    Skeleton,
    TagsAutocomplete as L2RTagsAutocomplete,
    Typography,
    styled,
} from "@l2r-front/l2r-ui"

export const Form = styled(FormikForm)(() => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
}))

export const FormContent = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(4),
    flex: "1 1 0",
    overflowY: "auto",
    paddingTop: theme.spacing(2),
}))

export const Desc = styled(Typography)(() => ({
}))

export const MapContainer = styled("div", { shouldForwardProp: prop => prop !== "height" })(({ theme, height }) => ({
    borderRadius: theme.spacing(3),
    height: height,
    marginBottom: theme.spacing(8),
    flexShrink: 0,
}))

export const Select = styled(FormikSelect)(() => ({
    width: "100%",
    height: "auto",
}))

export const TextField = styled(FormikTextField)(() => ({
    width: "100%",
}))

export const ButtonsWrapper = styled("div")(({ theme, showShadow }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    margin: theme.spacing(0, -8, -8, -8),
    padding: theme.spacing(8),
    display: "flex",
    justifyContent: "right",
    gap: theme.spacing(5),
    boxShadow: showShadow ? theme.shadows[29] : "none",
    zIndex: 1,
}))

export const SubmitButton = styled(UiButton)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    textTransform: "capitalize",
}))

export const HierarchicalList = styled(UIHierarchicalList)({
    height: "100%",
    overflowY: "hidden",
})


export const FormSkeleton = styled(Skeleton)(() => ({
    transform: "none",
}))

export const ErrorMessage = styled(Typography)(({ theme }) => ({
    color: theme.palette["error/main"].main,
}))

export const TagsAutocomplete = styled(L2RTagsAutocomplete)(({ theme }) => ({
    marginBottom: theme.spacing(3),
}))