import { useCallback, useEffect, useMemo, useState } from "react"

import * as turf from "@turf/turf"
import ReactMapbox, { Marker } from "react-map-gl"

import { useGetLocaleFromList } from "@l2r-front/l2r-i18n"
import { PinIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useTheme } from "@l2r-front/l2r-ui"

import { MapLocales, setMapLanguage } from "../../components/Map"
import { ZoomButtons } from "../../components/ZoomButtons"
import { MapStyles } from "../../constants/mapStyles"

import * as Styled from "./MapPointEditor.styled"

export const MapPointEditor = (props) => {
    const {
        children,
        initialZoom,
        position,
        onMove,
        onRoadDistanceChanged,
        onZoom,
        referential,
        road,
    } = props

    const [mapRef, setMapRef] = useState(null)

    const theme = useTheme()

    const currentLang = useGetLocaleFromList(MapLocales, MapLocales[0])
    const mapInitialZoom = initialZoom || 19

    const mapInitialViewState = useMemo(() => {
        return {
            ...position,
            zoom: mapInitialZoom,
        }
    }, [position, mapInitialZoom])

    const handleMapRef = useCallback((ref) => {
        setMapRef(ref)

        return () => {
            setMapRef(null)
        }
    }, [setMapRef])

    const map = useMemo(() => {
        if (!mapRef) {
            return
        }

        return mapRef.getMap()
    }, [mapRef])

    const onStyleLoaded = (e) => {
        setMapLanguage(e.target, currentLang)
    }

    const handleMove = useCallback(e => {
        const { longitude, latitude } = e.viewState

        onMove({
            longitude,
            latitude,
        })
    }, [onMove])

    const handleZoom = useCallback(e => {
        onZoom?.(e.viewState.zoom)
    }, [onZoom])

    useEffect(() => {
        if (!map) {
            return
        }
        const loaded = map.loaded()
        if (!loaded) {
            return
        }
        setMapLanguage(map, currentLang)
    }, [currentLang, map])

    useEffect(() => {
        if (!position || !referential || !onRoadDistanceChanged) {
            return
        }

        const point = turf.point([position.longitude, position.latitude])
        const nearestRoadLinear = referential ? turf.featureReduce(referential,
            (previousValue, currentFeature) => {
                const distance = turf.pointToLineDistance(point, currentFeature.geometry)
                if (distance < previousValue.distance) {
                    return {
                        distance,
                        linearLocation: currentFeature.properties.linearLocation[0],
                    }
                } else {
                    return previousValue
                }
            },
            { linearLocation: {}, distance: Infinity },
        ) : {}
        onRoadDistanceChanged(nearestRoadLinear)
    }, [position, referential, onRoadDistanceChanged])

    const zoomIn = useCallback(() => {
        map.zoomIn()
    }, [map])

    const zoomOut = useCallback(() => {
        map.zoomOut()
    }, [map])

    return <ReactMapbox
        ref={handleMapRef}
        initialViewState={mapInitialViewState}
        localFontFamily={theme.typography.fontFamily}
        logoPosition="top-right"
        mapboxAccessToken={process.env["NX_PUBLIC_MAPBOXTOKEN"]}
        mapStyle={MapStyles.satellite.mapStyle}
        onMove={handleMove}
        onZoom={handleZoom}
        onStyleData={onStyleLoaded}
    >
        <Styled.TopLeftAnchor>
            {road && <Styled.RoadContainer>
                <Styled.RoadText variant="Small" noWrap>
                    {road}
                </Styled.RoadText>
            </Styled.RoadContainer>}
        </Styled.TopLeftAnchor>
        <Styled.BottomRightAnchor>
            <ZoomButtons onZoomIn={zoomIn} onZoomOut={zoomOut} />
        </Styled.BottomRightAnchor>
        {children}
        <Marker {...position} anchor="bottom" >
            <PinIcon style={{ color: theme.palette["error/main"].main, transform: "scale(2)" }} />
        </Marker>
    </ReactMapbox>
}

MapPointEditor.propTypes = {
    initialZoom: PropTypes.number,
    position: PropTypes.shape({
        longitude: PropTypes.number,
        latitude: PropTypes.number,
    }).isRequired,
    onMove: PropTypes.func.isRequired,
    referential: PropTypes.object,
    road: PropTypes.string,
}