import { useCallback, useState } from "react"

import { useParams } from "react-router-dom"

import { CatalogItemEditModal, useGetViewCenter } from "@l2r-front/l2r-common"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { TAGS_FILTER } from "../../constants/filters"
import { EquipmentAddFormPost } from "../../forms/EquipmentAddForm"

import * as Styled from "./EquipmentSidebarComponents.styled"

export function EquipmentSidebarComponents(props) {

    const {
        handleChangeTypeFilter,
        toggleFilter,
    } = props

    const [mapLocation, setMapLocation] = useState(null)

    const { t } = useTranslation(I18N_NAMESPACE)
    const isMobile = useIsMobileDevice()
    const { code, road } = useParams()
    const getMapCenter = useGetViewCenter()

    const handleCreateSign = useCallback(() => {
        setMapLocation(getMapCenter())
    }, [getMapCenter, setMapLocation])

    const handleCloseModal = useCallback(() => {
        setMapLocation(null)
    }, [])

    return <>
        {isMobile && <Styled.MobileFilterButton enabledFilters={[TAGS_FILTER]} onClick={toggleFilter} />}
        {road && <Styled.RoadDetailInfo isMobile={isMobile} road={road} />}
        <Styled.EquipmentsGrading isMobile={isMobile} />
        <Styled.EquipmentTypeList
            baseEquipmentTypeCode={code}
            isMobile={isMobile}
            onChangeFilter={handleChangeTypeFilter}
            onCreate={handleCreateSign}
        />
        {
            mapLocation &&
            <CatalogItemEditModal
                formTitle={t(I18N_NAMESPACE, "containers.equipmentAddForm.title")}
                typeTitle={t(I18N_NAMESPACE, "containers.equipmentDetailsForm.type")}
                contentFieldLabel={t(I18N_NAMESPACE, "containers.equipmentDetailsForm.content")}
                FormComponent={EquipmentAddFormPost}
                startingPosition={mapLocation}
                open={!!mapLocation}
                onClose={handleCloseModal}
            />
        }
    </>
}

EquipmentSidebarComponents.propTypes = {
    handleChangeTypeFilter: PropTypes.func,
    toggleFilter: PropTypes.func,
}