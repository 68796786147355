import { useTheme } from "@emotion/react"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { formatNumber } from "@l2r-front/l2r-utils"

import { useMediaQuery } from "../../hooks/useMediaQuery"

import * as Styled from "./CatalogItemsConditionCount.styled"

export function CatalogItemsConditionCount(props) {

    const {
        color,
        count,
        label,
        onClick,
        selected,
    } = props

    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))

    return <Styled.CatalogItemsConditionCountButton onClick={onClick} isMobile={isSmallScreen} selected={selected}>
        <Styled.Icon color={color} isMobile={isSmallScreen} />
        <Styled.TextWrapper>
            <Styled.CountText variant="H3">{formatNumber(count)}</Styled.CountText>
            <Styled.StateText isMobile={isSmallScreen} variant="Small">{label}</Styled.StateText>
        </Styled.TextWrapper>
    </Styled.CatalogItemsConditionCountButton>
}

CatalogItemsConditionCount.propTypes = {
    color: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
}