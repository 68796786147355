import { useMemo } from "react"

import { PropTypes } from "@l2r-front/l2r-proptypes"
import { Typography } from "@l2r-front/l2r-ui"

import * as Styled from "./YearSelector.styled"

export const YearSelector = (props) => {

    const {
        className,
        onClick,
        selectedValue,
        title,
        yearlyStatuses,
        ...otherProps
    } = props

    const options = useMemo(() => {
        if (!yearlyStatuses?.length) {
            return []
        }
        return yearlyStatuses
            .map(yearlyStatus => {
                return <Styled.Button
                    displayBorder={!yearlyStatus.badgeComponent}
                    disabled={yearlyStatus.disabled}
                    id={`year-selector-button-${yearlyStatus.date}`}
                    key={`network-${yearlyStatus.date}`}
                    onClick={() => onClick(yearlyStatus.value)}
                    selected={selectedValue?.uuid === yearlyStatus.value?.uuid}
                >
                    <Styled.Text color={yearlyStatus.disabled ? "texts/text-disabled" : "objects/object-black"} variant="Light">
                        {yearlyStatus.date}
                        {yearlyStatus.badgeComponent}
                    </Styled.Text>
                </Styled.Button>
            })

    }, [onClick, selectedValue, yearlyStatuses])

    if (!options.length) {
        return null
    }

    return (
        <Styled.Wrapper>
            {title && <Typography variant="Small">{title}</Typography>}
            <Styled.OptionsWrapper className={className} {...otherProps}>
                {options}
            </Styled.OptionsWrapper>
        </Styled.Wrapper>
    )
}

YearSelector.defaultProps = {
    selectedValue: null,
}

YearSelector.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    selectedValue: PropTypes.any,
    title: PropTypes.string,
    yearlyStatuses: PropTypes.arrayOf(
        PropTypes.shape({
            badgeComponent: PropTypes.node,
            disabled: PropTypes.bool,
            date: PropTypes.number.isRequired,
        }).isRequired,
    ).isRequired,
}