import { styled, Typography as UiTypography, Skeleton as UiSkeleton } from "@l2r-front/l2r-ui"

export const Stat = styled(UiTypography)(({ theme }) => ({
    marginLeft: theme.spacing(2),
}))

export const Skeleton = styled(UiSkeleton)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    width: 50,
}))

export const Error = styled(UiTypography)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    color: theme.palette.error.main,
}))