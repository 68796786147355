import { Navigate, Route, Routes } from "react-router-dom"

import { useGetEnabledModulesInApp } from "@l2r-front/l2r-networks"

import { Dashboard } from "../../../modules/dashboard/components/Dashboard"
import { appRoad } from "../../constants/appRoad"
import { RoadDesktopLayout } from "../../layouts/RoadDesktopLayout"

export function RoadDesktopRouter() {

    const enabledRoadModule = useGetEnabledModulesInApp(appRoad)

    return (
        <Routes>
            <Route
                path="/dashboard/*"
                element={
                    <Dashboard />
                }
            />
            <Route
                path="/"
                element={
                    <Navigate replace to={enabledRoadModule?.[0]?.path ? enabledRoadModule?.[0]?.path : ""} />
                }
            />
            <Route
                path="*"
                element={
                    <RoadDesktopLayout />
                }
            />
        </Routes>
    )
}