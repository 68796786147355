import { RoadDetailInfo as L2RSegmentDetailInfo } from "@l2r-front/l2r-networks"
import { styled, styledForMobile } from "@l2r-front/l2r-ui"

import { EquipmentsGrading as L2REquipmentsGrading } from "../../containers/EquipmentsGrading"
import { EquipmentTypeList as L2REquipmentTypeList } from "../../containers/EquipmentTypeList"
import { MobileFilterButton as L2RMobileFilterButton } from "../../containers/MobileFilterButton"

export const EquipmentTypeList = styledForMobile(L2REquipmentTypeList)(({ isMobile, theme }) => ({
    padding: !isMobile && theme.spacing(8),
}))

export const EquipmentsGrading = styledForMobile(L2REquipmentsGrading)(({ isMobile, theme }) => ({
    padding: isMobile ? theme.spacing(0, 0, 8, 0) : theme.spacing(0, 8),
}))

export const RoadDetailInfo = styledForMobile(L2RSegmentDetailInfo)(({ isMobile, theme }) => ({
    padding: !isMobile && theme.spacing(0, 8, 0, 8),
    marginBottom: theme.spacing(isMobile ? 6 : 5),
}))

export const MobileFilterButton = styled(L2RMobileFilterButton)(({ theme }) => ({
    bottom: theme.spacing(6),
    position: "absolute",
    right: theme.spacing(8),
}))