import { useMemo } from "react"

import { ReactMapGl } from "@l2r-front/l2r-map"
import { RoadSearch } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { Map } from "../../../../common/containers/Map"
import { RoadMapLegend } from "../../../../common/containers/RoadMapLegend"

import * as Styled from "./MobileRoadwayMap.styled"

export function MobileRoadwayMap(props) {
    const {
        children,
        layerIndicator,
        ...mapProps
    } = props

    const topRightElements = useMemo(() => {
        const elements = layerIndicator ? [layerIndicator] : []
        elements.push(<RoadSearch key="road-search" disableExpend={false} />)
        elements.push(<RoadMapLegend key="road-map-legend" />)

        return elements
    }, [layerIndicator])

    return (
        <Styled.Container>
            <Map
                {...mapProps}
                topLeftElements={null}
                topRightElements={topRightElements}>
                {children}
            </Map>

        </Styled.Container >
    )
}

MobileRoadwayMap.propTypes = {
    ...ReactMapGl.propTypes,
    className: PropTypes.string,
    layerIndicator: PropTypes.element,
}