import { useMemo } from "react"

import { useLocation, matchPath } from "react-router-dom"

import { APP_ROAD, appRoad } from "@l2r-front/l2r-app-road"
import { useGetEnabledModulesInApp, useNetworksStateContext } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useTheme, useMediaQuery } from "@l2r-front/l2r-ui"

import { l2rApps } from "../../constants/l2rApps"
import { AvatarWithAccountMenu } from "../../containers/AvatarWithAccountMenu"

import * as Styled from "./AuthenticatedDesktopLayout.styled"

export function AuthenticatedDesktopLayout(props) {
    const { children } = props
    const { pathname } = useLocation()

    const { selectedNetwork } = useNetworksStateContext()
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"))

    const customLayoutRoutes = useMemo(() => {
        return Object.values(l2rApps).reduce((routes, app) => {
            if (app.desktopCustomLayoutRoutes) {
                return routes.concat(app.desktopCustomLayoutRoutes)
            }
            return routes
        }, [])
    }, [])
    const enabledRoadModule = useGetEnabledModulesInApp(appRoad)

    if (customLayoutRoutes.some(path => matchPath(`/:networkSlug${path}`, pathname))) {
        return <Styled.ChildrenWrapperWithoutAppBar>
            {children}
        </Styled.ChildrenWrapperWithoutAppBar>
    }

    return (
        <>
            <Styled.AppBar
                id="app-bar"
                position="fixed"
                moduleTabs={<Styled.ModuleSelector />}
            >
                <Styled.Link to={selectedNetwork ? `/${selectedNetwork.slug}/${APP_ROAD}/${enabledRoadModule?.[0]?.path}` : "/"}>
                    {
                        isLargeScreen ? <Styled.Logo id="logiroad-logo" src={"assets/resources/logiroad-center-logo.svg"} />
                            : <Styled.Logo id="logiroad-favicon" src={"assets/resources/Favicon_Logiroad.svg"} />
                    }

                </Styled.Link>
                <Styled.AppSelector id="app-selector" />
                <Styled.NetworkSelector id="network-selector" />
                <AvatarWithAccountMenu />
            </Styled.AppBar>
            <Styled.ChildrenWrapper>
                {children}
            </Styled.ChildrenWrapper>
        </>
    )
}

AuthenticatedDesktopLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
