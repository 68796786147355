import { useMemo } from "react"

import { useRoutes, Navigate } from "react-router-dom"

import { DetailSegmentSelectListener, SegmentSelectListener } from "@l2r-front/l2r-common"
import { MapAdapterBoundingBox, MapAdapterRoad } from "@l2r-front/l2r-networks"
import { useUrlParams, NavigateWithParams } from "@l2r-front/l2r-utils"

import { EquipmentSelectListener } from "../../containers/EquipmentSelectListener"

import { EquipmentValidator } from "../EquipmentValidator"

export const EquipmentDesktopRoutes = () => {
    const { getParams } = useUrlParams()

    const equipmentRoutes = useMemo(() => {
        const searchParams = getParams()
        return [
            {
                path: "/",
                element: <>
                    <MapAdapterBoundingBox />
                    <SegmentSelectListener />
                </>,
            },
            {
                path: "/:road",
                element: <>
                    <MapAdapterRoad />
                    <DetailSegmentSelectListener />
                </>,
            },
            {
                path: "/:road/:signId",
                element: <EquipmentValidator>
                    <EquipmentSelectListener />
                </EquipmentValidator>,
            },
            {
                path: "/code/*",
                element: <Navigate replace to={`../?${searchParams}`} />,
            },
            {
                path: "/:road/code/*",
                element: <NavigateWithParams replace to={`../:road?${searchParams}`} />,
            },
        ]
    }, [getParams])

    return useRoutes(equipmentRoutes)
}