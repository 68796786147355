import { useCallback, useMemo, useState } from "react"

import { format } from "date-fns"

import { CatalogItemEditModal } from "@l2r-front/l2r-common"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { ModeEditIcon } from "@l2r-front/l2r-icons"
import { useRoleIsReadOnly } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice, Typography } from "@l2r-front/l2r-ui"
import { findInCatalog } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { VERTICAL_SIGNING_CONDITIONS_VALUES } from "../../constants/verticalSigningConditions"
import { VerticalSignDetailsFormPost } from "../../forms/VerticalSignDetailsForm"
import { useVerticalSignTypes } from "../../hooks/queries/verticalSigning/useVerticalSignTypes"
import { useVerticalSignAscendantCodes } from "../../hooks/utils/useVerticalSignAscendantCodes"

import * as Styled from "./VerticalSignDetails.styled"

export function VerticalSignDetails(props) {

    const { verticalSign, poleId } = props

    const [modalOpen, setModalOpen] = useState(false)

    const ascendantCodes = useVerticalSignAscendantCodes(verticalSign.code)
    const { data: verticalSignsTypes } = useVerticalSignTypes()
    const { t } = useTranslation()
    const isMobile = useIsMobileDevice()
    const readOnly = useRoleIsReadOnly()

    const codeHierarchy = useMemo(() => {
        if (ascendantCodes.length && verticalSignsTypes) {
            return <Typography variant="H3">
                {ascendantCodes
                    .reverse()
                    .map((code) => findInCatalog(code, verticalSignsTypes).name)
                    .join(" > ")
                }
            </Typography>
        }
        return <Styled.Skeleton />
    }, [ascendantCodes, verticalSignsTypes])

    const closeModal = useCallback(() => {
        setModalOpen(false)
    }, [])

    const tagsChips = useMemo(() => {
        return (verticalSign.tags || []).map(tag => ({ label: tag }))
    }, [verticalSign])

    const updatedInfo = useMemo(() => {
        if (!verticalSign.updated_at) {
            return null
        }

        const date = format(new Date(verticalSign.updated_at), "dd/MM/yyyy")
        const user = verticalSign.updated_by ?? "Logiroad"
        return <Typography variant="caption">
            {
                t(I18N_NAMESPACE, "containers.verticalSignDetail.updateInfo", {
                    date,
                    user,
                })
            }
        </Typography>
    }, [verticalSign, t])

    return <>
        <Styled.Container>
            <Styled.TitleHeader>
                {codeHierarchy}
                {!readOnly && <Styled.FilledIconButton id="linear-edit-button" onClick={() => setModalOpen(true)}>
                    <ModeEditIcon />
                </Styled.FilledIconButton>}
            </Styled.TitleHeader>
            <Styled.TextWrapper>
                <Styled.Text isMobile={isMobile}>
                    {t(I18N_NAMESPACE, "containers.verticalSignDetail.condition.label")}&nbsp;
                </Styled.Text>
                <Styled.StateWrapper
                    color={VERTICAL_SIGNING_CONDITIONS_VALUES[verticalSign.condition].backgroundColor}
                    isMobile={isMobile}
                >
                    <Styled.State
                        color={VERTICAL_SIGNING_CONDITIONS_VALUES[verticalSign.condition].color}
                        variant="H3"
                    >
                        {t(I18N_NAMESPACE, VERTICAL_SIGNING_CONDITIONS_VALUES[verticalSign.condition].label)}
                    </Styled.State>
                </Styled.StateWrapper>
            </Styled.TextWrapper>
            {
                verticalSign?.is_textual && <Styled.TextWrapper>
                    <Styled.Text isMobile={isMobile}>
                        {t(I18N_NAMESPACE, "containers.verticalSignDetail.panelText.label")}&nbsp;
                    </Styled.Text>
                    <Styled.ContentText
                        color={verticalSign.content ? "colors/gris/1000" : "texts/text-placeholder"}
                        isMobile={isMobile}
                    >
                        {verticalSign.content || t(I18N_NAMESPACE, "containers.verticalSignDetail.panelText.text")}
                    </Styled.ContentText>
                </Styled.TextWrapper>
            }
            <Styled.TextWrapper>
                <Styled.Text isMobile={isMobile}>
                    {t(I18N_NAMESPACE, "containers.verticalSignDetail.tags")}&nbsp;
                </Styled.Text>
                {tagsChips.length ? <Styled.ChipList isMobile={isMobile} values={tagsChips} />
                    : <Styled.ContentText color={"texts/text-placeholder"} isMobile={isMobile}>-</Styled.ContentText>}
            </Styled.TextWrapper>
            {updatedInfo}
        </Styled.Container>
        <CatalogItemEditModal
            formTitle={t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.title")}
            typeTitle={t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.type")}
            contentFieldLabel={t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.content")}
            FormComponent={VerticalSignDetailsFormPost}
            signId={verticalSign?.uuid}
            poleId={poleId}
            open={modalOpen}
            onClose={closeModal}
        />
    </>
}

VerticalSignDetails.propTypes = {
    verticalSign: PropTypes.verticalSign.isRequired,
}
