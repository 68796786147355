import { Fragment, useMemo, useState } from "react"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { LayersIcon } from "@l2r-front/l2r-icons"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { MapLayerItem } from "../../components/MapLayerItem"
import { I18N_NAMESPACE } from "../../constants/i18n"
import { MAP_ACTION_CHANGE_STYLE } from "../../constants/map"
import { MapStyles } from "../../constants/mapStyles"
import { useMapContext } from "../../contexts/MapContext"

import * as Styled from "./MapLayerSelector.styled"

const layersList = [
    {
        title: "containers.mapLayerSelector.mapStyles.title",
        id: "mapStyle",
        layers: [
            {
                action: MAP_ACTION_CHANGE_STYLE,
                field: "mapStyle",
                id: "mapStyle-plan",
                image: MapStyles.plan.background,
                title: "containers.mapLayerSelector.mapStyles.plan",
                value: MapStyles.plan,
            },
            {
                action: MAP_ACTION_CHANGE_STYLE,
                field: "mapStyle",
                id: "mapStyle-satellite",
                image: MapStyles.satellite.background,
                title: "containers.mapLayerSelector.mapStyles.satellite",
                value: MapStyles.satellite,
            },
        ],
    },
]

export const MapLayerSelector = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [mapState, mapDispatch] = useMapContext()
    const { mapLayersState } = mapState
    const { setMapLayerState } = mapDispatch

    const { t } = useTranslation(I18N_NAMESPACE)
    const isMobile = useIsMobileDevice()

    const lists = useMemo(() => {
        return layersList.map((list, index) => {
            return <Fragment key={list.id}>
                <div>
                    <Styled.Title
                        variant="H4"
                        isFirst={index === 0}>
                        {t(I18N_NAMESPACE, list.title)}
                    </Styled.Title>
                    <Styled.LayerList>
                        {list.layers.map(layer => <MapLayerItem
                            key={layer.id}
                            image={layer.image}
                            title={t(I18N_NAMESPACE, layer.title)}
                            isSelected={mapLayersState[layer.field] === layer.value}
                            onClick={() => setMapLayerState(layer.action, layer.value)}
                        />)}
                    </Styled.LayerList>
                </div>
                {layersList.length > 1 && index < layersList.length - 1 && <Styled.Divider />}
            </Fragment>
        })
    }, [mapLayersState, setMapLayerState, t])

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return <>
        <Styled.Button
            color="cta-bg/cta-bg-primary"
            id="map-layers-open-button"
            onClick={handlePopoverOpen}
        >
            <LayersIcon />
        </Styled.Button>

        {!isMobile && <Styled.Container
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            onClose={handlePopoverClose}
            open={open}>
            <Styled.ContentWrapper>
                <Styled.List>
                    {lists}
                </Styled.List>
                <Styled.CloseButton onClick={handlePopoverClose}>
                    <Styled.CloseIcon />
                </Styled.CloseButton>
            </Styled.ContentWrapper>
        </Styled.Container >}
        {
            isMobile && <Styled.BottomSheet
                open={open}
                onClose={handlePopoverClose}>
                <Styled.ContentWrapper>
                    <Styled.List>
                        {lists}
                    </Styled.List>
                    <Styled.CloseButton onClick={handlePopoverClose}>
                        <Styled.CloseIcon />
                    </Styled.CloseButton>
                </Styled.ContentWrapper>
            </Styled.BottomSheet>
        }
    </>
}