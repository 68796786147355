import { useTheme } from "@emotion/react"
import { Autocomplete, Chip } from "@mui/material"

import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./AutoCompleteWithChips.styled"

const BOX_MAX_HEIGHT = 114

export const AutoCompleteWithChips = (props) => {

    const {
        label,
        id,
        maxHeight,
        maxLength,
        variant,
        ...autocompleteProps
    } = props

    const { getOptionLabel } = autocompleteProps
    const theme = useTheme()

    const boxMaxHeight = maxHeight ?? BOX_MAX_HEIGHT

    return <Autocomplete
        {...autocompleteProps}
        multiple
        id={id}
        renderInput={(params) => {
            const { InputProps, ...restParams } = params
            const { startAdornment, ...restInputProps } = InputProps
            return <Styled.TextField
                {...restParams}
                InputProps={{
                    ...restInputProps,
                    startAdornment: (
                        <div style={{
                            maxHeight: boxMaxHeight,
                            overflowY: "auto",
                        }}
                        >
                            {startAdornment}
                        </div>
                    ),
                }}
                {...params}
                color="cta-bg/cta-bg-primary"
                label={label}
                inputProps={{ ...params.inputProps, maxLength }}
            />
        }}
        renderTags={(value, getTagProps) =>
            value.map((option, index) => (
                <Chip
                    {...getTagProps({ index })}
                    color="cta-bg/cta-bg-primary"
                    label={getOptionLabel ? getOptionLabel(option) : option}
                    variant={variant}
                />
            ))
        }
        slotProps={{
            paper: {
                elevation: 2,
                sx: {
                    borderRadius: theme.spacing(3),
                    marginTop: theme.spacing(1),
                },
            },
        }}
    />
}

AutoCompleteWithChips.defaultProps = {
    variant: "filled",
}

AutoCompleteWithChips.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    variant: PropTypes.string,
}
