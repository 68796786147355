import { createContext } from "react"

export const initialState = {
    filter: null,
}

const initialDispatch = {}

export const EquipmentStateContext = createContext(initialState)
export const EquipmentDispatchContext = createContext(initialDispatch)
