import { useContext } from "react"

import { CatalogItemEditStateContext, CatalogItemEditDispatchContext } from "./CatalogItemEditContext.context"

export const useCatalogItemEditStateContext = () => {
    const context = useContext(CatalogItemEditStateContext)
    if (context === undefined) {
        throw new Error("useCatalogItemEditStateContext must be used within an CatalogItemEditStateContext")
    }
    return context
}

export const useCatalogItemEditDispatchContext = () => {
    const context = useContext(CatalogItemEditDispatchContext)
    if (context === undefined) {
        throw new Error("useCatalogItemEditDispatchContext must be used within an CatalogItemEditDispatchContext")
    }
    return context
}

export const useCatalogItemEditContext = () => {
    return [useCatalogItemEditStateContext(), useCatalogItemEditDispatchContext()]
}
