import { useCallback } from "react"

import { CatalogItemsFiltersForm } from "@l2r-front/l2r-common"

import { useVerticalSigningDispatchContext, useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext/VerticalSigningContext.hooks"
import { useVerticalSigningProject } from "../../hooks/queries/verticalSigning/useVerticalSigningProject"


export function VerticalSignFiltersFormPost(props) {
    const {
        close,
        ...verticalSignFiltersFormProps
    } = props

    const { setFilters } = useVerticalSigningDispatchContext()
    const { filters } = useVerticalSigningStateContext()
    const { data: project } = useVerticalSigningProject()

    const onSubmit = useCallback((values) => {
        setFilters(values)
        close()
    }, [close, setFilters])

    return <CatalogItemsFiltersForm
        {...verticalSignFiltersFormProps}
        activeFilters={filters}
        close={close}
        onSubmit={onSubmit}
        project={project}
        resetFilters={() => setFilters({ tags: [] })}
    />
}