import { PropTypes } from "@l2r-front/l2r-proptypes"
import { formatNumber } from "@l2r-front/l2r-utils"

import * as Styled from "./CatalogItemsCount.styled"

export function CatalogItemsCount(props) {

    const {
        className,
        filteredCount,
        isMainCount,
        totalCount = null,
    } = props

    return <Styled.Wrapper className={className} isMainCount={isMainCount}>
        <Styled.Text variant="Regular">{formatNumber(filteredCount)}</Styled.Text>
        {Number.isInteger(totalCount) ? <Styled.TextTotal variant="Small">/{formatNumber(totalCount)}</Styled.TextTotal>
            : null}
    </Styled.Wrapper>
}

CatalogItemsCount.propTypes = {
    className: PropTypes.string,
    filteredCount: PropTypes.number.isRequired,
    isMainCount: PropTypes.bool,
    totalCount: PropTypes.number,
}