import { useQueryClient } from "@tanstack/react-query"

import { l2rApiAxiosInstance as axiosInstance } from "@l2r-front/l2r-query"

import { equipmentQueryKeys } from "../queries/equipment/queryKeys"

import { useEquipmentMutation } from "./useEquipmentMutation"

export function useEquipmentCreate(
    queryParameters,
    config = {},
) {
    const url = "equipments/"
    const queryClient = useQueryClient()

    return useEquipmentMutation(
        url,
        axiosInstance.post,
        {
            ...queryParameters,
        },
        {
            ...config,
            onSuccess: (data) => {
                queryClient.invalidateQueries({ queryKey: equipmentQueryKeys.project() })
                queryClient.invalidateQueries({ queryKey: equipmentQueryKeys.layer() })
                queryClient.invalidateQueries({ queryKey: equipmentQueryKeys.stats_categories() })
                queryClient.invalidateQueries({ queryKey: equipmentQueryKeys.stats_conditions() })
                config.onSuccess?.(data)
            },
        },
    )
}