import { useCallback, useMemo } from "react"

import { DEFAULT_SIGN_ICON } from "@l2r-front/l2r-common"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { useRoleIsReadOnly } from "@l2r-front/l2r-networks"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import {
    CatalogList,
    CatalogListError,
    CatalogListSkeleton,
} from "@l2r-front/l2r-ui"
import { getAscendantCodes } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useEquipmentContext } from "../../contexts/EquipmentContext"
import { useEquipmentType, useEquipmentTypes } from "../../hooks/queries/equipment/useEquipmentsType"

import { EquipmentsCount } from "../EquipmentsCount"

import * as Styled from "./EquipmentTypeList.styled"

export function EquipmentTypeList(props) {

    const {
        baseEquipmentTypeCode,
        className,
        onChangeFilter,
        onCreate,
    } = props

    const { t } = useTranslation()
    const { data } = useEquipmentType(baseEquipmentTypeCode)
    const [state, dispatch] = useEquipmentContext()
    const { ascendantCodes, filters } = state
    const readOnly = useRoleIsReadOnly()

    const {
        data: equipmentTypes,
        isError: isErrorEquipmentTypes,
        isLoading: isLoadingEquipmentTypes,
        isFetching: isFetchingEquipmentTypes,
    } = useEquipmentTypes(baseEquipmentTypeCode)

    const { data: catalogEquipmentTypes } = useEquipmentTypes()

    const { setFilters } = dispatch

    const title = useMemo(() => {
        if (data) {
            return <Styled.TitleWrapper>
                <Styled.Icon
                    alt={data.name}
                    src={data.icon}
                    onError={e => e.target.src = DEFAULT_SIGN_ICON} />
                {baseEquipmentTypeCode ? data.name : t(I18N_NAMESPACE, "containers.equipmentTypeList.defaultTitle")}
            </Styled.TitleWrapper>
        }
        return data?.name ?? t(I18N_NAMESPACE, "containers.equipmentTypeList.defaultTitle")
    }, [baseEquipmentTypeCode, data, t])

    const enhancedEquipmentTypes = useMemo(() => {
        function getTooltipText(item) {
            const itemAscendantCodes = getAscendantCodes(item.code, catalogEquipmentTypes)
            const parent = equipmentTypes.find(equipmentType => equipmentType.code === item.parent)
            if (item.is_purchased || parent && !parent.is_purchased) {
                return null
            } else {
                switch (itemAscendantCodes?.length) {
                    case 1:
                        return t(I18N_NAMESPACE, "containers.equipmentTypeList.familyDisabledTooltip")
                    case 2:
                        return t(I18N_NAMESPACE, "containers.equipmentTypeList.categoryDisabledTooltip")
                    default:
                        return t(I18N_NAMESPACE, "containers.equipmentTypeList.typeDisabledTooltip")
                }
            }
        }
        function enhanceEquipmentTypes(items, t) {
            return (items || []).map(child => ({
                ...child,
                disabled: !child.is_purchased,
                tooltipText: getTooltipText(child),
                children: child.children ? enhanceEquipmentTypes(child.children, t) : undefined,
            }))
        }
        return enhanceEquipmentTypes(equipmentTypes, t)
    }, [catalogEquipmentTypes, equipmentTypes, t])

    const applyFilter = useCallback((newFilter) => {
        if (filters.type === newFilter || ascendantCodes.indexOf(newFilter) >= 0) {
            const filterIndex = ascendantCodes.indexOf(newFilter)
            if (filterIndex !== ascendantCodes.length - 1) {
                setFilters({ type: ascendantCodes[filterIndex + 1] })
            } else {
                setFilters({ type: null })
            }
        } else {
            setFilters({ type: newFilter })
            onChangeFilter?.({ type: newFilter })
        }
    }, [filters, ascendantCodes, setFilters, onChangeFilter])

    if (isErrorEquipmentTypes && !isFetchingEquipmentTypes) {
        return <CatalogListError className={className} errorText={t(I18N_NAMESPACE, "components.equipmentTypeList.error")} />
    }

    if (isLoadingEquipmentTypes) {
        return <CatalogListSkeleton className={className} />
    }

    return <CatalogList
        alwaysDisplayIconsWhenAvailable
        onClick={applyFilter}
        baseItemCode={baseEquipmentTypeCode}
        ascendantCodes={ascendantCodes}
        className={className}
        data={enhancedEquipmentTypes}
        defaultIcon={DEFAULT_SIGN_ICON}
        displayTypeIcon
        emptyListText={t(I18N_NAMESPACE, "components.equipmentTypeList.emptyList")}
        filter={filters?.type}
        title={title}
        ItemsCountComponent={EquipmentsCount}
        onCreate={!readOnly ? onCreate : null}
    />
}

EquipmentTypeList.propTypes = {
    baseEquipmentTypeCode: PropTypes.string,
    className: PropTypes.string,
    onChangeFilter: PropTypes.func,
    onCreate: PropTypes.func,
}
