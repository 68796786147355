import { useMemo } from "react"

import { getQuarter } from "date-fns"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { formatNumberWithUnit } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { STATUS_DONE, STATUS_TODO } from "../../constants/status"

import * as Styled from "./RoadworkQuartersBudget.styled"

export function RoadworkQuartersBudget(props) {

    const {
        budget,
        currency,
        quartersCost,
        totalDoneCost,
    } = props

    const { t } = useTranslation(I18N_NAMESPACE)
    const currentQuarter = getQuarter(new Date())

    const quarters = useMemo(() => {
        const doneValues = quartersCost?.[STATUS_DONE]
            .slice(0, currentQuarter)
            .map((numberFromApi, index) => {
                const costNumber = parseFloat(numberFromApi)
                const width = budget ? costNumber * 100 / budget : totalDoneCost ? costNumber * 50 / totalDoneCost : 0
                return ({
                    cost: formatNumberWithUnit(numberFromApi),
                    label: `${t(I18N_NAMESPACE, "components.roadworkQuartersBudget.label")} ${index + 1}`,
                    status: STATUS_DONE,
                    width,
                })
            }) || []

        const todoValue = {
            cost: budget ? formatNumberWithUnit(budget - totalDoneCost) : "- k",
            label: t(I18N_NAMESPACE, "components.roadworkQuartersBudget.remaining"),
            status: STATUS_TODO,
            width: budget ? parseFloat(budget - totalDoneCost) / budget * 100 : 100,
        }
        return [...doneValues, todoValue]
    }, [budget, currentQuarter, quartersCost, t, totalDoneCost])

    return <Styled.RoadworkQuartersBudgetWrapper>
        {quarters.map((quarter, index) => <Styled.RoadworkQuarterBudget
            {...quarter}
            currency={currency}
            key={`quarter-${index}`}
            id={`quarter-${index}`}
        />)}
    </Styled.RoadworkQuartersBudgetWrapper>
}

RoadworkQuartersBudget.propTypes = {
    budget: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    quartersCost: PropTypes.quartersCost.isRequired,
    totalDoneCost: PropTypes.number,
}

RoadworkQuartersBudget.defaultProps = {
    totalDoneCost: 0,
}