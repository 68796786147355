import { PropTypes } from "@l2r-front/l2r-proptypes"

import * as Styled from "./MapLayerItem.styled"

export const MapLayerItem = (props) => {
    const {
        image,
        title,
        isSelected,
        onClick,
    } = props

    return (
        <Styled.Wrapper onClick={onClick}>
            <Styled.Image background={image} isSelected={isSelected} />
            <Styled.Title variant="Very small" isSelected={isSelected}>{title}</Styled.Title>
        </Styled.Wrapper>
    )
}

MapLayerItem.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
}

MapLayerItem.defaultProps = {
    isSelected: false,
}