import { APP_ROAD, appRoad } from "@l2r-front/l2r-app-road"
import { useAuthenticationStateContext } from "@l2r-front/l2r-auth"
import { useGetEnabledModulesInApp, useNetworksStateContext } from "@l2r-front/l2r-networks"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { AvatarWithAccountMenu } from "../AvatarWithAccountMenu"

import * as Styled from "./SimpleAppBar.styled"

export const SimpleAppBar = () => {
    const { selectedNetwork } = useNetworksStateContext()
    const { isAuthenticated } = useAuthenticationStateContext()
    const isMobile = useIsMobileDevice()

    const enabledRoadModule = useGetEnabledModulesInApp(appRoad)

    return (
        <Styled.AppBar isMobile={isMobile}
            position="fixed"
            elevation={29}>
            {!isMobile &&
                <Styled.Link to={selectedNetwork ? `/${selectedNetwork.slug}/${APP_ROAD}/${enabledRoadModule?.[0]?.path}` : "/"}>
                    <Styled.Logo id="logiroad-logo" src={"assets/resources/logiroad-center-logo.svg"} />
                </Styled.Link>
            }
            {isAuthenticated && <Styled.WrapperRight><AvatarWithAccountMenu /></Styled.WrapperRight>}
        </Styled.AppBar>
    )
}