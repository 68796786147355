
import { findTypeByCode } from "./findTypeByCode"

function getCodes(types) {
    let codes = []

    types.forEach(type => {
        codes.push(type.code)
        codes = codes.concat(getCodes(type.children))
    })

    return codes
}

export function getDescendantCodes(code, typeData) {
    if (typeData) {
        const vsType = findTypeByCode(code, typeData)

        return [code].concat(getCodes(vsType ? vsType.children : typeData))
    }

    return []
}