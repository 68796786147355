import { useMemo, useCallback } from "react"

import * as turf from "@turf/turf"
import { useParams } from "react-router-dom"

import { CatalogItemsSource, DEFAULT_SIGN_CODE, DEFAULT_SIGN_ICON } from "@l2r-front/l2r-common"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { findTypeByCode } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext"
import { useVerticalSignPoles } from "../../hooks/queries/verticalSigning/useVerticalSignPoles"
import { useVerticalSignTypes } from "../../hooks/queries/verticalSigning/useVerticalSignTypes"

const MULTI_SIGNS_CODE = "MULTI_SIGNS"
const MULTI_SIGNS_ICON = "assets/resources/MultiSigns.png"

export const ENABLED_STATE = "1"
export const DISABLED_STATE = "0"

export function VerticalSignPolesSource(props) {
    const { t } = useTranslation(I18N_NAMESPACE)
    const { setError: setMapError } = useMapDispatchContext()
    const { descendantCodes, filters } = useVerticalSigningStateContext()
    const { signId: poleId } = useParams()

    const { data: verticalSignTypes } = useVerticalSignTypes()

    const { data } = useVerticalSignPoles({
        as_geojson: true,
    }, {
        onError: () => setMapError(true),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.verticalSignPolesLayer.error"),
    })

    const getIcon = useCallback((typeCode) => {
        return typeCode === MULTI_SIGNS_CODE ? MULTI_SIGNS_ICON :
            typeCode === DEFAULT_SIGN_CODE ? DEFAULT_SIGN_ICON :
                findTypeByCode(typeCode, verticalSignTypes)?.icon ? findTypeByCode(typeCode, verticalSignTypes)?.icon : DEFAULT_SIGN_ICON
    }, [verticalSignTypes])

    // Need to copy id into properties because of that mapbox issue: 
    // link:https://github.com/mapbox/mapbox-gl-js/issues/2716
    const sanitizedData = useMemo(() => {
        if (!data || !verticalSignTypes) {
            return null
        }

        const dataFeatures = []
        turf.geomEach(data, (currentGeometry, featureIndex, featureProperties, featureBBox, featureId) => {
            const feature = data.features[featureIndex]
            const filterConditions = filters?.conditions?.length ? filters?.conditions.split(",") : null
            const filterTags = filters?.tags || null
            const selectedSigns = featureProperties.vertical_signs
                .filter(sign => {
                    const isFilteredByType = descendantCodes.indexOf(sign.vertical_sign_type) >= 0
                    const isSelectedPole = featureId === poleId
                    const isFilteredByCondition = !filterConditions || filterConditions?.includes(sign.condition.toString())
                    const isFilteredByTags = !filterTags || filterTags.every(filterTag => sign.tags.includes(filterTag))
                    return isSelectedPole || (isFilteredByType && isFilteredByCondition && isFilteredByTags)
                })

            if (selectedSigns.length) {
                dataFeatures.push({
                    id: featureId,
                    bbox: featureBBox,
                    geometry: currentGeometry,
                    properties: {
                        ...featureProperties,
                        vertical_signs: [...selectedSigns],
                        uuid: featureId,
                        linearLocation: feature.linear_location,
                        globalCondition: getGlobalCondition(selectedSigns, verticalSignTypes),
                        globalIcon: getGlobalIcon(selectedSigns, verticalSignTypes),
                    },
                })
            }
        })
        return turf.featureCollection(dataFeatures)
    }, [data, descendantCodes, filters, poleId, verticalSignTypes])

    if (!sanitizedData) {
        return null
    }

    return (
        <CatalogItemsSource
            {...props}
            getIcon={getIcon}
            sanitizedData={sanitizedData}
            types={verticalSignTypes}
        />
    )
}

const isAllOnSameCondition = (signs) => signs.every(sign => sign.condition === signs[0].condition)

const _getGlobalCondition = (signs) => {
    if (signs.length === 1) {
        return signs[0].condition
    }
    if (signs.length >= 2 && isAllOnSameCondition(signs)) {
        return signs[0].condition
    }
    return -1
}

const getGlobalCondition = (signs, verticalSignTypes) => {
    if (signs.length === 0) {
        return -1
    }
    if (signs.length === 1) {
        return signs[0].condition
    }
    if (isAllOnSameCondition(signs)) {
        return signs[0].condition
    }

    const signTypes = signs.map((sign) => findTypeByCode(sign.vertical_sign_type, verticalSignTypes))
    const mainSigns = signs.filter((_, index) => signTypes[index].is_main)
    const notMainSigns = signs.filter((_, index) => !signTypes[index].is_main)

    if (!mainSigns.length) {
        return _getGlobalCondition(notMainSigns)
    }

    return _getGlobalCondition(mainSigns)
}

const getGlobalIcon = (signs, verticalSignTypes) => {
    if (signs.length === 0) {
        return DEFAULT_SIGN_CODE
    }

    if (signs.length === 1) {
        const sign = signs[0]
        return sign.vertical_sign_type
    }

    const { mainSignCount, lastMainSignType } = signs.reduce(
        (acc, sign) => {
            const signType = findTypeByCode(sign.vertical_sign_type, verticalSignTypes)
            if (!signType.is_main) {
                return acc
            }
            return { mainSignCount: acc["mainSignCount"] + 1, lastMainSignType: sign.vertical_sign_type }
        },
        {
            mainSignCount: 0,
            lastMainSignType: DEFAULT_SIGN_CODE,
        },
    )

    if (mainSignCount === 0 || mainSignCount >= 2) {
        return MULTI_SIGNS_CODE
    }

    return lastMainSignType
}