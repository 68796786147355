import { useCallback } from "react"

import * as turf from "@turf/turf"
import { useParams } from "react-router-dom"

import { getFeatureCenterPoint, useGeoServerFeaturesList } from "@l2r-front/l2r-geodata"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { networksQueryKeys, useNetworkReferential, useNetworksStateContext } from "@l2r-front/l2r-networks"
import { useIsMobileDevice } from "@l2r-front/l2r-ui"

export const useGetViewCenter = () => {
    const { selectedNetwork } = useNetworksStateContext()
    const { data: referential } = useNetworkReferential()
    const { road } = useParams()
    const { getMapRef } = useMapDispatchContext()
    const isMobile = useIsMobileDevice()
    const map = getMapRef()


    const { data: roadSegments } = useGeoServerFeaturesList(
        networksQueryKeys.list(road),
        {
            layer: selectedNetwork?.referential?.layerSection,
            linear_location_road: road,
            as_geojson: true,
        },
        {
            enabled: !!road && !!selectedNetwork?.referential,
        })

    const getViewCenter = useCallback(() => {
        if (!isMobile) {
            if (!map) {
                return null
            }
            const center = map.getCenter()
            return ({ latitude: center.lat, longitude: center.lng })
        }
        if (road) {
            if (roadSegments) {
                const center = getFeatureCenterPoint(roadSegments)
                return ({
                    latitude: center.geometry.coordinates[1],
                    longitude: center.geometry.coordinates[0],
                })
            }
        } else if (referential) {
            const referentialCenter = turf.center(referential)
            return ({
                latitude: referentialCenter.geometry.coordinates[1],
                longitude: referentialCenter.geometry.coordinates[0],
            })
        }

        return null
    }, [map, isMobile, referential, road, roadSegments])

    return getViewCenter

}