import { Route, Routes } from "react-router-dom"

import { EquipmentSidebarRouter } from "../../../modules/equipment/routers/EquipmentSidebarRouter"
import { VerticalSigningSidebarRouter } from "../../../modules/verticalSigning/routers/VerticalSigningSidebarRouter"
import { MODULE_EQUIPMENT, MODULE_VERTICAL_SIGNING } from "../../constants/modules"

export const SidebarRouter = () => {

    return <Routes>
        <Route
            element={<VerticalSigningSidebarRouter />}
            path={`/${MODULE_VERTICAL_SIGNING}/*`}
        />
        <Route
            element={<EquipmentSidebarRouter />}
            path={`/${MODULE_EQUIPMENT}/*`}
        />
    </Routes>
}