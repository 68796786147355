import { useCallback, useMemo, useState } from "react"

import { useQueryClient } from "@tanstack/react-query"
import { useParams } from "react-router-dom"

import { CatalogItemEditModal, DEFAULT_SIGN_ICON } from "@l2r-front/l2r-common"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { ModeEditIcon } from "@l2r-front/l2r-icons"
import { RoadDetailInfo, useRoleIsReadOnly } from "@l2r-front/l2r-networks"
import { Typography, useIsMobileDevice } from "@l2r-front/l2r-ui"
import { useUrlParams } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { DETAIL_TAB_PARAM_KEY } from "../../../../common/constants/tabKeys"
import { VERTICAL_SIGNING_CONDITIONS_VALUES } from "../../constants/verticalSigningConditions"
import { useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext"
import { VerticalSignAddFormPost } from "../../forms/VerticalSignAddForm"
import { VerticalSignPoleEditFormPost } from "../../forms/VerticalSignPoleEditForm/VerticalSignPoleEditForm.post"
import { verticalSigningQueryKeys } from "../../hooks/queries/verticalSigning/queryKeys"
import { usePoleWithVerticalSigns } from "../../hooks/queries/verticalSigning/usePoleWithVerticalSigns"
import { useVerticalSignDescendantCodes } from "../../hooks/utils/useVerticalSignDescendantCodes"

import { VerticalSignDetails } from "../VerticalSignDetails"

import * as Styled from "./VerticalSignPoleDetailsSidebarContent.styled"

export function VerticalSignPoleDetailsSidebarContent() {

    const [locationModalOpen, setLocationModalOpen] = useState(false)
    const [addSignModalOpen, setAddSignModalOpen] = useState(false)

    const { signId: poleId, road } = useParams()
    const { data, isError } = usePoleWithVerticalSigns(poleId)
    const { filters } = useVerticalSigningStateContext()
    const filterDescendantCodes = useVerticalSignDescendantCodes(filters.type)
    const isMobile = useIsMobileDevice()

    const { t } = useTranslation(I18N_NAMESPACE)
    const readOnly = useRoleIsReadOnly()
    const queryClient = useQueryClient()

    const {
        setParam,
    } = useUrlParams()
    const { getParam } = useUrlParams()
    const tabParam = getParam(DETAIL_TAB_PARAM_KEY)

    const latLong = data?.geometry.coordinates

    const LatLongComponent = useMemo(() => {
        if (latLong) {
            return <Typography id="road-detail-latlong" variant="Regular">
                {latLong[0].toFixed(6)}, {latLong[1].toFixed(6)}
            </Typography>
        }
        return <Styled.Skeleton />
    }, [latLong])

    const filtersPredicate = useCallback((verticalSign) => {
        const conditionPredicate = !filters.conditions || filters.conditions.includes(verticalSign.condition)
        const tagsPredicate = !filters.tags || filters.tags.every(tag => verticalSign.tags.includes(tag))
        const typePredicate = !filters.type || filterDescendantCodes.includes(verticalSign.code)

        return conditionPredicate && tagsPredicate && typePredicate
    }, [filterDescendantCodes, filters])

    const displayedTabIndex = useMemo(() => {
        if (!data) {
            return null
        }

        const index = data.vertical_signs.findIndex(verticalSign => {
            return filtersPredicate(verticalSign)
        })

        return index >= 0 ? index : null
    }, [data, filtersPredicate])

    const tabsAttributes = useMemo(() => {
        if (!data) {
            return null
        }

        return data.vertical_signs.map((verticalSign, index) => ({
            active: true,
            icon: <Styled.IconWrapper color={VERTICAL_SIGNING_CONDITIONS_VALUES[verticalSign.condition].backgroundColor}>
                <Styled.Icon
                    alt={`icon-${verticalSign.code}`}
                    onError={e => e.target.src = DEFAULT_SIGN_ICON}
                    src={verticalSign.icon || DEFAULT_SIGN_ICON}
                />

            </Styled.IconWrapper>,
            id: `tab-${index}`,
            value: index,
        }))
    }, [data])

    const tabValue = useMemo(() => {
        const tabIndex = tabsAttributes?.findIndex(tabConfig => tabConfig.id === tabParam)
        return tabIndex > -1 ? tabIndex : displayedTabIndex || 0
    }, [displayedTabIndex, tabParam, tabsAttributes])

    const tabsPanels = useMemo(() => {
        if (!data) {
            return null
        }

        return data.vertical_signs.map((verticalSign, index) => (
            <div
                role="tabpanel"
                hidden={tabValue !== index}
                id={`verticalsign-tabpanel-${index}`}
                key={`verticalsign-details-${index}`}
            >
                {tabValue === index && <VerticalSignDetails verticalSign={verticalSign} poleId={poleId} />}
            </div>
        ))
    }, [tabValue, data, poleId])

    const handleTabChange = (_, newValue) => {
        setParam(DETAIL_TAB_PARAM_KEY, tabsAttributes[newValue]?.id)
    }

    const handleVerticalSignAdded = useCallback(() => {
        const queriesdata = queryClient.getQueriesData({ queryKey: verticalSigningQueryKeys.pole(poleId, {}) })
        const signsCount = queriesdata.reduce((acc, queryData) => {
            if (queryData[1].vertical_signs) {
                return queryData[1].vertical_signs.length
            }
            return acc
        }, 0)

        setParam(DETAIL_TAB_PARAM_KEY, `tab-${signsCount ? signsCount - 1 : 0}`)
    }, [poleId, queryClient, setParam])

    return <>
        <Styled.Container isMobile={isMobile}>
            <Styled.RoadDetailWrapper>
                <Styled.LocationHeader>
                    <Typography variant="H3">
                        {t(I18N_NAMESPACE, "containers.verticalSignDetailSidebar.location")}
                    </Typography>
                    {!readOnly && <Styled.FilledIconButton id="linear-edit-button" onClick={() => setLocationModalOpen(true)}>
                        <ModeEditIcon />
                    </Styled.FilledIconButton>}
                </Styled.LocationHeader>
                <RoadDetailInfo
                    LengthComponent={LatLongComponent}
                    lengthLabel={`${t(I18N_NAMESPACE, "containers.sidebarVerticalSignDetailContent.detail.title")} `}
                    road={road}
                />
            </Styled.RoadDetailWrapper>
            <Styled.Wrapper isMobile={isMobile}>
                <Typography variant="H2">
                    {t(I18N_NAMESPACE, "containers.sidebarVerticalSignDetailContent.title")}
                </Typography>
                {
                    <>
                        {isError ? <Styled.ErrorText>
                            {t(I18N_NAMESPACE, "containers.sidebarVerticalSignDetailContent.error")}
                        </Styled.ErrorText>
                            : <Styled.TabsContainer>
                                {!tabsAttributes ? <Styled.TabSkeleton />
                                    : <Styled.Tabs
                                        handleChangeTab={handleTabChange}
                                        value={tabValue}
                                        tabElement={Styled.Tab}
                                        TabIndicatorProps={{ style: { display: "none" } }}
                                        tabsAttributes={tabsAttributes}
                                        scrollButtons="auto"
                                        variant="scrollable"
                                    />
                                }
                                {!readOnly && <Styled.AddSignButton onClick={() => setAddSignModalOpen(true)}><Styled.AddIcon /></Styled.AddSignButton>}
                            </Styled.TabsContainer>
                        }
                        {tabsPanels}
                    </>
                }
            </Styled.Wrapper>
        </Styled.Container>
        {data && <CatalogItemEditModal
            FormComponent={VerticalSignPoleEditFormPost}
            poleId={poleId}
            formTitle={t(I18N_NAMESPACE, "containers.verticalSignPoleLocationEditForm.title")}
            startingPosition={{ longitude: latLong[0], latitude: latLong[1] }}
            startingRoad={data?.linear_location.road}
            open={locationModalOpen}
            onClose={() => setLocationModalOpen(false)}
        />}
        {data && poleId && <CatalogItemEditModal
            poleId={poleId}
            formTitle={t(I18N_NAMESPACE, "containers.verticalSignPoleAddForm.title")}
            typeTitle={t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.type")}
            contentFieldLabel={t(I18N_NAMESPACE, "containers.verticalSignDetailsForm.content")}
            FormComponent={VerticalSignAddFormPost}
            open={addSignModalOpen}
            onClose={() => setAddSignModalOpen(false)}
            onSuccess={handleVerticalSignAdded}
        />}
    </>
}
