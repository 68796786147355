import { createContext } from "react"

import { MapStyles } from "../../constants/mapStyles"

export const initialStateContext = {
    mapLayersState: {
        mapStyle: MapStyles.plan,
    },
    error: false,
    fitOptions: null,
    legendOpened: false,
    mapBoundingBoxToFit: null,
    mapRefs: {},
    mapType: null,
    storedBoundingBox: {
        network: null,
        road: null,
    },
    layerClickListeners: {},
}
const initialDispatchContext = {}

export const MapStateContext = createContext(initialStateContext)
export const MapDispatchContext = createContext(initialDispatchContext)
