import { VerticalSigningIcon, CityIcon, EquipmentIcon } from "@l2r-front/l2r-icons"

import { L2rAppCity } from "../../app"

import { getModulesBreadcrumb } from "../utils/getModulesBreadcrumb"

import { I18N_NAMESPACE } from "./i18n"
import { MODULE_EQUIPMENT, MODULE_VERTICAL_SIGNING } from "./modules"

export const APP_CITY = "city"

export const appCity = {
    index: 2,
    color: "cta-bg/cta-bg-primary",
    component: <L2rAppCity />,
    icon: CityIcon,
    externalContexts: [],
    i18nNamespace: I18N_NAMESPACE,
    label: "app.label",
    modules: {
        verticalSigning: {
            index: 0,
            icon: VerticalSigningIcon,
            id: "VERTICAL_SIGNING",
            kpis: [],
            label: "app.modules.verticalSigning",
            path: MODULE_VERTICAL_SIGNING,
            urlParams: [],
            getModulesBreadcrumb,
        },
        equipment: {
            index: 1,
            icon: EquipmentIcon,
            id: "EQUIPMENT",
            kpis: [],
            label: "app.modules.equipment",
            path: MODULE_EQUIPMENT,
            urlParams: [],
            getModulesBreadcrumb,
        },
    },
    path: APP_CITY,
}