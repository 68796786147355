import { useTranslation } from "@l2r-front/l2r-i18n"

import { I18N_NAMESPACE } from "../../constants/i18n"

import * as Styled from "./CatalogItemsFiltersForm.styled"

export function CatalogItemsFiltersFormError() {

    const { t } = useTranslation(I18N_NAMESPACE)

    return <Styled.Error>
        {t(I18N_NAMESPACE, "forms.catalogItemsFiltersForm.error")}
    </Styled.Error>
}