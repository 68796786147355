import { useEffect, useCallback } from "react"

import * as htmlToImage from "html-to-image"

import { Source, useMapDispatchContext } from "@l2r-front/l2r-map"
import { useTheme } from "@l2r-front/l2r-ui"

import { CATALOG_ITEMS_CONDITIONS_VALUES } from "../../constants/catalogItemConditions"
import { DEFAULT_SIGN_CODE, DEFAULT_SIGN_ICON } from "../../constants/defaultIcon"

const ICON_SIZE = 60
export const ENABLED_STATE = "1"
export const DISABLED_STATE = "0"

export function CatalogItemsSource(props) {

    const {
        getIcon,
        iconOffset,
        sanitizedData,
        types,
        ...otherProps
    } = props

    const theme = useTheme()
    const { getMapRef } = useMapDispatchContext()

    const createMapIcon = useCallback((code, icon, condition, state, map, theme) => {
        const color = theme.palette[CATALOG_ITEMS_CONDITIONS_VALUES?.[condition]?.color]?.main
        const opacity = state === ENABLED_STATE ? 1 : 0.5
        const iconId = code + "#" + condition + "#" + state
        const container = document.createElement("div")
        container.innerHTML = `
            <div style="background-color: white; height: 100%; width: 100%; border-radius: 50%;">
                <div style="background-color: ${color}; opacity:${opacity}; height: 100%; width: 100%; border-radius: 50%; position:absolute;top:0px;left:0px"></div>
                <div style="background-color: white; height: calc(100% - 20px); width: calc(100% - 20px); border-radius: 50%; position:absolute;top:10px;left:10px">
                    <img src=${icon} style="width:calc(100% - ${iconOffset}px); height:calc(100% - ${iconOffset}px); position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);object-fit: contain; opacity:${opacity};" />
                </div>
            </div>
        `

        htmlToImage.toPng(container, {
            height: ICON_SIZE,
            width: ICON_SIZE,
            canvasHeight: ICON_SIZE,
            canvasWidth: ICON_SIZE,
            pixelRatio: 1,
            skipFonts: true,
        })
            .then(function (dataUrl) {
                window.generatedIcons[iconId] = dataUrl
                map.loadImage(
                    dataUrl,
                    (error, icon) => {
                        if (error) {
                            return console.error(error)
                        }
                        if (!map.hasImage(iconId)) {
                            map.addImage(iconId, icon)
                        }
                    },
                )
            })
            .catch(() => {
                createMapIcon(code, DEFAULT_SIGN_ICON, condition, state, map, theme)
            })
    }, [])

    useEffect(() => {
        const map = getMapRef()?.getMap()

        if (!map || !theme || !types) {
            return
        }

        if (!window.generatedIcons) {
            window.generatedIcons = {}
        }

        Object.keys(CATALOG_ITEMS_CONDITIONS_VALUES).forEach(condition => {
            [ENABLED_STATE, DISABLED_STATE].forEach(state => {
                const iconId = DEFAULT_SIGN_CODE + "#" + condition + "#" + state
                if (!window.generatedIcons[iconId]) {
                    createMapIcon(DEFAULT_SIGN_CODE, DEFAULT_SIGN_ICON, condition, state, map, theme)
                }
            })
        })

        const loadIcon = (e) => {
            const iconId = e.id
            const [typeCode, condition, state] = iconId.split("#")

            if (!typeCode || Object.keys(CATALOG_ITEMS_CONDITIONS_VALUES).indexOf(condition) < 0) {
                return
            }

            if (!window.generatedIcons) {
                window.generatedIcons = {}
            }

            if (window.generatedIcons[iconId]) {
                map.loadImage(
                    window.generatedIcons[iconId],
                    (error, icon) => {
                        if (error) {
                            return console.error(error)
                        }

                        if (!map.hasImage(iconId)) {
                            map.addImage(iconId, icon)
                        }
                    },
                )
            } else {
                const icon = getIcon(typeCode)
                createMapIcon(typeCode, icon, condition, state, map, theme)
            }
        }

        map?.on("styleimagemissing", loadIcon)

        return () => map?.off("styleimagemissing", loadIcon)

    }, [createMapIcon, getIcon, getMapRef, theme, types])

    if (!sanitizedData) {
        return null
    }

    return (
        <Source {...otherProps} type="geojson" data={sanitizedData} tolerance={.001} />
    )
}

CatalogItemsSource.defaultProps = {
    iconOffset: 10,
}