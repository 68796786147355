import {
    Skeleton,
    Typography,
    styled,
} from "@l2r-front/l2r-ui"

export const FormSkeleton = styled(Skeleton)(() => ({
    transform: "none",
}))

export const ErrorMessage = styled(Typography)(({ theme }) => ({
    color: theme.palette["error/main"].main,
}))