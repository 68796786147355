import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { EMPTY_QUERY_PARAM } from "@l2r-front/l2r-query"

import { equipmentQueryKeys } from "./queryKeys"
import { useEquipmentQuery } from "./useEquipmentQuery"

const REFETCH_INTERVAL = 7 * 60 * 1000

const sortHierarchy = (items) =>
    items
        .map(item => ({
            ...item,
            children: sortHierarchy(item.children),
        }))
        .sort((a, b) =>
            a.is_purchased !== b.is_purchased
                ? (!a.is_purchased ? 1 : -1)
                : a.code.localeCompare(b.code),
        )

const refetchConfig = {
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: true,
    staleTime: REFETCH_INTERVAL,
}

export function useEquipmentTypes(parent = EMPTY_QUERY_PARAM, config = {}) {
    const url = "equipment_types/"
    const { selectedNetwork } = useNetworksStateContext()
    const { country } = selectedNetwork

    const parameters = {
        country,
        parent: parent || EMPTY_QUERY_PARAM,
    }

    const queryKey = equipmentQueryKeys.list(parameters)

    const { data, isLoading, isError, isFetching } = useEquipmentQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
            ...refetchConfig,
        },
    )

    const result = data ? sortHierarchy(data) : null

    return { data: result, isLoading, isError, isFetching }
}

export function useEquipmentType(code, queryParameters, config = {}) {
    const url = `equipment_types/${code}/`
    const queryKey = equipmentQueryKeys["equipmentType"]({ ...queryParameters, code })

    const { data, isLoading, isError } = useEquipmentQuery(
        url,
        queryKey,
        { ...queryParameters, code },
        {
            enabled: !!code,
            ...config,
            ...refetchConfig,
        },
    )
    if (data?.children) {
        sortHierarchy(data?.children)
    }

    return { data, isLoading, isError }
}