import { useCallback } from "react"

import { CatalogItemsFiltersForm } from "@l2r-front/l2r-common"

import { useEquipmentDispatchContext, useEquipmentStateContext } from "../../contexts/EquipmentContext"
import { useEquipmentProject } from "../../hooks/queries/equipment/useEquipmentProject"

export function EquipmentFiltersFormPost(props) {
    const {
        close,
        ...equipmentFiltersFormProps
    } = props

    const { setFilters } = useEquipmentDispatchContext()
    const { filters } = useEquipmentStateContext()
    const { data: project } = useEquipmentProject()

    const onSubmit = useCallback((values) => {
        setFilters(values)
        close()
    }, [close, setFilters])

    return <CatalogItemsFiltersForm
        {...equipmentFiltersFormProps}
        activeFilters={filters}
        close={close}
        onSubmit={onSubmit}
        project={project}
        resetFilters={() => setFilters({ tags: [] })}
    />
}