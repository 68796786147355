import { CloseIcon as Close } from "@l2r-front/l2r-icons"
import {
    Box as UiBox,
    Button,
    IconButton as UiIconButton,
    Typography,
    styled,
} from "@l2r-front/l2r-ui"

const POPUP_WIDTH = 600

export const Box = styled(UiBox, { shouldForwardProp: (prop) => !["isMobile", "height"].includes(prop) })(({ theme, isMobile, height }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    borderRadius: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    bottom: isMobile ? "5%" : 0,
    top: isMobile ? "5%" : 0,
    left: isMobile ? "5%" : 0,
    right: isMobile ? "5%" : 0,
    margin: "auto",
    maxHeight: !isMobile ? "90%" : "",
    minHeight: !isMobile ? "55%" : "",
    height: isMobile ? "80%" : height,
    width: isMobile ? "90%" : POPUP_WIDTH,
    padding: theme.spacing(8, 8, 8, 8),
    position: "absolute",
    overflow: "hidden",
}))

export const TitleContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "top",
    marginBottom: theme.spacing(6),
    width: "100%",
}))

export const BackButton = styled(Button)(({ theme }) => ({
    minWidth: "unset",
    width: "fit-content",
    textTransform: "initial",
    padding: 0,
    marginRight: theme.spacing(9),
}))


export const Title = styled(Typography)(() => ({
    textAlign: "left",
}))

export const IconButton = styled(UiIconButton)(() => ({
    padding: 0,
    marginLeft: "auto",
}))

export const FormContainer = styled("div")({
    flex: "1 1 auto",
})

export const CloseIcon = styled(Close)(({ theme }) => ({
    color: theme.palette["objects/object-black"].main,
    height: 24,
    width: 24,
}))