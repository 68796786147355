import { useCallback } from "react"

import { CatalogItemsFiltersFormError, CatalogItemsFiltersFormSkeleton } from "@l2r-front/l2r-common"
import { PropTypes } from "@l2r-front/l2r-proptypes"

import { VerticalSignFiltersFormPost } from "../../forms/VerticalSignFiltersForm"
import { useVerticalSigningProject } from "../../hooks/queries/verticalSigning/useVerticalSigningProject"

import * as Styled from "./VerticalSignFilters.styled"

export function VerticalSignFilters(props) {

    const {
        className,
        close,
        displayFilters,
    } = props

    const { data, isError, isLoading } = useVerticalSigningProject()

    const VerticalSignFiltersContent = useCallback(() => {
        if (isError) {
            return <CatalogItemsFiltersFormError />
        } else if (isLoading) {
            return <CatalogItemsFiltersFormSkeleton />
        }
        return <VerticalSignFiltersFormPost close={close} projectTags={data?.tags} />
    }, [close, data, isError, isLoading])


    return <div className={className}>
        <Styled.Collapse in={displayFilters}>
            <VerticalSignFiltersContent close={close} />
        </Styled.Collapse>
    </div>
}

VerticalSignFilters.propTypes = {
    className: PropTypes.string,
    close: PropTypes.func.isRequired,
    displayFilters: PropTypes.bool.isRequired,
}