import { AddIcon, RemoveIcon } from "@l2r-front/l2r-icons"
import {
    Divider as UiDivider,
    IconButton,
    styled,
} from "@l2r-front/l2r-ui"

const BUTTON_SIZE = 24

export const Wrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    borderRadius: 100,
    padding: "8px 6px",
    boxShadow: theme.shadows[3],
}))

export const ZoomButton = styled(IconButton)(() => ({
    width: BUTTON_SIZE,
    height: BUTTON_SIZE,
}))

export const PlusIcon = styled(AddIcon)(({ theme }) => ({
    color: theme.palette["objects/object-primary"].main,
}))

export const MinusIcon = styled(RemoveIcon)(({ theme }) => ({
    color: theme.palette["objects/object-primary"].main,
}))

export const Divider = styled(UiDivider)(({ theme }) => ({
    margin: theme.spacing(3, 0),
}))