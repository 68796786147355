import { IconButton, styledForMobile } from "@l2r-front/l2r-ui"

const DESKTOP_SIZE = 37
const MOBILE_SIZE = 44

export const Button = styledForMobile(IconButton)(({ isMobile, theme }) => ({
    backgroundColor: theme.palette["surfaces/surface-primary"].main,
    width: isMobile ? MOBILE_SIZE : DESKTOP_SIZE,
    height: isMobile ? MOBILE_SIZE : DESKTOP_SIZE,
    boxShadow: theme.shadows[3],

    "&:hover": {
        backgroundColor: theme.palette["objects/object-lightgrey"].main,
    },
}))
