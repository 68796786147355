import { useCallback, useEffect, useMemo, useState } from "react"

import ReactMapbox, { ScaleControl } from "react-map-gl"

import { useGetLocaleFromList } from "@l2r-front/l2r-i18n"
import { EndFlagIcon, PlaceIcon } from "@l2r-front/l2r-icons"
import { PropTypes } from "@l2r-front/l2r-proptypes"
import { useIsMobileDevice, useTheme } from "@l2r-front/l2r-ui"

import { CATALOG_ITEMS_LAYER_CLUSTER_COUNT, INCIDENTS_LAYER_CLUSTER_COUNT } from "../../constants/layers"
import { BASE_MAP_ID } from "../../constants/map"
import { MapLayerSelector } from "../../containers/MapLayerSelector"
import { useMapContext } from "../../contexts/MapContext"

import { ImageLayerSwitch } from "../ImageLayerSwitch"
import { ImageViewerButton } from "../ImageViewerButton"
import { RecenterButton } from "../RecenterButton"
import { ZoomButtons } from "../ZoomButtons"

import * as Styled from "./Map.styled"

export const PLACE_ICON_ID = "place"
export const ENDFLAG_ICON_ID = "endFlag"

const MapIconsInfos = [
    {
        id: PLACE_ICON_ID,
        icon: PlaceIcon,
        convertToSDF: true,
    },
    {
        id: ENDFLAG_ICON_ID,
        icon: EndFlagIcon,
        convertToSDF: false,
    },
]

export const MapLocales = ["en", "fr", "es", "ge"]

export const Map = (props) => {
    const {
        className,
        borderRounded,
        bottomLeftElements,
        topLeftElements,
        topRightElements,
        children,
        error,
        id,
        isLoading,
        imageLayerDisplayed,
        onOpenImageViewer,
        onSwitchImageLayer,
        onRecenter,
        ...mapProps
    } = props

    const [mapRef, setMapRef] = useState(null)
    const [mapState, mapDispatch] = useMapContext()
    const { mapBoundingBoxToFit, fitOptions, currentMapStyle } = mapState
    const { setMapBoundingBoxToFit, setMapRef: setContextMapRef } = mapDispatch
    const theme = useTheme()
    const isMobile = useIsMobileDevice()

    const map = useMemo(() => {
        if (!mapRef) {
            return
        }

        return mapRef.getMap()
    }, [mapRef])

    const currentLang = useGetLocaleFromList(MapLocales, MapLocales[0])

    const handleLoad = useCallback((e) => {
        MapIconsInfos.forEach(infos => {
            if (!e.target.hasImage(infos.id)) {
                e.target.loadImage(
                    infos.icon,
                    (error, icon) => {
                        if (error) {
                            return console.error(error)
                        }

                        if (!e.target.hasImage(infos.id)) {
                            e.target.addImage(infos.id, icon, {
                                sdf: infos.convertToSDF,
                            })
                        }
                    },
                )
            }
        })
    }, [])

    const onStyleLoaded = (e) => {
        setMapLanguage(e.target, currentLang)
        handleLoad(e)
    }

    useEffect(() => {
        if (!map) {
            return
        }
        const loaded = map.loaded()
        if (!loaded) {
            return
        }
        setMapLanguage(map, currentLang)
    }, [currentLang, map])

    const handleMapRef = useCallback((ref) => {
        setMapRef(ref)
        setContextMapRef(ref, id)

        if (process.env.NODE_ENV === "development") {
            window.map = ref
        }
        return () => {
            setMapRef(null)
            setContextMapRef(null, id)
            window.map = undefined
        }
    }, [setContextMapRef, id])

    const zoomIn = useCallback(() => {
        map.zoomIn()
    }, [map])

    const zoomOut = useCallback(() => {
        map.zoomOut()
    }, [map])

    useEffect(() => {
        if (mapBoundingBoxToFit && map) {
            const frame = requestAnimationFrame(() => {
                map.resize()

                const horizontalPadding = Math.min(fitOptions.padding, map.getCanvas().width / 8)
                const verticalPadding = Math.min(fitOptions.padding, map.getCanvas().height / 8)

                map.fitBounds(
                    [
                        [mapBoundingBoxToFit.minLng, mapBoundingBoxToFit.minLat],
                        [mapBoundingBoxToFit.maxLng, mapBoundingBoxToFit.maxLat],
                    ],
                    {
                        ...fitOptions,
                        padding: {
                            top: verticalPadding,
                            bottom: verticalPadding,
                            left: horizontalPadding,
                            right: horizontalPadding,
                        },
                    },
                )

                setMapBoundingBoxToFit(null)
            })
            return () => cancelAnimationFrame(frame)
        }
    }, [map, mapBoundingBoxToFit, setMapBoundingBoxToFit, fitOptions])

    return (
        <Styled.Wrapper className={className} scaleColor={currentMapStyle.contrastColor} borderRounded={borderRounded}>
            <Styled.Loader color="error" hidden={!error} value={100} variant="determinate" />
            <Styled.Loader color="cta-bg/cta-bg-primary" hidden={!isLoading} />
            <ReactMapbox
                ref={handleMapRef}
                {...mapProps}
                localFontFamily={theme.typography.fontFamily}
                logoPosition={mapProps.logoPosition || (isMobile ? "top-left" : "top-right")}
                mapboxAccessToken={process.env["NX_PUBLIC_MAPBOXTOKEN"]}
                mapStyle={currentMapStyle.mapStyle}
                onLoad={handleLoad}
                onStyleData={onStyleLoaded}
                style={{ width: "100%", height: "100%" }}
            >
                <Styled.TopLeftAnchor>
                    {topLeftElements}
                </Styled.TopLeftAnchor>
                <Styled.BottomLeftAnchor>
                    <MapLayerSelector id="map-style-selector" />
                    {!isMobile && onOpenImageViewer && <ImageViewerButton id="images-viewer-button" onClick={onOpenImageViewer} />}
                    {onSwitchImageLayer && <ImageLayerSwitch id="images-layer-switch" handleChange={onSwitchImageLayer} checked={imageLayerDisplayed} />}
                    {bottomLeftElements}
                </Styled.BottomLeftAnchor>
                <Styled.TopRightAnchor>
                    {topRightElements}
                </Styled.TopRightAnchor>
                <Styled.BottomRightAnchor>
                    {!isMobile && <ZoomButtons onZoomIn={zoomIn} onZoomOut={zoomOut} />}
                    {onRecenter && <RecenterButton onRecenter={onRecenter} />}
                </Styled.BottomRightAnchor>
                {children}
                <ScaleControl />
            </ReactMapbox>
        </Styled.Wrapper>
    )
}

Map.propTypes = {
    ...ReactMapbox.propTypes,
    className: PropTypes.string,
    borderRounded: PropTypes.bool,
    children: PropTypes.node,
    error: PropTypes.bool,
    id: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
    bottomLeftElements: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    topRightElements: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    onRecenter: PropTypes.func,
}

Map.defaultProps = {
    borderRounded: false,
    error: false,
    id: BASE_MAP_ID,
    bottomLeftElements: [],
    topRightElements: [],
}

const EXCLUDED_LAYERS_FOR_TRANSLATION = ["road-number-shield", "road-number", INCIDENTS_LAYER_CLUSTER_COUNT, CATALOG_ITEMS_LAYER_CLUSTER_COUNT]

export const setMapLanguage = (map, lang) => {
    const mapStyle = map.getStyle()
    mapStyle.layers.forEach((layer) => {
        if (layer.layout && layer.layout["text-field"]) {
            if (!EXCLUDED_LAYERS_FOR_TRANSLATION.includes(layer.id)) {
                map.setLayoutProperty(layer.id, "text-field", [
                    "coalesce",
                    ["get", "name_" + lang],
                    ["get", "name"],
                ])
            }
        }

    })
}