import { Button as UiButton, Skeleton as UiSkeleton, styled, Typography } from "@l2r-front/l2r-ui"

export const Button = styled(UiButton, { shouldForwardProp: (prop) => prop !== "displayBorder" })(({ displayBorder, disabled, selected, theme }) => {

    const outline = selected ? `2px solid ${theme.palette["cta-bg/cta-bg-primary"].main}`
        : displayBorder ? `1px solid ${theme.palette["border/border-disabled"].main}`
            : "none"

    return ({
        backgroundColor: disabled && theme.palette["surfaces/surface-variation"].main,
        outline,
        boxSizing: "border-box",
        borderRadius: theme.spacing(2),
        boxShadow: selected && theme.shadows[32],
        padding: theme.spacing(2, 3),
        minWidth: "fit-content",
    })
})

export const Text = styled(Typography)(({ color, theme }) => ({
    color: theme.palette[color].main,
}))

export const OptionsWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    boxSizing: "border-box",
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    maxWidth: "100%",
    overflowX: "auto",
    gap: theme.spacing(1),
}))

export const Wrapper = styled("div")({
})

export const Skeleton = styled(UiSkeleton)(({ theme }) => ({
    height: 82,
    marginTop: theme.spacing(2),
    transform: "scale(1)",
    width: "100%",
}))

export const ErrorWrapper = styled("div")(({ theme }) => ({
    height: 82,
    marginTop: theme.spacing(2),
    width: "100%",
}))

export const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette["texts/text-error"].main,
}))