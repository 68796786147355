import { verticalSigningQueryKeys } from "./queryKeys"
import { useVerticalSigningQuery } from "./useVerticalSigningQuery"


export function useVerticalSigningCategoriesStats(parameters = {}, config = {}) {
    const url = "vertical_signs/stats/vertical_sign_types/"

    const queryKey = verticalSigningQueryKeys["stats_categories"]({ parameters })

    return useVerticalSigningQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
        },
    )
}