import { useMemo } from "react"

import { useRoutes } from "react-router-dom"

import { useIsMobileDevice } from "@l2r-front/l2r-ui"

import { EquipmentDetailSidebar } from "../../containers/EquipmentDetailSidebar"
import { EquipmentSidebar } from "../../containers/EquipmentSidebar"

import { EquipmentValidator } from "../EquipmentValidator"

import * as Styled from "./EquipmentSidebarRouter.styled"

export function EquipmentSidebarRouter() {
    const isMobile = useIsMobileDevice()

    const sidebarRoutes = useMemo(() => ([
        { path: "/", element: <EquipmentSidebar /> },
        { path: "/:road", element: <EquipmentSidebar /> },
        {
            path: "/:road/:signId", element: <EquipmentValidator>
                <EquipmentDetailSidebar />
            </EquipmentValidator>,
        },
    ]), [])


    return <Styled.Sidebar shadowed={!isMobile}>{useRoutes(sidebarRoutes)}</Styled.Sidebar>
}
