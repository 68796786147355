import { useMemo, useCallback } from "react"

import * as turf from "@turf/turf"
import { useParams } from "react-router-dom"

import { CatalogItemsSource, DEFAULT_SIGN_CODE, DEFAULT_SIGN_ICON } from "@l2r-front/l2r-common"
import { useTranslation } from "@l2r-front/l2r-i18n"
import { useMapDispatchContext } from "@l2r-front/l2r-map"
import { findTypeByCode } from "@l2r-front/l2r-utils"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { useHorizontalSigningStateContext } from "../../contexts/HorizontalSigningContext"
import { useHorizontalSigns } from "../../hooks/queries/horizontalSigning/useHorizontalSigns"
import { useHorizontalSignTypes } from "../../hooks/queries/horizontalSigning/useHorizontalSignTypes"

export const ENABLED_STATE = "1"
export const DISABLED_STATE = "0"

export function HorizontalSigningSource(props) {

    const { t } = useTranslation(I18N_NAMESPACE)
    const { setError: setMapError } = useMapDispatchContext()
    const { descendantCodes, filters } = useHorizontalSigningStateContext()
    const { signId } = useParams()

    const { data: horizontalSignTypes } = useHorizontalSignTypes()

    const { data } = useHorizontalSigns({
        as_geojson: true,
    }, {
        onError: () => setMapError(true),
        errorSnackbarMessage: t(I18N_NAMESPACE, "containers.horizontalSignsLayer.error"),
    })

    const getIcon = useCallback((typeCode) => {
        return typeCode === DEFAULT_SIGN_CODE ? DEFAULT_SIGN_ICON :
            findTypeByCode(typeCode, horizontalSignTypes)?.icon || DEFAULT_SIGN_ICON
    }, [horizontalSignTypes])

    // Need to copy id into properties because of that mapbox issue: 
    // link:https://github.com/mapbox/mapbox-gl-js/issues/2716
    const sanitizedData = useMemo(() => {
        if (!data || !horizontalSignTypes) {
            return null
        }
        const dataFeatures = []
        turf.geomEach(data, (currentGeometry, featureIndex, featureProperties, featureBBox, featureId) => {
            const feature = data.features[featureIndex]
            const sign = featureProperties
            const isSelectedSign = featureId === signId
            const filterConditions = filters?.conditions || null
            const filterTags = filters?.tags || null
            const isSelectedType = descendantCodes.includes(sign.horizontal_sign_type)
            const isSelectedCondition = filterConditions ? (filterConditions).includes(sign.condition)
                : true
            const isFilteredByTags = !filterTags || filterTags.every(filterTag => sign.tags.includes(filterTag))

            if (isSelectedSign || (isSelectedType && isSelectedCondition && isFilteredByTags)) {
                dataFeatures.push({
                    id: featureId,
                    bbox: featureBBox,
                    geometry: currentGeometry,
                    properties: {
                        ...featureProperties,
                        globalCondition: sign.condition || -1,
                        globalIcon: sign.horizontal_sign_type,
                        horizontal_signs: sign,
                        linearLocation: feature.linear_location,
                        uuid: featureId,
                    },
                })
            }
        })
        return turf.featureCollection(dataFeatures)
    }, [data, descendantCodes, filters, signId, horizontalSignTypes])

    if (!sanitizedData) {
        return null
    }

    return (
        <CatalogItemsSource
            {...props}
            getIcon={getIcon}
            iconOffset={0}
            sanitizedData={sanitizedData}
            types={horizontalSignTypes}
        />
    )
}