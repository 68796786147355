import { CloseIcon as UiCloseIcon } from "@l2r-front/l2r-icons"
import {
    styled,
    styledForMobile,
    Autocomplete as UiAutocomplete,
    TextField as UiTextField,
    IconButton as UiIconButton,
} from "@l2r-front/l2r-ui"

const WIDTH = 336
const DESKTOP__BUTTON_SIZE = 48
const MOBILE_BUTTON_SIZE = 44

export const Autocomplete = styledForMobile(UiAutocomplete)(({ theme, isMobile }) => ({
    width: isMobile ? "100%" : WIDTH,

    "&.MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}))

export const TextField = styled(UiTextField)(({ theme }) => ({

    "& > .MuiInputBase-root": {
        borderRadius: theme.spacing(8),
        boxShadow: theme.shadows[3],
        backgroundColor: "white",
    },

    "& fieldset": {
        border: 0,
    },

}))

export const IconButton = styledForMobile(UiIconButton)(({ theme, isMobile }) => ({
    backgroundColor: "white",
    padding: theme.spacing(3),
    boxShadow: theme.shadows[3],
    width: isMobile ? MOBILE_BUTTON_SIZE : DESKTOP__BUTTON_SIZE,
    height: isMobile ? MOBILE_BUTTON_SIZE : DESKTOP__BUTTON_SIZE,

    "&:hover": {
        backgroundColor: theme.palette["objects/object-lightgrey"].main,
    },
}))

export const CloseIcon = styled(UiCloseIcon)(() => ({
    cursor: "pointer",
}))