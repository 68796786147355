export function findCatalogTypeByCode(code, catalog) {
    const horizontalSignType = catalog.find(item => item.code === code)

    if (horizontalSignType) {
        return horizontalSignType
    } else {
        for (let item of catalog) {
            const type = findCatalogTypeByCode(code, item.children)
            if (type) {
                return type
            }
        }

        return null
    }
}

export function getFinalCatalogTypes(catalog) {

    const getLeafTypes = (types) => {
        let leafTypes = []

        types.forEach(type => {
            if (!type.children?.length) {
                leafTypes.push(type)
            } else {
                leafTypes = leafTypes.concat(getLeafTypes(type.children))
            }
        })

        return leafTypes
    }

    return getLeafTypes(catalog)
}
